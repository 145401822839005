<template>
	<div
		class="bg-gray-900 buyer-app-sidebar app-sidebar flex-col sidebar-shadow sidebar-bg-custom"
		:style="
			bgColor && bgColor ? 'background:' + bgColor : 'background: #181C4C'
		"
	>
		<div
			class="absolute inset-0"
			style="z-index: 999"
			@click="hideWrapper"
			v-if="showWrapper"
		></div>
		<div
			data-cy="logo"
			class="flex justify-center mb-3 cursor-pointer"
			v-if="systemMode === 'whitelabel'"
			v-on:click="openDashboardUrl"
		>
			<img
				class="b2b-logo"
				:src="GET_COMPANY_SUBSCRIPTION_SETTING.half_logo"
				:alt="GET_COMPANY_SUBSCRIPTION_SETTING.brand_name"
				style="height: 50px; width: auto"
			/>
		</div>
		<div
			data-cy="logo"
			class="flex justify-center mb-3 cursor-pointer"
			v-else
			v-on:click="openDashboardUrl"
		>
			<img
				class="b2b-logo"
				src="./../assets/images/logo/old-web.png"
				alt=""
			/>
		</div>
		<div class="app-header__logo bg-gray-900">
			<div class="logo-srcs"></div>
			<div class="header__pane ml-auto">
				<div>
					<button
						type="button"
						class="hamburger close-sidebar-btn hamburger--elastic"
						data-class="closed-sidebar"
					>
						<span class="hamburger-box">
							<span class="hamburger-inner"></span>
						</span>
					</button>
				</div>
			</div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button
					type="button"
					class="hamburger hamburger--elastic mobile-toggle-nav"
				>
					<span class="hamburger-box">
						<span class="hamburger-inner"></span>
					</span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
			<span>
				<button
					type="button"
					class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
				>
					<span class="btn-icon-wrapper">
						<i class="fa fa-ellipsis-v fa-w-6"></i>
					</span>
				</button>
			</span>
		</div>
		<div class="scrollbar-sidebar">
			<div class="app-sidebar__inner flex items-center">
				<ul class="vertical-nav-menu w-full overflow-x-hidden">
					<!-- <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">Menu</li> -->
					<li
						class="menu-link mm-active v-step-0"
						v-if="systemMode === 'whitelabel'"
					>
						<router-link
							:to="{
								name: 'bloglist',
								params: {
									company_id:
										GET_COMPANY_SUBSCRIPTION_SETTING.company,
								},
							}"
						>
							<!-- <i class="metismenu-icon iconly-brokenCategory"></i>{{ $t('dashboard') }} -->
							<span
								class="flex align-items-center custom-left absolute"
								style="left: 15px"
								><img
									class="h-3 mr-3"
									src="./../assets/images/icons/dashboard_2.png"
									alt=""
								/>
								{{ $t("dashboard") }}</span
							>
						</router-link>
					</li>
					<li
						class="menu-link mm-active v-step-0"
						v-if="systemMode === 'whitelabel'"
					>
						<router-link
							:to="{
								name: 'productarticles',
								params: {
									company_id:
										GET_COMPANY_SUBSCRIPTION_SETTING.company,
								},
							}"
						>
							<!-- <i class="metismenu-icon iconly-brokenCategory"></i>{{ $t('dashboard') }} -->
							<span
								class="flex align-items-center custom-left absolute"
								style="left: 15px"
								><img
									class="h-3 mr-3"
									src="./../assets/images/icons/dashboard_2.png"
									alt=""
								/>
								{{ $t("toWebshop") }}</span
							>
						</router-link>
					</li>
					<li
						class="menu-link mm-active v-step-0"
						v-if="systemMode !== 'whitelabel'"
					>
						<router-link
							:to="{ name: 'customerdash' }"
							class="nav-link-none-bold"
							href="javascript:void(0)"
							:class="{
								'router-link-exact-active router-link-active':
									this.$route.name === 'customerdash',
							}"
						>
							<!-- <i class="metismenu-icon iconly-brokenCategory"></i>{{ $t('dashboard') }} -->
							<span
								class="flex align-items-center custom-left absolute"
								style="left: 15px"
								><img
									class="h-3 mr-3"
									src="./../assets/images/icons/dashboard_2.png"
									alt=""
								/>
								{{ $t("dashboard") }}</span
							>
						</router-link>
					</li>
					<li class="menu-link v-step-1">
						<a
							href="javascript:void(0)"
							class="nav-link-none-bold"
							v-on:click="
								openNavUrl(
									{
										name: 'customerhistory',
										params: { company_id: getCompanyID() },
									},
									'order'
								)
							"
							:class="{
								'router-link-exact-active router-link-active':
									this.$route.name === 'customerhistory',
							}"
						>
							<!-- <i class="metismenu-icon iconly-brokenChart"></i> {{ $t('orderHistory') }} -->
							<span
								class="flex align-items-center custom-left absolute"
								style="left: 15px"
								><img
									class="h-3 mr-3"
									src="./../assets/images/icons/notes.png"
									alt=""
								/>
								{{ $t("orderHistory") }}</span
							>
						</a>
					</li>
					<li class="menu-link v-step-3">
						<a
							href="javascript:void(0)"
							class="nav-link-none-bold"
							v-on:click="
								openNavUrl(
									{
										name: 'invitebuyers',
										params: { company_id: getCompanyID() },
									},
									'invitebuyers'
								)
							"
							:class="{
								'router-link-exact-active router-link-active':
									this.$route.name === 'invitebuyers',
							}"
						>
							<!-- <i class="metismenu-icon iconly-brokenSend"></i> {{ $t('invite') }} -->
							<span
								class="flex align-items-center custom-left absolute"
								style="left: 15px"
								><img
									class="h-3 mr-3"
									src="./../assets/images/icons/rocket_2.png"
									alt=""
								/>
								{{ $t("invite") }}</span
							>
						</a>
					</li>
					<li class="menu-link v-step-7">
						<a
							href="javascript:void(0)"
							class="nav-link-none-bold"
							:class="{
								'router-link-exact-active router-link-active':
									this.$route.name === 'userlogs',
							}"
							v-on:click="
								openNavUrl({
									name: 'userlogs',
									params: { company_id: getCompanyID() },
								})
							"
						>
							<!-- <i class="metismenu-icon iconly-brokenSend"></i> {{ $t('logs') }} -->
							<span
								class="flex align-items-center custom-left absolute"
								style="left: 15px"
								><img
									class="h-3 mr-3"
									src="./../assets/images/icons/img.png"
									alt=""
								/>{{ $t("logs") }}</span
							>
						</a>
					</li>
					<li class="menu-link">
						<a
							href="javascript:void(0)"
							class="nav-link-none-bold"
							:class="{
								'router-link-exact-active router-link-active':
									this.$route.name === 'customer-settings',
							}"
							v-on:click="
								openNavUrl({
									name: 'customer-settings',
									params: { company_id: getCompanyID() },
								})
							"
						>
							<!-- <i class="metismenu-icon iconly-brokenSend"></i> {{ $t('logs') }} -->
							<span
								class="flex align-items-center custom-left absolute"
								style="left: 15px"
								><img
									class="h-3 mr-3"
									src="./../assets/images/icons/settings-line.png"
									alt=""
								/>{{ $t("settings") }}</span
							>
						</a>
					</li>
					
					<li class="menu-link">
						<a
							href="javascript:void(0)"
							data-cy="faqz-route"
							class="nav-link-none-bold"
							:class="{
								'router-link-exact-active router-link-active':
									this.$route.name === 'customer_faqz',
							}"
							v-on:click="
								openNavUrl({
									name: 'customer_faqz',
									params: { company_id: getCompanyID() },
								})
							"
						>
							<span
								class="flex align-items-center custom-left absolute"
								style="left: 15px"
								><svg
									width="17"
									height="17"
									viewBox="0 0 22 22"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476606 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM11 20.4286C9.13521 20.4286 7.31229 19.8756 5.76177 18.8396C4.21125 17.8035 3.00277 16.331 2.28914 14.6082C1.57551 12.8853 1.3888 10.9895 1.7526 9.16057C2.1164 7.33161 3.01439 5.6516 4.333 4.33299C5.65161 3.01438 7.33162 2.1164 9.16058 1.7526C10.9895 1.38879 12.8853 1.57551 14.6082 2.28914C16.331 3.00276 17.8035 4.21124 18.8396 5.76177C19.8756 7.31229 20.4286 9.1352 20.4286 11C20.4286 13.5006 19.4352 15.8988 17.667 17.667C15.8988 19.4352 13.5006 20.4286 11 20.4286Z"
										fill="#424242"
									/>
									<path
										d="M12.1788 16.8932C12.1845 17.1275 12.1202 17.3582 11.9942 17.5558C11.8681 17.7534 11.6861 17.9089 11.4712 18.0026C11.2564 18.0962 11.0185 18.1237 10.7879 18.0815C10.5574 18.0393 10.3446 17.9293 10.1769 17.7656C10.0091 17.602 9.89399 17.392 9.84613 17.1626C9.79827 16.9331 9.81989 16.6946 9.90821 16.4776C9.99654 16.2605 10.1476 16.0746 10.342 15.9438C10.5365 15.8129 10.7655 15.743 10.9999 15.7429C11.3076 15.7428 11.6032 15.8631 11.8235 16.078C12.0438 16.293 12.1713 16.5855 12.1788 16.8932Z"
										fill="#424242"
									/>
									<path
										d="M11.7863 4.71422H10.6074C10.1427 4.71311 9.68238 4.80382 9.25286 4.98113C8.82335 5.15844 8.4331 5.41887 8.10452 5.74744C7.77595 6.07601 7.51553 6.46627 7.33822 6.89578C7.1609 7.32529 7.07019 7.78561 7.0713 8.25028V8.64349H8.64273V8.25028C8.64273 7.72923 8.84971 7.22951 9.21816 6.86107C9.5866 6.49263 10.0863 6.28564 10.6074 6.28564H11.7863C12.0474 6.28072 12.3068 6.32789 12.5494 6.42439C12.792 6.5209 13.013 6.6648 13.1994 6.84769C13.3857 7.03058 13.5338 7.24878 13.6348 7.48955C13.7359 7.73032 13.788 7.98881 13.788 8.24993C13.788 8.51105 13.7359 8.76954 13.6348 9.01031C13.5338 9.25108 13.3857 9.46928 13.1994 9.65217C13.013 9.83506 12.792 9.97896 12.5494 10.0755C12.3068 10.172 12.0474 10.2191 11.7863 10.2142H10.2149V13.7503H11.7863V11.7856C12.2548 11.7922 12.7199 11.7056 13.1547 11.5309C13.5894 11.3562 13.9851 11.0968 14.3187 10.7678C14.6523 10.4388 14.9173 10.0468 15.0981 9.61459C15.2789 9.18235 15.372 8.71847 15.372 8.24993C15.372 7.78139 15.2789 7.31751 15.0981 6.88527C14.9173 6.45302 14.6523 6.06102 14.3187 5.73205C13.9851 5.40307 13.5894 5.14368 13.1547 4.96895C12.7199 4.79422 12.2548 4.70763 11.7863 4.71422Z"
										fill="#424242"
									/>
								</svg>
								<span class="mr-13">{{
									$t("faqz")
								}}</span></span
							>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<span class="menu-link mt- absolute bottom-10 cursor-pointer z-50">
			<span
				class="text-sm nav-link-none-bold cursor-pointer pl-3"
				@click="logoutUser"
			>
				<span
					class="text-xl icon-gray mt- mb-1 iconly-brokenLogout"
				></span>
				<span class="pl-2 relative nav-text -top-1">{{
					$t("logout")
				}}</span>
			</span>
		</span>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import isScreen from "../services/screen-helper/screenHelper";
import { getBuyerId } from "../services/storage-window";
import i18n from "@/plugins/i18n";

export default {
	name: "sidebar-dash",
	data() {
		return {
			baseURL: window.location.origin,
			userSubscription: [],
			userObject: null,
			showConnectedIntegration: false,
			showWrapper: false,
			systemMode: "",
			showSetting: false,
			routeList: {},
			userSetting: {},
			bgColor: null,
		};
	},
	watch: {
		$route(to, from) {},
	},
	computed: {
		...mapGetters({
			GET_CONNECTED_WEBSHOP: "customer/GET_CONNECTED_WEBSHOP",
			GET_WEBSHOP_PROVIDER: "customer/GET_WEBSHOP_PROVIDER",
			GET_COMPANY_SUBSCRIPTION_SETTING:
				"portal/GET_COMPANY_SUBSCRIPTION_SETTING",
			CURRENT_COMPANY: "customer/GET_CURRENT_COMPANY",
			GET_CUSTOMER_NAME: "customer/GET_CUSTOMER_NAME",
		}),
	},
	mounted() {
		this.getConnectedWebshop();
		this.getUserCompanyName();
		this.getCompanyInfo();
		window.Bus.$on("show-wrapper", (e) => {
			if (e === true) {
				this.showWrapper = true;
			} else {
				this.showWrapper = false;
			}
		});
		window.Bus.$on("reset-dropdown", (_) => {
			this.hideDropdown();
		});
		this.getBuyerAccountSettings();
	},
	created() {
		this.getCompanyInfo();
	},
	beforeMount() {
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE;
		if (process.env.VUE_APP_SYSTEM_MODE === "whitelabel") {
			this.$store
				.dispatch("auth/getCompanySetup", {
					company_id: process.env.VUE_APP_COMPANY_ID,
				})
				.then((resp) => {
					this.$store.commit(
						"portal/UPDATE_COMPANY_SUBSCRIPTION_SETTING",
						resp.data.data
					);
				});
		}
	},
	methods: {
		getCompanyInfo() {
			if (process.env.VUE_APP_SYSTEM_MODE === "whitelabel") {
				const payload = {
					id: process.env.VUE_APP_COMPANY_ID,
				};
				this.$store
					.dispatch("customer/getCompanySettings", payload)
					.then((resp) => {
						if (resp.data.data.buyer_sidebar_menu_color) {
							this.bgColor =
								resp.data.data.buyer_sidebar_menu_color;
						} else {
							this.bgColor = null;
						}
					})
					.catch((err) => {
						this.bgColor = null;
					});
			}
		},
		changeLocale(locale) {
			i18n.locale = locale;
			this.$services.moment.locale(locale);
			this.$store.commit("utility/CHANGE_LOCALE", locale);
			window.Bus.$emit("reload-locale-specific-component");
		},
		logoutUser() {
			this.changeLocale("se");
			this.$store.commit("auth/CLEAR_AUTH_CUS", null);
			window.Bus.$emit("sign-out");
		},
		resize() {
			if (isScreen("xs") || isScreen("sm") || isScreen("md")) {
			}
		},
		hideWrapper() {
			window.Bus.$emit("hide-wrapper");
			this.showWrapper = false;
		},
		getConnectedWebshop() {
			this.$store.dispatch("customer/getConnectedWebshop", {});
		},
		hideDropdown() {
			if (this.showConnectedIntegration) {
				document.getElementById("sub_id").classList.remove("mm-show");
				this.showConnectedIntegration = false;
			}
		},
		getBuyerAccountSettings: function () {
			/*
			 * this method handle getting buyer setting from the backend api
			 * */
			this.$store
				.dispatch("customer/getUserAccountSettings")
				.then((resp) => {
					this.userSetting = resp.data.data;
				});
		},
		renderProvider(provider) {
			const filterProvider = this.GET_WEBSHOP_PROVIDER.filter(
				(item) => parseInt(item.id) === parseInt(provider.provider_id)
			);
			if (filterProvider.length > 0) {
				return filterProvider[0].name;
			}
		},
		openConnectedWebshop() {
			this.showConnectedIntegration = !this.showConnectedIntegration;
			if (this.showConnectedIntegration) {
				document.getElementById("sub_id").classList.add("mm-show");
				// document.getElementById('sub_id').classList.add('override-height')
				// document.getElementById('top-side-to-scroll').classList.add('overflow-y-scroll')
			} else {
				document.getElementById("sub_id").classList.remove("mm-show");
				// document.getElementById('top-side-to-scroll').classList.remove('overflow-y-scroll')
			}
		},
		getCompanyID: function () {
			if (this.CURRENT_COMPANY.hasOwnProperty("id")) {
				return this.CURRENT_COMPANY.id;
			} else if (
				window.localStorage.getItem("__b2b__company__id") !== "" &&
				window.localStorage.getItem("__b2b__company__id") !== null
			) {
				return window.localStorage.getItem("__b2b__company__id");
			} else if (getBuyerId()) {
				let buyer = getBuyerId();
				if (
					buyer.hasOwnProperty("company_id") &&
					buyer.hasOwnProperty("referrer")
				) {
					return buyer["company_id"];
				}
			} else {
				return "invalid_company";
			}
		},
		getUserCompanyName() {
			this.$store.dispatch("customer/getUserCompanyName").then((res) => {
				if (this.GET_CUSTOMER_NAME.length > 0) {
					if (
						this.GET_CUSTOMER_NAME[0].hasOwnProperty(
							"self_allowed_create_delivery_addresses"
						)
					) {
						if (
							this.GET_CUSTOMER_NAME[0]
								.self_allowed_create_delivery_addresses === true
						) {
							this.showSetting = true;
						}
					}
				}
			});
		},
		openDashboardUrl() {
			if (getBuyerId()) {
				let buyer = getBuyerId();
				if (buyer.hasOwnProperty("referrer")) {
					if (buyer.referrer === "seller") {
						return this.$services.helpers.notification(
							this.$t("permissionDeniedResource"),
							"error",
							this
						);
					} else {
						return this.$router.push({
							name: "bloglist",
							params: {
								company_id:
									this.GET_COMPANY_SUBSCRIPTION_SETTING
										.company,
							},
						});
					}
				} else {
					return this.$router.push({
						name: "bloglist",
						params: {
							company_id:
								this.GET_COMPANY_SUBSCRIPTION_SETTING.company,
						},
					});
				}
			} else {
				return this.$router.push({
					name: "bloglist",
					params: {
						company_id:
							this.GET_COMPANY_SUBSCRIPTION_SETTING.company,
					},
				});
			}
		},
		openNavUrl(url, resource_type = null) {
			if (getBuyerId()) {
				let buyer = getBuyerId();
				if (buyer.hasOwnProperty("referrer")) {
					if (buyer.referrer === "seller") {
						if (resource_type === "order") {
							let denied = true;
							if (
								this.userSetting.hasOwnProperty(
									"allow_seller_to_see_your_orders"
								)
							) {
								if (
									this.userSetting
										.allow_seller_to_see_your_orders ===
									true
								) {
									denied = false;
								}
							}
							if (denied === true) {
								return this.$services.helpers.notification(
									this.$t("permissionDeniedResource"),
									"error",
									this
								);
							} else {
								return this.$router.push(url);
							}
						} else if (resource_type === "invitebuyers") {
							if (
								this.userSetting
									.allow_seller_send_employee_invite === true
							) {
								return this.$router.push(url);
							} else {
								return this.$services.helpers.notification(
									this.$t("permissionDeniedResource"),
									"error",
									this
								);
							}
						} else {
							return this.$services.helpers.notification(
								this.$t("permissionDeniedResource"),
								"error",
								this
							);
						}
					} else {
						return this.$router.push(url);
					}
				} else {
					return this.$router.push(url);
				}
			} else {
				return this.$router.push(url);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./../assets/variables.scss";

.app-sidebar .app-sidebar__inner {
	padding: unset !important;
}

.sidebar-bg-custom {
	background: $production-theme;
	border-right: 1px solid #344258;
}

// .app-sidebar__heading{
//     // color: #ffffff !important;
//     // font-size: 0.6rem;
// }
.vertical-nav-menu li.mm-active > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	font-weight: unset !important;
}

.vertical-nav-menu li a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
}

.vertical-nav-menu li.menu-link:hover {
	border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: #2e346c !important;
	opacity: 0.5;
}

.vertical-nav-menu ul > li > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	transition: all 0.3s ease;
}

.vertical-nav-menu li a:hover:not(.router-link-exact-active) {
	background: unset !important;
	text-decoration: none;
	margin-left: 13px;

	i.metismenu-state-icon {
		margin-left: 10px;
		position: sticky;
	}
}

.nav-text {
	color: #ccc !important;
}

.vertical-nav-menu i.metismenu-icon {
	opacity: unset !important;
}

.vertical-nav-menu ul {
	transition: padding 300ms;
	padding: 0.5em 0 0 0.6rem !important;
}

.router-link-exact-active {
	// border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: #2e346c !important;
	// background: #9FA2B4 !important;
	color: #ffffff !important;
	opacity: 0.9;
	left: 11px;
}

// .hover-connected {
//     &:hover{
//         margin-top : 3px;
//         display: flex;
//         justify-content: end;
//     }
// }
// .subscription-section li .router-link-exact-active{
//     color: #bfc3ce !important;
//     font-size: 0.8rem;
//     font-weight: bold;
// }
.mr-13 {
	margin-left: 13px;
}

.disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.6;
}

.sidebar-bg-custom {
	background: rgb(24, 28, 76);
}

.icon-gray {
	color: #61678f;
}
</style>
