<template>
	<div id="accordion" class="border-solid category-header bg-white">
		<div class="flex justify-content-between">
			<div class="flex">
				<span class="col-span-1" v-if="openChild === false" v-on:click="openChildCategory">
				<i class="fa fa-chevron-circle-right span-icon"></i>
			</span>
				<span class="col-span-1" v-if="openChild === true" v-on:click="openChildCategory">
				<i class="fa fa-chevron-circle-down span-icon"></i>
			</span>
				<h5 class="mb-0 text-base font-normal col-span-1 ml-3">
					<input class="checkbox-height mr-2"
						   type="checkbox"
						   v-on:change="toggleMainCategory"
						   v-model="category.checked"
						   :value="category.checked"
						   v-bind:checked="category.checked"/>{{ category.name }}
				</h5>
			</div>

		</div>
		<div class="collapse" :class="{'show': openChild === true}">
			<div class="category-sub-header" v-for="(item,key) in category.child_node" :key="key"
				 :class="{'border-bottom': key+1 < category.child_node.length}">
				<div class="subcategory">
					<span> <input class="checkbox-height mr-2"
								  type="checkbox"
								  v-model="item.checked"
								  :value="item.checked"
								  v-on:change="toggleChildCategory(item)"
								  v-bind:checked="item.checked"/> {{ item.name }} <i class="ml-1 mr-1 not-italic"
																					 v-if="item.subcategory.length > 0"> >> </i> </span>
					<span class="font-normal cursor-pointer mr-2" v-for="(sub,k) in item.subcategory"
						  :key="k">{{ sub.name }}<i class="ml-1 mr-1 not-italic"
													v-if="k+1 !== item.subcategory.length"> >></i> </span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "singleArticleComponentTree",
	props: {
		category: {
			type: Object
		}
	},
	data: function () {
		return {
			assigningProduct: false,
			showHere: false,
			openChild: false,
		}
	},
	methods: {
		toggleMainCategory: function () {
			window.Bus.$emit('toggle-main-category', this.category)
		},
		toggleChildCategory: function (item) {
			window.Bus.$emit('toggle-child-category', {category_id: this.category.id, node: item})
		},
		openChildCategory: function () {
			this.openChild = !this.openChild
		},
	}
}
</script>

<style scoped>
.category-header {
	padding-top: 7px;
	padding-bottom: 7px;
	padding-left: 13px;
	border: 1px solid rgb(243 244 246);
}

.category-sub-header {
	padding-top: 12px;
	padding-bottom: 7px;
	padding-left: 52px;

}
</style>
