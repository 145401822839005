<template>
	<tr>
		<td><input type="checkbox" v-model="data.checked" :value="data.checked" @change="checkboxOrder"
				   class="font-xs checkbox-height"></td>
		<!-- <td><span class="font-black">{{ data.description }}</span></td> -->
<!--		<td><span>{{ $t('parentCategory') }}</span></td>-->
		<td>
			<div class="flex">
				<span class="text-pink-400 mr-1">{{ data.parent_category_name }}  </span>>>
				<span class="flex">
					<category-child-table-body-drawer class="ml-2" v-for="(subCategory,key) in data.sub_category"
													  :category="data"
													  :data="subCategory" :key="key"/>
				</span>
			</div>
			<!--            <span v-else>-->
			<!--                <form @submit.prevent="updateParentCategory" class="z-50 relative w-full">-->
			<!--                    <input v-model="parentEditText" type="text" class="focus:outline-none w-full">-->
			<!--                </form>-->
			<!--			            </span>-->
		</td>
		<td>
			<div class="flex justify-center space-x-2">
            <span v-on:click="deleteCategory(data)"
				  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                                                <img src="./../../../assets/images/icons/delete.png" alt="">
                 </span>
				<span v-on:click="updateParentCategory(data)"
					  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                                                <img src="./../../../assets/images/icons/edit.png" alt="">
           </span>
			</div>
		</td>
	</tr>
</template>

<script>
import CategoryChildTableBody from "./CategoryChildTableBody";
import CategoryChildTableBodyDrawer from "./CategoryChildTableBody";

export default {
	name: 'CategoryParentTableBodyDrawer',
	components: {CategoryChildTableBodyDrawer},
	props: {
		data: {
			type: Object,
			require: true,
		}
	},
	data() {
		return {
			checked: false,
			updateStatus: false,
			parentEditText: this.data.parent_category_name,
		}
	},
	mounted() {
		window.Bus.$on('remove-category-update', _ => {
			this.updateStatus = false
		})
	},
	methods: {
		checkboxOrder(e) {
			this.$store.commit('portal/TOGGLE_CATEGORY_CHECKBOX', {
				id: this.data.parent_category_id,
				value: this.data.checked
			})
		},
		toggleStatus() {
			this.updateStatus = !this.updateStatus
			if (this.updateStatus) {
				window.Bus.$emit('update-category-backdrop')
			}
		},
		updateParentCategory(data) {
			window.Bus.$emit('update-category-subcategory-event', {type: 'category', data: data})
		},
		deleteCategory(data) {
			window.Bus.$emit('delete-category-subcategory-event', {type: 'category', data: data})
		}
	}
}
</script>

<style lang="scss" scoped>
.table tbody tr > td {
	padding: 0.4rem 0.55rem !important;
	font-size: 15px !important;
	color: #6F6F6F !important;
}
</style>
