<template>
    <div class="app-wrapper-footer">
        <div class="app-footer">
            <div class="app-footer__inner">
                <div class="app-footer-right">
            
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'footer-dash',
        data () {
            return {
                current : new Date().getFullYear()
            }
        }
    }
</script>

<style lang="sass" scoped>

</style>