<template>
  <transition name="fade">
    <div class="modal-custom"> <!--v-if="show"-->
        <div class="modal-custom__backdrop" @click="$emit('handleClose')"/>

        <div class="modal-custom__dialog" :class="width ? width : 'w-1/3'">
            <!-- <div class="modal-custom__header">
                <div class="flex justify-between items-start sticky-border">
                    <div class="flex align-items-center">
                        <button class="p-3 leading-none" @click="$emit('handleClose')" v-if="!close">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div> -->

            <div class="modal-custom__body">
                <slot name="body"/>
            </div>

            <div class="modal-custom__footer">
                <slot name="footer"/>
            </div>
        </div>
    </div>
  </transition>
</template>

<script>
export default {
    name: 'Modal',
    props : {
        // isVisible : {
        //     type : Boolean,
        //     required : true
        // },
        width : {
            type : String,
            required : false
        },
        title : {
            type : String,
            required: true
        },
        close : {
            type : Boolean,
            required: false
        }
    },
    data () {
        return {
            show: false
        }
    },
    methods: {
        closeModal () {
            this.show = false
            document.querySelector('body').classList.remove('overflow-hidden')
        },
        openModal () {
            this.show = true
            document.querySelector('body').classList.add('overflow-hidden')
        }
    }
}
</script>


<style lang="scss">
.modal-custom {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: auto;
  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .5;
    background: #000;
    z-index: 1;
  }
  &__dialog {
    position: relative;
    // width: 600px;
    background-color: #fff;
    border-radius: 5px;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    z-index: 999999999 !important;
    @media screen and (max-width: 992px) {
      // width: 90%;
    }
  }
  &__close {
    width: 30px;
    height: 30px;
  }
  &__header {
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    // padding: 20px 20px 10px;
  }
  &__body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
}
// .sticky-border{
//     border-color: rgba(182, 159, 78, 0.38);
// }
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
