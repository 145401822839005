<template>
    <div class="fixed bottom-0 right-0 m-6 index-z">
        <Transition name="slide-fade">
            <div v-if="message" class="bg-white text-red-900 rounded-lg border-2 border-yellow-600 shadow-md p-6 pr-10" style="min-width: 280px">
                <!-- <button class="opacity-75 cursor-pointer absolute top-0 right-0 py-2 px-3 hover:opacity-100">
                    ×
                </button> -->
                <div class="flex items-center text-gray-700 font-semibold">
                    {{ message.data }}
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
    export default {
        name : 'message',
        data () {
            return {
                message : null
            }
        },
        mounted () {
            let timer
            window.Bus.$on('message', (message) => {
                clearTimeout(timer)
                
                this.message = message
                
                timer = setTimeout(() => {
                    this.message = null
                }, 10000)
            })
        },
        methods : {

        }
    }
</script>

<style lang="scss" scoped>

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}
.index-z{
    z-index: 99999999;
}
</style>