import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import services from "./services";
import lazyload from "./custom-directives/lazyload";
import "./filters";
import "./veevalidate";
import {
  Input,
  Drawer,
  Carousel,
  CarouselItem,
  InfiniteScroll,
  ColorPicker,
  Dialog,
  Button,
  Slider,
  InputNumber,
  Tooltip,
  Upload,
  Loading,
  Select,
  OptionGroup,
  Option,
  MessageBox,
  Card,
  Row,
  Col,
  Image,
  Checkbox,
  Switch,
  SkeletonItem,
  Skeleton,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

// import Notifications from 'vt-notifications'
import Notifications from "vue-notification";
import VueCroppie from "vue-croppie";
import i18n from "@/plugins/i18n";
import "croppie/croppie.css";
import VueTour from "vue-tour";
import ProgressBar from "vuejs-progress-bar";
import { createPinia, PiniaVuePlugin } from "pinia";

// 👉 Internal and External CSS
import "./assets/css/main.css";
import "./assets/css/icon.min.css";
import "./assets/css/iconly.min.css";
import "./assets/css/material-icons.min.css";
import "./assets/css/styles.css";
import "./assets/css/custom.css";

if (process.env.VUE_APP_CUSTOM_CSS === 'True') {
	import('./assets/css/global.css').then(() => {
	  document.body.classList.add('custom-css');
	}).catch(error => {
	  console.error('Failed to load custom CSS:', error);
	});
  }

require("vue-tour/dist/vue-tour.css");

Vue.config.productionTip = false;
Vue.config.devtools = false;
locale.use(lang);

Vue.use(ProgressBar);
Vue.use(Loading.directive);
Vue.use(VueTour);
Vue.use(InfiniteScroll);
Vue.use(services);
Vue.use(PiniaVuePlugin);
Vue.use(Notifications);
Vue.use(Dialog);
Vue.use(InputNumber);
Vue.use(Drawer);
Vue.use(Select);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(OptionGroup);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(ColorPicker);
Vue.use(Upload);
Vue.use(Slider);
Vue.use(Option);
Vue.use(Button);
Vue.use(Tooltip);
Vue.use(Image);
Vue.use(Checkbox);
Vue.use(Input);
Vue.use(Switch);
Vue.use(SkeletonItem);
Vue.use(Skeleton);
Vue.use(VueCroppie);

window.Bus = new Vue();

Vue.directive("lazyload", lazyload);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;

const pinia = createPinia();



const app = new Vue({
    i18n,
    router,
    store,
    pinia,
    render: (h) => h(App),
    
}).$mount("#app");


if (window.Cypress) {
  window.__app__ = app;
}
