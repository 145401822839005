export default {
	setLocale: window.localStorage.getItem('xlocale') || 'se',
	model_invite: false,
	model_photo: false,
	model_util: false,
	categoryDrawer: false,
	orderDetailDrawer: false,
	customerSettingDrawer: false,
	customerCategoryDrawer: false,
	blogDrawer: false,
	createOrderDrawer: false,
	createArticleDrawer: false,
	createCustomerDrawer: false,
	editCustomerDrawer: false,
	editOrderDrawer: false,
	openCartDrawer: false,
	openCustomerSpecificSlider: false,
}
