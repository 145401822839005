import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'

const administrator = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}

export default administrator
