<template>
	<tr>
		<td>
			<div
				style="z-index: 99999"
				class="w-dropdown absolute bg-white shadow border rounded"
				v-if="openArticle && filteredArticle.length > 0"
			>
				<ul class="list-ul">
					<li
						class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
						v-for="(item, i) in filteredArticle.slice(0, 5)"
						:key="i"
						@click="setArticle(item)"
					>
						{{
							item.article_number + " (" + item.description + ")"
						}}
					</li>
				</ul>
			</div>
			<div
				class="absolute inset-0 z-40"
				@click="openArticle = false"
				v-if="openArticle"
			></div>
			<input
				type="text"
				v-model="item.article_number"
				@input="filterArticle"
				@focus="openArticle = true"
				class="w-30 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
				style="outline: currentcolor none medium"
			/>
		</td>
		<td>
			<input
				type="text"
				v-model="item.description"
				class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
				style="outline: currentcolor none medium"
			/>
		</td>
		<td>
			<input
				type="number"
				v-model="item.price"
				@input="calculateTotal"
				min="0"
				class="w-16 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
				style="outline: currentcolor none medium"
			/>
		</td>
		<td class="position-relative">
			<i
				class="fa fa-sync icon-sync cursor-pointer"
				v-on:click="switchDiscountType"
			></i>
			<input
				type="number"
				v-model="item.discount"
				min="0"
				@input="calculateTotal"
				class="w-16 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
				style="outline: currentcolor none medium"
			/>
			<i class="percent-sign" v-if="item.discount_type === 'PERCENT'"
				>%</i
			>
		</td>
		<td>
			<input
				type="number"
				min="0"
				v-model="delivered_quantity"
				@input="calculateTotal"
				class="w-16 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
				style="outline: currentcolor none medium"
			/>
		</td>
		<td>
			<div
				style="z-index: 99999"
				class="w-dropdown absolute bg-white shadow border rounded"
				v-if="openAccount && filteredAccount.length > 0"
			>
				<ul class="list-ul">
					<li
						class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
						v-for="(item, i) in filteredAccount.slice(0, 5)"
						:key="i"
						@click="setAccount(item)"
					>
						{{ item.number + " (" + item.description + ")" }}
					</li>
				</ul>
			</div>
			<div
				class="absolute inset-0 z-40"
				@click="openAccount = false"
				v-if="openAccount"
			></div>
			<input
				type="text"
				v-model="item.account_number"
				@focus="openAccount = true"
				@input="filterAccount"
				class="w-16 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
				style="outline: currentcolor none medium"
			/>
		</td>
		<td>
			<div
				style="z-index: 99999; width: 21%"
				class="w-dropdown absolute bg-white shadow border rounded"
				v-if="openCostCenter && filteredCostCenter.length > 0"
			>
				<ul class="list-ul w-50">
					<li
						class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
						v-for="(item, i) in filteredCostCenter.slice(0, 5)"
						:key="i"
						@click="setCostCenter(item)"
					>
						{{ item.description }}
					</li>
				</ul>
			</div>
			<div
				class="absolute inset-0 z-40"
				@click="openCostCenter = false"
				v-if="openCostCenter"
			></div>
			<input
				type="text"
				v-model="cost_center_description"
				@focus="openCostCenter = true"
				@input="filterCostCenter"
				class="w-16 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
				style="outline: currentcolor none medium"
			/>
		</td>
		<td>
			<input
				type="text"
				v-model="item.total"
				disabled=""
				class="w-16 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
				style="outline: currentcolor none medium"
			/>
		</td>
		<td>
			<span
				v-on:click="deleteRow"
				class="flex justify-center align-items-center h-5 w-5 rounded-full bg-red-600 cursor-pointer"
			>
				<img
					src="./../../../../assets/images/icons/delete.png"
					alt=""
				/>
			</span>
		</td>
	</tr>
</template>

<script>
import debounce from "lodash.debounce";
import { mapGetters } from "vuex";

export default {
	name: "orderRowForm",
	props: {
		item: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
	},
	data: function () {
		return {
			openArticle: false,
			openAccount: false,
			openCostCenter: false,
			filteredArticle: [],
			filteredAccount: [],
			filteredCostCenter: [],
			costCenter: [],
			activeAccounts: [],
			delivered_quantity: 0,
			cost_center_description: "",
		};
	},
	mounted() {
		this.delivered_quantity = this.item.delivered_quantity;
		this.activeAccounts = this.GET_ALL_ACTIVES_ACCOUNTS;
		this.costCenter = this.GET_ALL_COST_CENTER;
		let exist_cost_center = this.costCenter.filter(
			(item) => String(item.code) === String(this.item.cost_center)
		);
		if (exist_cost_center.length > 0) {
			this.cost_center_description = exist_cost_center[0]["description"];
		}
		this.calculateTotal();
	},
	methods: {
		calDiscountedPrice: function () {
			if (parseFloat(this.item.discount) > 0) {
				let qty = 1;
				if (parseFloat(this.delivered_quantity) > 0) {
					qty = this.delivered_quantity;
				}
				this.item.total = qty * parseFloat(this.item.discount);
			}
		},
		setArticle: function (item) {
			this.item.article_number = item.article_number;
			this.item.description = item.description;
			this.item.price = item.sales_price;
			if (this.item.price && this.item.delivery_quantity) {
				this.item.total =
					parseFloat(this.item.price) *
					parseInt(this.item.delivery_quantity);
			} else {
				if (this.item.price) {
					this.item.delivery_quantity = 1;
					this.item.total = this.item.price;
				}
			}
			this.openArticle = false;
		},
		setAccount: function (item) {
			this.item.account_number = item.number;
			this.openAccount = false;
		},
		setCostCenter: function (item) {
			this.item.cost_center = item.code;
			this.cost_center_description = item.description;
			this.openCostCenter = false;
		},
		filterArticle: debounce(function (event) {
			let url = `?limit=100`;
			if (event.target.value) {
				url += `&search=${event.target.value}`;
			}
			this.$store
				.dispatch("portal/getArticles", { url: url })
				.then((resp) => {
					this.openArticle = true;
					this.filteredArticle = resp.data.data;
				});
		}, 500),
		filterAccount: function (event) {
			if (event.target.value) {
				this.openAccount = true;
				this.filteredAccount = this.activeAccounts.filter((account) => {
					return String(account.number).includes(event.target.value);
				});
			}
		},
		filterCostCenter: function (event) {
			if (event.target.value) {
				this.openCostCenter = true;
				this.filteredCostCenter = this.costCenter.filter((account) => {
					return String(account.description).includes(
						event.target.value
					);
				});
			}
		},
		deleteRow: function () {
			window.Bus.$emit("remove-row-item", this.index);
		},
		getCostCenter: function () {
			this.$store.dispatch("portal/getCostCenter").then((resp) => {
				this.costCenter = resp.data.results;
			});
		},
		getAccounts: function () {
			this.$store.dispatch("portal/getActiveAccounts").then((resp) => {
				this.activeAccounts = resp.data.results;
			});
		},
		calculateTotal: function () {
			/*
			 * Method compute total amount for the order/ invoice row
			 * */
			this.item.delivered_quantity = this.delivered_quantity;
			if (this.item.price) {
				let price = this.item.price;
				let totalPrice = this.item.price;
				if (parseFloat(this.item.discount) > 0) {
					if (this.item.discount_type === "AMOUNT") {
						totalPrice =
							parseFloat(price) - parseFloat(this.item.discount);
					} else {
						let percent =
							parseFloat(this.item.discount / 100) * price;
						totalPrice = price - percent;
					}
				}
				this.item.total = parseFloat(
					totalPrice * parseInt(this.delivered_quantity)
				).toFixed(2);
			}
		},
		switchDiscountType: function () {
			/**
			 * method handle switching discount type to either AMOUNT or PERCENT
			 */
			if (this.item.discount_type === "AMOUNT") {
				this.item.discount_type = "PERCENT";
			} else {
				this.item.discount_type = "AMOUNT";
			}
			this.calculateTotal();
		},
	},
	computed: {
		...mapGetters({
			GET_ALL_ACTIVES_ACCOUNTS: "portal/GET_ALL_ACTIVE_ACCOUNTS",
			GET_ALL_COST_CENTER: "portal/GET_ALL_COST_CENTER",
		}),
	},
};
</script>

<style scoped>
.list-ul {
	text-align: left;
	padding-left: 5px;
}

.w-dropdown {
	margin-top: 37px !important;
	width: 28%;
}

.icon-sync {
	position: absolute;
	left: 11px;
	top: 24px;
	font-size: 12px;
}

.percent-sign {
	position: absolute;
	top: 20px;
	right: 13px;
}
</style>
