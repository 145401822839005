<template>
	<notify-me :event-bus="bus">
		<template slot="content" slot-scope="{ data }" class="shadow-lg">
			<div
				style="
					width: 100%;
					text-align: left;
					z-index: 999999999 !important;
				"
			>
				<h4>
					<b>{{ data.title }}</b>
				</h4>
				<p style="margin: 0" class="font-light text-xs word-breaking">
					{{ data.text }}
				</p>
			</div>
		</template>
	</notify-me>
</template>

<script>
import Notify from "vue-notify-me";
import bus from "./../bus";

export default {
	name: "notification-alert",
	components: {
		"notify-me": Notify,
	},
	data() {
		return {
			bus,
		};
	},
	// props : {
	//     notifications : {
	//         type : Object,
	//         required : true
	//     }
	// }
};
</script>

<style lang="scss" scoped>
.z-index-notification {
	z-index: 999999;
}

.word-breaking {
	overflow-wrap: anywhere !important;
}
</style>
