<template>
	<div class="w-48">
		<div class="toggle-holder toggle">

			<div
				class="w-50 text-xs"
			>
												<span class="toggle-btn toggle-btn-left font-semibold cursor-pointer"
													  :class="{'toggle-active':  selected === true}"
													  v-on:click="onPick(true)">{{
														$t('yes')
													}}</span>
			</div>
			<div
				class="text-xs w-50"
			>
												<span class="toggle-btn toggle-btn-right font-semibold cursor-pointer"
													  :class="{'toggle-active':  selected === false}"
													  v-on:click="onPick(false)">
													{{
														$t('no')
													}}
												</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "toggleComponent",
	props: {
		picked: {
			type: Boolean,
			required: true
		}
	},
	data: function () {
		return {
			selected: ''
		}
	},
	methods: {
		onPick: function (pick) {
			this.selected = pick
			this.$emit('onpicked', pick)
		}
	},
	mounted() {
		this.selected = this.picked
	}
}
</script>

<style scoped>
.toggle {
	display: flex;
	justify-content: flex-end;
}

.toggle-holder {
	background: #333B95;
	border-radius: 5px;
	width: 100%;
	display: flex;
	height: 30px;
}

.toggle-btn {
	color: #FFFFFF;
	padding-left: 20px;
	padding-right: 20px;
	position: absolute;
	top: 6px;
	border-radius: 5px;
	width: 50%;
}

.toggle-active {
	border: 1px solid #fff;
	background: #fff;
	color: #201d1d !important;
}

.toggle-btn-left {
	width: 45%;
	margin-left: 9px;
	text-align: center;

}

.toggle-btn-right {
	width: 45%;
	margin-left: 3px;
	text-align: center;
}
</style>
