import { render, staticRenderFns } from "./editCustomer.vue?vue&type=template&id=862d4940&scoped=true&"
import script from "./editCustomer.vue?vue&type=script&lang=js&"
export * from "./editCustomer.vue?vue&type=script&lang=js&"
import style0 from "./editCustomer.vue?vue&type=style&index=0&id=862d4940&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "862d4940",
  null
  
)

export default component.exports