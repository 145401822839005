const TYPES = {
	UPDATE_AUTH_USER: 'UPDATE_AUTH_USER',
	UPDATE_USER_COMPANY: 'UPDATE_USER_COMPANY',
	UPDATE_COMPANY_SETTING: 'UPDATE_COMPANY_SETTING',
	UPDATE_ARTICLES: 'UPDATE_ARTICLES',
	UPDATE_SEARCH_ARTICLES: 'UPDATE_SEARCH_ARTICLES',
	UPDATE_ARTICLES_SPREADSHEET: 'UPDATE_ARTICLES_SPREADSHEET',
	UPDATE_ORDERS: 'UPDATE_ORDERS',
	UPDATE_INVOICE: 'UPDATE_INVOICE',
	UPDATE_BILLING: 'UPDATE_BILLING',
	UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
	UPDATE_PRICE_LIST: 'UPDATE_PRICE_LIST',
	UPDATE_COST_CENTER: 'UPDATE_COST_CENTER',
	UPDATE_CUSTOMER_CATEGORY: 'UPDATE_CUSTOMER_CATEGORY',
	UPDATE_SHOP_USERS: 'UPDATE_SHOP_USERS',
	UPDATE_SHOP_CUSTOMERS: 'UPDATE_SHOP_CUSTOMERS',
	UPDATE_NEW_SHOP_CUSTOMERS: 'UPDATE_NEW_SHOP_CUSTOMERS',
	UPDATE_SHOP_CUSTOMER_DRAWER_SETTING: 'UPDATE_SHOP_CUSTOMER_DRAWER_SETTING',
	UPDATE_SHOP_CUSTOMERS_DRAWER: 'UPDATE_SHOP_CUSTOMERS_DRAWER',
	UPDATE_INVITED_CUSTOMERS: 'UPDATE_INVITED_CUSTOMERS',
	UPDATE_DASHBOARD_METRICS: 'UPDATE_DASHBOARD_METRICS',
	FILTER_CUSTOMER_ALL: 'FILTER_CUSTOMER_ALL',
	FILTER_SEARCH_CUSTOMER: 'FILTER_SEARCH_CUSTOMER',
	FILTER_ORDERS_ALL: 'FILTER_ORDERS_ALL',
	FILTER_SEARCH_ORDER: 'FILTER_SEARCH_ORDER',
	UPDATE_USER_SUBCRIPTION_TYPE: 'UPDATE_USER_SUBCRIPTION_TYPE',
	UPDATE_USER_SUBCRIPTION: 'UPDATE_USER_SUBCRIPTION',
	UPDATE_SELECTED_ARTICLE: 'UPDATE_SELECTED_ARTICLE',
	UPDATE_USER_NOTIFICATION: 'UPDATE_USER_NOTIFICATION',
	UPDATE_ALL_NOTIFICATION: 'UPDATE_ALL_NOTIFICATION',
	UPDATE_ACTIVE_ACCOUNTS: 'UPDATE_ACTIVE_ACCOUNTS',
	UPDATE_PRODUCT_CATEGORY: 'UPDATE_PRODUCT_CATEGORY',
	UPDATE_COMPANY_FORM_HELP_TEXT: 'UPDATE_COMPANY_FORM_HELP_TEXT',
	UPDATE_USER_BILLING: 'UPDATE_USER_BILLING',
	UPDATE_APPLICATION_LOG: 'UPDATE_APPLICATION_LOG',
	UPDATE_ALL_CUSTOMERS: 'UPDATE_ALL_CUSTOMERS',
	UPDATE_CUSTOMIZATION: 'UPDATE_CUSTOMIZATION',
	UPDATE_BLOG_CATEGORY: 'UPDATE_BLOG_CATEGORY',
	UPDATE_BLOG_TAG: 'UPDATE_BLOG_TAG',
	UPDATE_BLOG_POST: 'UPDATE_BLOG_POST',
	UPDATE_SINGLE_BLOG_POST: 'UPDATE_SINGLE_BLOG_POST',
	BULK_BLOG_POST_DELETE: 'BULK_BLOG_POST_DELETE',
	UPDATE_FAQZ: 'UPDATE_FAQZ',
	UPDATE_FAQ_CATEGORY: 'UPDATE_FAQ_CATEGORY',
	UPDATE_COMPANY_CHECKOUT: 'UPDATE_COMPANY_CHECKOUT',
	UPDATE_COMPANY_SUBSCRIPTION_SETTING: 'UPDATE_COMPANY_SUBSCRIPTION_SETTING',
	SET_CATEGORY_STRUCTURE: 'SET_CATEGORY_STRUCTURE',
	SET_UNIT_TYPES: 'SET_UNIT_TYPES',
	SET_ALL_ARTICLES: 'SET_ALL_ARTICLES',
	SET_ALL_ACTIVE_ACCOUNTS: 'SET_ALL_ACTIVE_ACCOUNTS',
	CLEAR_ALL_ARTICLES: 'CLEAR_ALL_ARTICLES',
	CLEAR_ALL_ACTIVE_ACCOUNTS: 'CLEAR_ALL_ACTIVE_ACCOUNTS',
	CLEAR_ALL_COST_CENTER: 'CLEAR_ALL_COST_CENTER',
	SET_ALL_COST_CENTER: 'SET_ALL_COST_CENTER',
}

export default TYPES
