export default {
	GET_ENTRY_ACCOUNT_NAME: 'accounts/get_name/',
	CREATE_SHOPOWNER_ACCOUNT: 'accounts/',
	CREATE_SHOP_OWNER_ACCOUNT_V2: 'accounts/account-create-v2/',
	FORGET_PASSWORD_ENDPOINT: 'accounts/reset/',
	RESET_PASSWORD_ENDPOINT: 'accounts/reset/change_password/',
	COMPANY_ENDPOINT: 'accounts/company',
	COMPANY_SETTINGS_ENDPOINT: 'accounts/company_settings',
	GET_AUTH_USER: 'accounts/get_user_type/',
	GET_AUTH_USER_ME: 'accounts/get_user_type/me/',
	SHOP_USERS: 'accounts/users/',
	COMPANY_ENDCUSTOMERS: 'accounts/endcustomers/',
	BUYER_ACCOUNT_SETTINGS: 'accounts/settings/endcustomer_settings/',
	USER_ACCOUNT_SETTINGS: 'accounts/settings/',
	ENDUSER_EMPLOYEE_INVITE: 'accounts/endcustomers/send_invite_code/',
	AUTH_USER_LOGIN: 'api-token-auth/',
	CUSTOMER_SUPPORT_USER_LOGIN: 'api-customer-support-token/',
	EMAIL_VERIFICATION: 'api-email-verification/',
	GET_PRICE_LIST: 'store/pricelists/',
	ACTIVE_ACCOUNT_NUMBERS: 'store/account_number/',
	ARTICLES_ENDPOINT: 'products/articles',
	NOTIFICATION_ENDPOINT: 'notifications/',
	COMPANY_ORDERS: 'orders', // Handles global order irrespective of invoice/orders (New changes in API)
	UNIT_TYPES: 'products/unit_types/',
	COMPANY_INVOICE: 'invoices/',
	INTEGRATION_ENDPOINTS: 'accounts/integration/',
	INTEGRATIONS_ENDPOINTS: 'accounts/integration',
	SHOPIFY_PERMISSION_ENDPOINT: 'shopify/connect/',
	FORTNOX_ADDITION_ENDPOINT: 'accounts/add_fortnox/',
	ONBOARDING_PERMISSION_URL: 'accounts/permission_url/',
	ONBOARDING_ACCESS_TOKEN: 'accounts/get_access_token/',
	SHOPIFY_CONNECT: 'shopify/connect/',
	INTEGRATION_PROVIDER_ENDPOINT: 'accounts/integration-provider/',
	WEBSHOP_PRODUCTS: 'web-shop-products/',
	PRODUCT_CATEGORY_ENDPOINT: 'products/article_category/',
	SUB_CATEGORY_ENDPOINT: 'products/article_sub_category/',
	CATEGORY_STRUCTURE_ENDPOINT: 'products/article_category_structure/',
	PRODUCT_SPECIFICATION: 'products/articles_specification/',
	ARTICLE_IMAGES: 'products/articles/upload_article_image/',
	CUSTOMER_CATEGORY: 'accounts/endcustomers_category/',
	COMPANY_LOGO_UPLOAD: 'accounts/upload_logo/',
	COST_CENTER_ENDPOINT: 'store/cost_center/',
	CURRENCY_ENDPOINT: 'store/currency/',
	PROJECT: 'store/project/',
	PAYMENT_TERMS: 'store/payment_terms/',
	SHOPOWNERS_EMPLOYEES: 'accounts/shopowner_employees/',
	CUSTOMERS_EMPLOYEES: 'accounts/buyer_employees/',
	APPLICATION_LOGS: 'analytics/events',
	DASHBOARD_METRICS: 'analytics/kpis/',
	GRAPH_FILTER_ENDPOINT: 'analytics/kpis/get_revenue_graph_data/',
	INHOUSE_KPI: 'analytics/kpis/inhouse_kpi/',
	REGISTER_CUSTOMERS: 'accounts/endcustomers/register_end_user/',
	ACCEPT_INVITATION: 'accounts/invitation/accept/',
	SHOP_LIST: 'store/suppliers/',
	SHOP_SETUP: 'store/setup/',
	SHOP_SETTING: 'accounts/shop_setting/',
	COUNTRY_DATA: 'accounts/countries/',
	INVOICE_DOWNLOAD: 'invoices/get_invoices_pdf/',
	ORDER_DOWNLOAD: 'orders/get_orders_pdf/',
	REMOVE_ARTICLE_IMAGE: 'products/articles/delete_article_image/',
	SUBSCRIPTION_ENDPOINT: 'subscription/',
	SEND_REQUEST: 'accounts/endcustomers/send_request/',
	BILLING_ENDPOINT: 'subscription/billing/',
	INTEGRATION_METRICS_ENDPOINT: 'web-shop-products/analytics',
	CONNECTED_WEBSHOP: 'accounts/integration/',
	REGISTER_ENDCUSTOMERS: 'accounts/endcustomers/register_buyer_from_fortnox/',
	ACCEPT_REQUEST: 'accounts/endcustomers/accept_request/',
	GET_ALL_CUSTOMERS: 'accounts/endcustomers/get_all_customers/',
	BLOG_ENDPOINT: 'blog/',
	FAQ_ENDPOINT: 'faq/',
	CHECKOUT_CUSTOMIZER: 'accounts/checkout_customization/',
	CUSTOMER_DELIVERY_ADDRESS: 'accounts/customer_delivery_address/',
	MICROSOFT_OAUTH_URL: 'accounts/microsoft_o365/oauth_url/',
	MICROSOFT_OAUTH_TOKEN: 'accounts/microsoft_o365/token/',
	ADMIN_RESOURCE_VIDEOS: 'admin_resources/video_guides',
	INTEGRATIONS: 'integrations'
}
