import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {getToken} from "../services-jwt";
import {getBuyerId, getCompanyId} from "../storage-window";
import http from "./axios";
// import { getToken } from './../services-jwt'

Vue.use(VueAxios, axios)

const downloadHTTP = Vue.axios.create({
	baseURL: process.env.VUE_APP_BASE_URL_EXTERNAL,
	responseType: 'blob',
	headers: {
		'Content-Type': 'application/pdf',
	}
})

downloadHTTP.interceptors.request.use(
	function (req) {
		try {
			if (window.location.pathname.includes('/portal')) {
				if (getCompanyId()) {
					if (req.url.includes('?') === false) {
						if (req.url.includes('company_id') === false) {
							req.url += `?company_id=${getCompanyId()}`
						}
					} else {
						if (req.url.includes('company_id') === false) {
							req.url += `&company_id=${getCompanyId()}`
						}
					}
				}
			}
		} catch (e) {
		}
		try {
			if (window.location.pathname.includes('/customer') || getBuyerId()) {
				if (getBuyerId()) {
					let buyerInfo = getBuyerId()
					if (buyerInfo.hasOwnProperty('buyer_id') && buyerInfo.hasOwnProperty('referrer')) {
						if (req.url.includes('?') === false) {
							if (req.url.includes('buyer_id') === false) {
								req.url += `?buyer_id=${buyerInfo['buyer_id']}`
							}
						} else {
							if (req.url.includes('buyer_id') === false) {
								req.url += `&buyer_id=${buyerInfo['buyer_id']}`
							}
						}
					}

				}
			}
		} catch (e) {

		}
		return req
	},
	function (err) {
		// console.clear()
		return Promise.reject(err)
	}
)
export default downloadHTTP

