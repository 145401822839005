import http from "./../../../../services/vue-axios/cus";
import HTTPEntry from "./../../../../services/vue-axios/entry";
import downloadHTTP from "./../../../../services/vue-axios/download";
import endpoints from "./../../../../services/endpoints";
import TYPES from "./../types";

import downloadExcelHTTP from "../../../../services/vue-axios/customer_download_excel";

export default {
	async getAuthUser({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.GET_AUTH_USER)
				.then((res) => {
					if (res.status === 200) {
						commit("portal/UPDATE_AUTH_USER", res.data, {
							root: true,
						});
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getUserInformation({ comment }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.SHOP_USERS}${payload.id}`)
				.then((res) => {
					return resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateUserInformation({ comment }, payload) {
		return new Promise((resolve, reject) => {
			http.put(`${endpoints.SHOP_USERS}${payload.id}`, payload.data)
				.then((res) => {
					return resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCompanyUser({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.COMPANY_ENDPOINT)
				.then((res) => {
					if (res.status === 200) {
						commit("portal/UPDATE_USER_COMPANY", res.data, {
							root: true,
						});
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCompanyInfo({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`accounts/endcustomers/retrieve_single_end_customer/`)
				.then((res) => {
					commit(TYPES.USER_INFO, res.data.data);
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCompanySettings({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.COMPANY_SETTINGS_ENDPOINT}/${payload.id}/`)
				.then((res) => {
					commit(TYPES.UPDATE_COMPANY_SETTINGS, res.data.data);
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getArticles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = `${endpoints.ARTICLES_ENDPOINT}/${payload.pageUrl}`;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
				
						commit(TYPES.UPDATE_ARTICLES, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
			
					reject(err);
				});
		});
	},
	async getCompanyArticlesAddon({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = `${endpoints.ARTICLES_ENDPOINT}/company_article_addon/${payload.addon}/${payload.URL}`;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
				
						resolve(res);
					}
				})
				.catch((err) => {
				
					reject(err);
				});
		});
	},
	async getSingleArticles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.ARTICLES_ENDPOINT + payload.pageUrl;
			http.get(pageUrl)
				.then((res) => {
					commit(TYPES.UPDATE_SINGLE_ARTICLE, res.data);
					resolve(res);
				})
				.catch((err) => {
		
					reject(err);
				});
		});
	},
	async getGenericSingleArticles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.ARTICLES_ENDPOINT + payload.pageUrl;
			http.get(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
		
					reject(err);
				});
		});
	},
	async getFilterArticles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.ARTICLES_ENDPOINT + `?${payload.query}`)
				.then((res) => {
					if (res.status === 200) {
			
						commit(TYPES.UPDATE_ARTICLES, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
	
					reject(err);
				});
		});
	},
	async getProductCategory({ commit }, payload) {
		http.get(endpoints.PRODUCT_CATEGORY_ENDPOINT + `?${payload.query}`)
			.then((res) => {
		
				if (res.status === 200) {
					commit(TYPES.UPDATE_CATEGORY, res.data);
				}
			})
			.catch((_) => {});
	},
	async getSubCategoryProducts({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SUB_CATEGORY_ENDPOINT + `?${payload}`)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async downloadFile({ rootState }, payload) {
		return new Promise((resolve, reject) => {
			const token = rootState.auth.cusToken;

			let URL = "";
			if (payload.key === "invoice") {
				URL =
					endpoints.ORDER_DOWNLOAD +
					`?is_invoice=True&document_number=${payload.doc}&token=${token}&order_id=${payload.id.id}`;
			} else {
				URL =
					endpoints.ORDER_DOWNLOAD +
					`?is_invoice=False&document_number=${payload.doc}&token=${token}&order_id=${payload.id.id}`;
			}
			downloadHTTP
				.get(URL, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getUserCompanyName({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.COMPANY_ENDCUSTOMERS)
				.then((res) => {
					if (res.status === 200) {
					
						commit(TYPES.UPDATE_CUSTOMER_NAME, res.data.data);
						resolve(res);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_CUS", null, { root: true });
					}
					reject(err);
				});
		});
	},
	async getUserAccountSettings({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.BUYER_ACCOUNT_SETTINGS)
				.then((res) => {
					commit(TYPES.UPDATE_USER_SETTINGS, res.data.data);
					resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_CUS", null, { root: true });
					}
					reject(err);
				});
		});
	},
	async patchUserSettings({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.patch(
				`${endpoints.USER_ACCOUNT_SETTINGS}${payload.id}/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_CUS", null, { root: true });
					}
					reject(err);
				});
		});
	},
	async getCompanyOrders({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.COMPANY_ORDERS + payload.page;
			// if (payload.status) {
			//     pageUrl = payload.page || endpoints.COMPANY_ORDERS + `?is_invoice=False&tab_status=${payload.status}&limit=${payload.limit}`
			// } else {
			//     pageUrl = payload.page || endpoints.COMPANY_ORDERS + `?is_invoice=False&limit=${payload.limit}`
			// }
			http.get(pageUrl)
				.then((res) => {
		
					if (res.status === 200) {
						commit("portal/UPDATE_ORDERS", res.data, {
							root: true,
						});
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateNoteStatus({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.COMPANY_ORDERS +
				`/${payload.id}/update_order_note_status/`;
			http.put(pageUrl, payload.data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
	
					reject(err);
				});
		});
	},
	async updateOrders({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.COMPANY_ORDERS + payload.URL;
			http.put(pageUrl, payload.data)
				.then((res) => {
			
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	async updateOrderNote({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.COMPANY_ORDERS + payload.URL;
			http.put(pageUrl, payload.data)
				.then((res) => {
			
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCompanyInvoice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			let pageUrl = "";
			if (payload.status) {
				pageUrl =
					payload.page ||
					endpoints.COMPANY_ORDERS +
						`?is_invoice=True&tab_status=${payload.status}&limit=${payload.limit}`;
			} else {
				pageUrl =
					payload.page ||
					endpoints.COMPANY_ORDERS +
						`?is_invoice=True&limit=${payload.limit}`;
			}
			http.get(pageUrl)
				.then((res) => {
			
					if (res.status === 200) {
						commit("portal/UPDATE_INVOICE", res.data, {
							root: true,
						});
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getConnectedShopList({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SHOP_LIST + `${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						// console.log('Shop:', res)
						commit(
							TYPES.UPDATE_CONNECTED_SHOP_LIST,
							res.data.suppliers
						);
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getConnectedListShopList({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SHOP_LIST + `${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						// console.log('Shop:', res)
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getConnectedShop({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SHOP_LIST + `${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						// console.log('Shop:', res)
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},

	async getNonConnectedShopList({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SHOP_LIST + `${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						// console.log('Shop:', res)
						commit(
							TYPES.UPDATE_NON_CONNECTED_SHOP_LIST,
							res.data.suppliers
						);
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async requestAccessShopowner({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.SEND_REQUEST, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async processDeliveryData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.COMPANY_ORDERS + "/", payload.payload)
				.then((res) => {
					// console.log('orderDe:', res)
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async verifyBankId({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(
				endpoints.COMPANY_ORDERS + `/verify_bank_id/?${payload.data}`
			)
				.then((res) => {
					// console.log('orderDe:', res)
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getUserSubscription({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SUBSCRIPTION_ENDPOINT + "buyer/")
				.then((res) => {
					// console.log('user sub:', res)
					if (res.status === 200) {
						commit(TYPES.UPDATE_USER_SUBCRIPTION, res.data.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addUserSubscription({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.SUBSCRIPTION_ENDPOINT + "buyer/", payload)
				.then((res) => {
					if (res.status === 200) {
						// console.log('created')
						dispatch("getUserSubscription")
							.then((_) => {
								resolve(res);
							})
							.catch((_) => {
								resolve(res);
							});
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateUserSubscription({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.SUBSCRIPTION_ENDPOINT + `buyer/${payload.current}/`,
				{
					subscription_type: {
						id: payload.sub,
					},
				}
			)
				.then((res) => {
					if (res.status === 200) {
						dispatch("getUserSubscription")
							.then((_) => {
								resolve(res);
							})
							.catch((_) => {
								resolve(res);
							});
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getShopSetting({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SHOP_SETTING + `?company_id=${payload.id}`)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addWooCommerce({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.INTEGRATION_ENDPOINTS, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async authorizeShopify({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.SHOPIFY_CONNECT, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async connectShopify({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.SHOPIFY_CONNECT, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateConnectedWebshop({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.CONNECTED_WEBSHOP + `${payload.webshop_id_save}/`,
				payload
			)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async authorizeFortnox({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.ONBOARDING_PERMISSION_URL + payload.query)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async fortnoxAddition({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.FORTNOX_ADDITION_ENDPOINT + payload.query)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getShopUsers({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.CUSTOMERS_EMPLOYEES}${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_SHOP_USERS, res.data);
						resolve(res.data);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async patchCustomer({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.patch(
				endpoints.COMPANY_ENDCUSTOMERS + `${payload.id}/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getUserNotification({ commit }, _) {
		http.get(`${endpoints.NOTIFICATION_ENDPOINT}`)
			.then((res) => {
				if (res.status === 200) {
					commit(TYPES.UPDATE_USER_NOTIFICATION, res.data);
				}
			})
			.catch((_) => {});
	},
	async readAllNotification({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.put(endpoints.NOTIFICATION_ENDPOINT + `mark_all_as_read/`)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	// async updateUserSubscription ({ dispatch }, payload) {
	//     return new Promise((resolve, reject) => {
	//         http.put(endpoints.SUBSCRIPTION_ENDPOINT + `supplier/${payload.current}/`, {
	//             subscription_type : {
	//                 id : payload.sub
	//             }
	//         })
	//         .then(res => {
	//             if (res.status === 200) {
	//                 dispatch('getUserSubscription')
	//                 .then(_ => {
	//                     resolve(res)
	//                 })
	//                 .catch(_ => {
	//                     resolve(res)
	//                 })
	//             }
	//         }).catch((err) => {
	//             reject(err)
	//         })
	//     })
	// },
	async deleteUser({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.CUSTOMERS_EMPLOYEES + `${payload.id}/`)
				.then((res) => {
					if (res.status === 204) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteConnectedIntegration({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.INTEGRATION_ENDPOINTS + `${payload.id}/`)
				.then((res) => {
					if (res.status === 204) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateUser({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(endpoints.CUSTOMERS_EMPLOYEES + `${payload.id}/`, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getApplicationLogs({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.APPLICATION_LOGS}${payload.page}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_APPLICATION_LOG, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addUsers({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.ENDUSER_EMPLOYEE_INVITE, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getUserBillingInformation({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(
				`${endpoints.COMPANY_ENDCUSTOMERS}retrieve_single_end_customer/${payload.data}`
			)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_BILLING_INFO, [res.data.data]);
						resolve(res);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_CUS", null, { root: true });
					}
					reject(err);
				});
		});
	},
	async getIntegrationProvider({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.INTEGRATION_PROVIDER_ENDPOINT)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_PROVIDERS, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_CUS", null, { root: true });
					}
					reject(err);
				});
		});
	},
	async getWebshopProducts({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				payload.page ||
				endpoints.WEBSHOP_PRODUCTS +
					`?web_shop_id=${payload.value}&limit=${payload.limit}`;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_WEBSHOP_PRODUCT, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_CUS", null, { root: true });
					}
					reject(err);
				});
		});
	},
	async filterWebshopProducts({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.WEBSHOP_PRODUCTS + `${payload.query}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_WEBSHOP_PRODUCT, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_CUS", null, { root: true });
					}
					reject(err);
				});
		});
	},
	async getCountry({ commit }, _) {
		http.get(endpoints.COUNTRY_DATA)
			.then((res) => {
				if (res.status === 200) {
					// console.log('COUNTRY:', res)
					commit(TYPES.UPDATE_COUNTRY, res.data);
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					commit("auth/CLEAR_AUTH_CUS", null, { root: true });
				}
			});
	},
	async getIntegrationMetric({ commit }, _) {
		http.get(endpoints.INTEGRATION_METRICS_ENDPOINT)
			.then((res) => {
				if (res.status === 200) {
					commit(TYPES.UPDATE_INTEGRATION_METRIC, res.data);
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					commit("auth/CLEAR_AUTH_CUS", null, { root: true });
				}
			});
	},
	async getConnectedWebshop({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const URL = payload.edit
				? endpoints.CONNECTED_WEBSHOP + `${payload.webshopId}/`
				: endpoints.CONNECTED_WEBSHOP;
			http.get(URL)
				.then((res) => {
					if (res.status === 200) {
						if (!payload.edit) {
							commit(TYPES.UPDATED_CONNECTED_WEBSHOP, res.data);
						}
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async acceptUserRequest({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.ACCEPT_REQUEST, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async readNotification({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.NOTIFICATION_ENDPOINT + `${payload.id}/mark_as_read/`
			)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getBlogCategory({ commit }, payload) {
		await http
			.get(endpoints.BLOG_ENDPOINT + `category/?company_id=${payload.id}`)
			.then((res) => {
				if (res.status === 200) {
					commit(TYPES.UPDATE_BLOG_CATEGORY, res.data);
				}
			})
			.catch((_) => {});
	},
	async getBlog({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.BLOG_ENDPOINT + `post?company_id=${payload}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_BLOG_POST, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getBlogs({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.BLOG_ENDPOINT + "post/" + payload.url;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_BLOG_POST, res.data);
						resolve(res);
					}
				})
				.catch((_) => {
					reject(err);
				});
		});
	},
	async getBlogBanner({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.BLOG_ENDPOINT +
				`post/banner?company_id=${payload.company_id}`;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((_) => {
					reject(err);
				});
		});
	},
	async getArchives({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.BLOG_ENDPOINT + "post/archive" + payload.url;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_ARCHIVE, res.data);
						resolve(res);
					}
				})
				.catch((_) => {
					// console.log(err)
					// reject(err)
				});
		});
	},
	async getSingleBlogInformation({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(
				endpoints.BLOG_ENDPOINT +
					`post/${payload.id}/?company_id=${payload.company}`
			)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_SINGLE_BLOG_POST, res.data.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getFaqz({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.FAQ_ENDPOINT + "post/" + payload.url;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_FAQZ, res.data);
						resolve(res);
					}
				})
				.catch((_) => {
					// console.log(err)
					// reject(err)
				});
		});
	},
	async getFaqCategory({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.FAQ_ENDPOINT + "category" + `${payload.data}`;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_FAQ_CATEGORY, res.data);
						resolve(res);
					}
				})
				.catch((_) => {
					// console.log(err)
					// reject(err)
				});
		});
	},
	async getCompanyCheckoutConfig({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.CHECKOUT_CUSTOMIZER + payload.URL;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_CHECKOUT_CONFIG, res.data);
						resolve(res);
					}
				})
				.catch((_) => {
					// console.log(err)
					// reject(err)
				});
		});
	},
	async getArticleSpecifications({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.PRODUCT_SPECIFICATION + payload.URL;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_CHECKOUT_CONFIG, res.data);
						resolve(res);
					}
				})
				.catch((_) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getCustomerDeliveryAddress({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.CUSTOMER_DELIVERY_ADDRESS + payload.URL;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async geCompanyArticleStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.CATEGORY_STRUCTURE_ENDPOINT + payload.URL;
			http.get(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getAllCustomerDeliveryAddress({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.CUSTOMER_DELIVERY_ADDRESS;
			http.get(`${pageUrl}${payload.data}`)
				.then((res) => {
					commit(TYPES.UPDATE_DELIVERY_ADDRESS_DATA, {
						data: res.data.data,
					});
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async saveCustomerDeliveryAddress({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.CUSTOMER_DELIVERY_ADDRESS;
			http.post(pageUrl, payload)
				.then((res) => {
					if (res.status === 201) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async updateCustomerDeliveryAddress({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.CUSTOMER_DELIVERY_ADDRESS + `${payload.id}/`;
			http.put(pageUrl, payload.data)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async deleteCustomerDeliveryAddress({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.CUSTOMER_DELIVERY_ADDRESS + `${payload.id}/`;
			http.delete(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async downloadArticles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.ARTICLES_ENDPOINT + payload.url;
			downloadExcelHTTP
				.get(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getAllNotification({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.NOTIFICATION_ENDPOINT}${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_ALL_NOTIFICATION, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async createOrderInvoiceCopy({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(`${endpoints.COMPANY_ORDERS}/${payload.id}/copy/`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getVideos({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.ADMIN_RESOURCE_VIDEOS}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
};
