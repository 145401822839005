import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {getCusToken} from '../services-jwt'

Vue.use(VueAxios, axios)

const downloadExcelHTTP = Vue.axios.create({
	baseURL: process.env.VUE_APP_BASE_URL_EXTERNAL,
	responseType: 'blob',
	headers: {
		'Content-Type': 'application/vnd.ms-excel',
	}
})

downloadExcelHTTP.interceptors.request.use(
	function (req) {
		const token = `${getCusToken()}`
		if (token) {
			req.headers.common.Authorization = `Token ${token}`
		}
		// console.clear()
		return req
	},
	function (err) {
		// console.clear()
		return Promise.reject(err)
	}
)


export default downloadExcelHTTP

