import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import utility from './modules/utility'
import portal from './modules/portal'
import customer from './modules/customer'
import administrator from './modules/administrator'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		utility,
		portal,
		customer,
		administrator
	}
})
