<template>
	<div class="row dm-sans-font">
		<div class="col-md-12">
			<div class="flex justify-end mt-2">
				<div class="bg-white mb-2 text-align-right w-full lg:w-4/12" style="text-align: right !important;">
					<input type="text" @input="searchInputTrigger"
						   class="space-x-4 focus:outline-none w-full bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
						   v-bind:placeholder="$t('search')">
				</div>
			</div>

		</div>
		<div class="col-md-12">

			<div>

				<div class="overflow-y-scroll" style="height:55vh" v-infinite-scroll="loadMore">
					<single-article-component-tree v-for="(category,key) in categoryTreeList.results"
												   :category="category"
												   :key="key"/>
					<p class="flex justify-center" v-if="loading">
						<svg class="animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
							 fill="none" viewBox="0 0 24 24">
							<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
									stroke-width="4"></circle>
							<path class="opacity-75" fill="currentColor"
								  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
						</svg>
					</p>

				</div>
				<div class="form-group mt-5 text-center flex justify-content-center">
					<button :disabled="assigningProduct" v-on:click="assignMassProduct"
							:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
							:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-blue-900'"
							class="flex justify-center align-items-center space-x-4 bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-2/6">
                                    <span v-if="assigningProduct" class="flex align-items-center space-x-3">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </span>
						<span v-else>{{ $t('assign') }}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
import singleArticleComponentTree from "./categoryTreeComponent/singleArticleComponentTree";
import debounce from 'lodash.debounce'

export default {
	name: "ArticleCategoryAssignComponent",
	components: {singleArticleComponentTree},
	props: {
		articleId: {
			required: true
		},
		selectState: {
			type: String,
			required: true
		},

	},
	data: function () {
		return {
			assigningProduct: false,
			showHere: false,
			openChild: false,
			productObj: {},
			category: {},
			selectionState: 'single',
			categoryTreeList: {results: [], count: 1, page: 1, total_pages: 1},
			categoryTreeListCopy: {results: [], count: 1, page: 1, total_pages: 1},
			singleArticleTree: [],
			loading: false
		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
			GET_COMPANY_SETTING: 'portal/GET_COMPANY_SETTING',
			GET_ARTICLES_CHECKED: 'portal/GET_ARTICLES_CHECKED',
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
	mounted() {
		this.productObj = this.article
		this.selectionState = this.selectState
		if (this.articleId !== null && this.selectionState === 'single') {
			this.getArticleCategory()
		} else {
			this.getCategoryTree()
		}

		window.Bus.$on('toggle-main-category', e => {
			this.checkBox(e)
		})
		window.Bus.$on('toggle-child-category', e => {
			this.checkChildNodeBox(e.category_id, e.node)
		})
	},
	methods: {
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.loading = true
				let pageUrl = `?limit=50&company_id=${this.GET_COMPANY_SETTING.id}&search=${event.target.value}`
				this.$store.dispatch('portal/getCategoryStructure', {data: pageUrl})
					.then(resp => {
						resp.data.data.results.map(item => {
							item.checked = false
							item.child_node.map(ch => {
								ch.checked = false
							})
						})
						this.categoryTreeList = resp.data.data
						// this.categoryTreeList.count = resp.data.data.count
						// this.categoryTreeList.page = resp.data.data.page
						// this.categoryTreeList.total_pages = resp.data.data.total_pages
						this.loading = false
						if (this.articleId !== null && this.selectionState === 'single') {
							let categoryTreeListCopy = this.categoryTreeList.results
							this.singleArticleTree.map(item => {
								categoryTreeListCopy.map(sub_item => {
									if (item.category === sub_item.id) {
										sub_item.checked = true
										item.child_node.map(main_child_node => {
											sub_item.child_node.map(child_item => {
												if (main_child_node.id === child_item.id) {
													child_item.checked = true
												}
											})
										})
									}
								})
							})
							this.categoryTreeList.results = categoryTreeListCopy
						}
					}).catch((err) => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})

			} else {
				if (this.articleId !== null && this.selectionState === 'single') {
					this.getArticleCategory()
				} else {
					this.getCategoryTree()
				}
			}
		}, 500),
		assignMassProduct: function () {
			/*
			Article category assign payload schema
			let payload = {
				articles:[],
				'nodes': [
				  {
					'category': 1,
					'child_node': []
				  }
				]
			  }
			*/
			let categoryTree = []
			this.assigningProduct = true
			let payload = {
				'nodes': []
			}
			this.categoryTreeList.results.map(item => {
				if (item.hasOwnProperty('checked')) {
					if (item.checked === true) {
						let category = {
							category: item.id,
							child_node: []
						}
						item.child_node.map(child_item => {
							if (child_item.hasOwnProperty('checked')) {
								if (child_item.checked === true) {
									category['child_node'].push(child_item.id)
								}
							}
						})
						payload['nodes'].push(category)
					}
				}
			})
			if (this.articleId !== null && this.selectionState === 'single') {
				payload['id'] = this.articleId
				this.$store.dispatch('portal/assignArticleCategoryStructure', {
					id: this.articleId,
					data: {'nodes': payload['nodes']}
				}).then(() => {
					this.assigningProduct = false
					window.Bus.$emit('fetch-article', 'bulk')
					return this.$services.helpers.notification(this.$t('articleCategoryAssign'), 'success', this)
				}).catch(err => {
					this.assigningProduct = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					} else {
						if (err.response.data.hasOwnProperty('message_swe') && err.response.data.hasOwnProperty('message')) {
							if (this.GET_LOCALE === 'se') {
								return this.$services.helpers.notification(err.response.data.message_swe, 'error', this)
							} else {
								return this.$services.helpers.notification(err.response.data.message, 'error', this)
							}
						} else {
							return this.$services.helpers.notification(err.response.data.message, 'error', this)
						}
					}
				})
			} else {
				if (this.GET_ARTICLES_CHECKED.status === true) {
					let articles = []
					this.GET_ARTICLES_CHECKED.data.map(item => {
						articles.push(item.id)
					})
					if (articles.length > 0) {
						payload['articles'] = articles
						if (payload.hasOwnProperty('nodes') && payload.hasOwnProperty('articles')) {
							if (payload['nodes'].length > 0 && payload['articles'].length > 0) {
								this.$store.dispatch('portal/bulkAssignArticleCategoryStructure', {data: payload}).then(_ => {
									this.assigningProduct = false
									window.Bus.$emit('fetch-article', 'bulk')
									return this.$services.helpers.notification(this.$t('articleCategoryAssign'), 'success', this)
								}).catch(err => {
									this.loading = false
									this.assigningProduct = false
									if (err.response.status === 401) {
										this.$store.commit('auth/CLEAR_AUTH_USER', null)
										window.Bus.$emit('sign-out')
									} else {
										this.assigningProduct = false
										if (err.response.data.hasOwnProperty('message_swe') && err.response.data.hasOwnProperty('message')) {
											if (this.GET_LOCALE === 'se') {
												return this.$services.helpers.notification(err.response.data.message_swe, 'error', this)
											} else {
												return this.$services.helpers.notification(err.response.data.message, 'error', this)
											}
										} else {
											return this.$services.helpers.notification(err.response.data.message, 'error', this)
										}

									}
								})
							} else {
								this.assigningProduct = false
								return this.$services.helpers.notification('', 'error', this)
							}
						} else {
							this.assigningProduct = false
							return this.$services.helpers.notification(this.$t('articleCategoryAssignError'), 'error', this)
						}
					} else {
						this.assigningProduct = false
						return this.$services.helpers.notification(this.$t('articleCategoryAssignError'), 'error', this)
					}

				} else {
					this.assigningProduct = false
					return this.$services.helpers.notification(this.$t('articleCategoryAssignError'), 'error', this)
				}
			}


		},
		loadMore: function () {
			if (this.categoryTreeList.page !== this.categoryTreeList.total_pages && this.categoryTreeList.page <= this.categoryTreeList.total_pages) {
				this.loading = true
				let pageUrl = `?page=${this.categoryTreeList.page + 1}&limit=50&company_id=${this.GET_COMPANY_SETTING.id}`
				this.$store.dispatch('portal/getCategoryStructure', {data: pageUrl})
					.then(resp => {
						resp.data.data.results.map(item => {
							item.checked = false
							item.child_node.map(ch => {
								ch.checked = false
							})
						})

						this.categoryTreeList.results = this.categoryTreeList.results.concat(resp.data.data.results)
						this.categoryTreeList.count = resp.data.data.count
						this.categoryTreeList.page = resp.data.data.page
						this.categoryTreeList.total_pages = resp.data.data.total_pages
						this.loading = false
						if (this.articleId !== null && this.selectionState === 'single') {
							let categoryTreeListCopy = this.categoryTreeList.results
							this.singleArticleTree.map(item => {
								categoryTreeListCopy.map(sub_item => {
									if (item.category === sub_item.id) {
										sub_item.checked = true
										item.child_node.map(main_child_node => {
											sub_item.child_node.map(child_item => {
												if (main_child_node.id === child_item.id) {
													child_item.checked = true
												}
											})
										})
									}
								})
							})
							this.categoryTreeList.results = categoryTreeListCopy
						}
					}).catch((err) => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
			}
		},
		getCategoryTree: function () {
			this.$store.dispatch('portal/getCategoryStructure', {data: `?company_id=${this.GET_COMPANY_SETTING.id}`}).then(resp => {
				resp.data.data.results.map(item => {
					item.checked = false
					item.child_node.map(ch => {
						ch.checked = false
					})
				})
				this.categoryTreeList = resp.data.data
				if (this.articleId !== null && this.selectionState === 'single') {
					let categoryTreeListCopy = this.categoryTreeList.results
					this.singleArticleTree.map(item => {
						categoryTreeListCopy.map(sub_item => {
							if (item.category === sub_item.id) {
								sub_item.checked = true
								item.child_node.map(main_child_node => {
									sub_item.child_node.map(child_item => {
										if (main_child_node.id === child_item.id) {
											child_item.checked = true
										}
									})
								})
							}
						})
					})
					this.categoryTreeList.results = categoryTreeListCopy

				}
				this.loading = false
			}).catch(err => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		checkBox: function (item) {
			let askNodeIndex = this.categoryTreeList.results.findIndex(box => item.id === box.id)
			if (askNodeIndex > -1) {
				const newArticleArray = [...this.categoryTreeList.results]
				newArticleArray[askNodeIndex] = {...newArticleArray[askNodeIndex], checked: item.checked}
				let status = item.checked
				if (status === false) {
					newArticleArray[askNodeIndex].child_node.map(res => {
						res.checked = false
					})
				} else {
					newArticleArray[askNodeIndex].child_node.map(res => {
						res.checked = true
					})
				}
				this.categoryTreeList.results = newArticleArray
			}

		},
		checkChildNodeBox: function (mainID, node) {
			let askNodeIndex = this.categoryTreeList.results.findIndex(box => mainID === box.id)
			if (askNodeIndex > -1) {
				const newArticleArray = [...this.categoryTreeList.results]
				const mainCategory = newArticleArray[askNodeIndex]
				let askChildNodeIndex = mainCategory.child_node.findIndex(item => item.id === node.id)
				const newChildNodeArray = [...mainCategory.child_node]
				if (askChildNodeIndex > -1) {
					newChildNodeArray[askChildNodeIndex] = {
						...newChildNodeArray[askChildNodeIndex],
						checked: node.checked
					}
					if (node.checked === true) {
						newArticleArray[askNodeIndex] = {...newArticleArray[askNodeIndex], checked: node.checked}
					}
					if (node.checked === false) {
						let remainChildCheck = newChildNodeArray.filter(item => item.checked === true)
						if (remainChildCheck.length === 0) {
							newArticleArray[askNodeIndex] = {...newArticleArray[askNodeIndex], checked: false}
						}
					}
					this.categoryTreeList.results = newArticleArray
				}
			}
		},
		getArticleCategory: function () {
			this.$store.dispatch('portal/getArticleCategoryStructure', {id: this.articleId}).then(resp => {
				this.singleArticleTree = resp.data.data.categories
				this.getCategoryTree()
			})
		}
	}
}
</script>

<style scoped>

</style>
