// Running out of time, so I'll skip in creating types to handle mutations

export default {
	CHANGE_LOCALE(state, payload) {
		state.setLocale = payload
		setLocale(payload)
	},
	OPEN_INVITE_MODAL(state, _) {
		state.model_invite = !state.model_invite
	},
	OPEN_PHOTO_MODAL(state, _) {
		state.model_photo = !state.model_photo
	},
	OPEN_MODAL_UTIL(state, _) {
		state.model_util = !state.model_util
	},
	OPEN_MODAL_FAQ(state, _) {
		state.modal_inhouse = !state.modal_inhouse
	},
	OPEN_SIDEDRAWER(state, _) {
		state.categoryDrawer = !state.categoryDrawer
	},
	OPEN_CUSTOMER_SETTING_DRAWER(state, _) {
		state.customerSettingDrawer = !state.customerSettingDrawer
	},
	OPENE_CUSTOMER_CATEGRY_DRAWER(state, _) {
		state.customerCategoryDrawer = !state.customerCategoryDrawer
	},
	OPEN_BLOG_DRAWER(state, payload) {
		state.blogDrawer = payload.status
	},
	OPEN_ORDER_DETAIL_DRAWER(state, payload) {
		state.orderDetailDrawer = !state.orderDetailDrawer
	},
	OPEN_CREATE_ORDER_DRAWER(state, payload) {
		state.createOrderDrawer = payload
	},
	OPEN_CREATE_ARTICLE_DRAWER(state, payload) {
		state.createArticleDrawer = payload
	},
	OPEN_CREATE_CUSTOMER_DRAWER(state, payload) {
		state.createCustomerDrawer = payload
	},
	OPEN_EDIT_CUSTOMER_DRAWER(state, payload) {
		state.editCustomerDrawer = payload
	},
	OPEN_EDIT_ORDER_DRAWER(state, payload) {
		state.editOrderDrawer = payload
	},
	UPDATE_CART_DRAWER(state, payload) {
		state.openCartDrawer = payload
	},
	UPDATE_CUSTOMER_SPECIFIC_DRAWER(state, payload) {
		state.openCustomerSpecificSlider = payload
	},
}

function setLocale(payload) {
	window.localStorage.setItem('xlocale', payload)
}
