export default {
	USER_TOKEN(state) {
		return state.token
	},
	CUSTOMER_TOKEN(state) {
		return state.cusToken
	},
	GET_USER_UTIL(state) {
		return state.utility
	},
	GET_USER_PROFILE(state) {
		return state.user
	},
}
