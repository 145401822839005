export const convertDiscountValueToPercentage = (
	discount_value,
	discount_type,
	sales_price
) => {
	if (discount_type === "PERCENT") {
		return discount_value;
	} else {
		const percent = (discount_value * 100) / sales_price;
		const percentRounded = Math.round(percent);
		if (!isFinite(percentRounded)) {
			return 0;
		}
		return percentRounded;
	}
};

export function get_vat_amount(
	prices_given_ink_vat,
	vat,
	delivered_quantity,
	discounted_price
) {
	try {
		if (prices_given_ink_vat) {
			// Means that VAT is included in this product price
			let vat_rate = vat / 100;
			const vat_factor = discounted_price / (1 + vat_rate);
			let vat_amount = discounted_price - vat_factor;
			return vat_amount * delivered_quantity;
		} else {
			// Means that VAT is not included in this product price
			let vat_rate = vat / 100;
			let vat_amount = discounted_price * vat_rate;
			return vat_amount * delivered_quantity;
		}
	} catch (ex) {
		// default value is provided
		return 0;
	}
}
