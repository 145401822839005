<template>
	<div>
		<div
			class="absolute bg-red-30 inset-0 z-10"
			v-if="statusUpdate"
			@click="hideStatus"
		></div>
		<div
			class="absolute inset-0 z-40"
			@click="openChildList = false"
			v-if="openChildList"
		></div>
		<div data-cy="drawer-category-open" class="container visible">
			<div class="row align-items-center mb-4 bg-white m-2 p-2">
				<div class="col-md-9 flex flex-col">
					<div data-cy="category-add-form" class="row">
						<div class="col-md-4">
							<div class="form-group">
								<label class="text-gray-900" for="">{{
									$t("selectCategory")
								}}</label>
								<el-select
									:placeholder="`${$t('selectCategory')}`"
									size="large"
									class="w-full"
									loading-text="loading"
									v-model="cateForm.category"
								>
									<el-option
										:key="'parent'"
										:label="$t('parentCategory')"
										:value="'parent'"
									>
									</el-option>
									<el-option
										:key="'child'"
										:label="$t('childCategory')"
										:value="'child'"
									>
									</el-option>
								</el-select>
							</div>
						</div>
						<div
							v-bind:class="{
								'col-md-5': cateForm.category === 'parent',
								'col-md-4': cateForm.category === 'child',
							}"
							v-if="cateForm.category"
						>
							<div class="form-group">
								<label class="text-gray-900" for="">{{
									$t("name")
								}}</label>
								<input
									v-model="cateForm.value"
									type="text"
									class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
								/>
							</div>
						</div>
						<div
							class="col-md-3"
							v-if="cateForm.category === 'parent'"
						>
							<div class="form-group">
								<label class="text-gray-900" for="">{{
									$t("availableOnlyForSpecificCustomer")
								}}</label>
								<el-switch
									class="article-category-switch"
									@change="openCategory"
									style="display: block; margin-bottom: 6px"
									v-model="showCategory"
								>
								</el-switch>
							</div>
						</div>

						<div
							class="col-md-4"
							v-if="cateForm.category === 'child'"
						>
							<span class="flex"
								><el-switch
									class="article-category-switch"
									style="display: block; margin-bottom: 6px"
									v-model="value2"
								>
								</el-switch>
								<span class="ml-2">{{
									$t("additionalSubCategory")
								}}</span></span
							>
							<div
								class="form-group"
								v-if="
									cateForm.category === 'child' &&
									value2 === false
								"
							>
								<el-select
									:placeholder="`${$t('select')}`"
									size="large"
									filterable
									remote
									class="w-full"
									loading-text="loading"
									v-model="cateForm.parent"
								>
									<el-option
										v-for="item in GET_PRODUCT_CATEGORY.data"
										:key="item.id"
										:label="item.name"
										:value="item.id"
									>
									</el-option>
								</el-select>
								<label class="text-gray-900" for="">{{
									$t("selectParentCategory")
								}}</label>
							</div>

							<div
								class="form-group mt-2 position-relative"
								v-if="value2 === true"
							>
								<div
									@click="showDropDown"
									class="flex h-10 justify-between cursor-pointer align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
								>
									{{ $t("selectChildCategory") }}
								</div>
								<span
									class="text-gray-400 child-category-icon"
									@click="showDropDown"
								>
									<i
										class="fa fa-chevron-down cursor-pointer"
										v-if="openChildList === false"
									></i>
									<i
										class="fa fa-chevron-up cursor-pointer"
										v-else
									></i>
								</span>

								<div
									id="dropdown"
									class="checkbox-select__dropdown position-absolute border w-full bg-white drop-shadow-xl shadow-md"
									v-if="openChildList === true"
								>
									<ul
										id="customScroll"
										class="checkbox-select__filters-wrapp ul-select h-96"
										data-simplebar-auto-hide="false"
									>
										<li>
											<input
												type="text"
												:placeholder="$t('search')"
												class="w-full h-6 border pl-2"
												v-model="searchValue"
												v-on:input="onSearchCategory"
											/>
										</li>
										<li
											v-for="(
												filter, index
											) in categoryTreeList.results"
											v-loading="loadingCategorySelect"
											class="w-full pt-2 pb-2"
											:key="index"
										>
											<div
												class="checkbox-select__check-wrapp"
											>
												<input
													class="checkbox-height"
													type="checkbox"
													v-model="filter.checked"
													:value="filter.checked"
													@change="checkBox(filter)"
													v-bind:checked="
														filter.checked
													"
												/>
												<label
													:for="index"
													class="ml-2 text-base font-normal"
													>{{ filter.name }}</label
												>
											</div>
											<div
												class="grid grid-cols-1 mb-2 overflow-y-scroll"
											>
												<span
													class="text-left text-xm p-1 col-spans-1 text-gray-400 cursor-pointer item-select"
													:key="k"
													v-for="(
														sub, k
													) in filter.child_node"
													style="
														margin-left: 15px;
														font-family: 'Poppins',
															sans-serif;
													"
												>
													<input
														class="checkbox-height text-base"
														v-model="sub.checked"
														v-bind:checked="
															sub.checked
														"
														@change="
															checkChildNodeBox(
																filter.id,
																sub
															)
														"
														:value="sub.checked"
														type="checkbox"
													/>
													{{ sub.name }}
												</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group mt-4">
						<button
							data-cy="drawer-category-add-btn"
							:disabled="addingProductCategory"
							v-if="value2 !== true"
							@click="addCategory"
							:style="
								GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
									? 'background:' + GET_CUSTOMIZATION.button
									: ''
							"
							:class="
								GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
									? ''
									: 'bg-green-500'
							"
							class="w-full text-white px-3 h-9 rounded-md whitespace-nowrap"
						>
							<span
								v-if="addingProductCategory"
								class="flex justify-center"
							>
								<svg
									class="animate-spin h-6 w-6 text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
								>
									<circle
										class="opacity-25"
										cx="12"
										cy="12"
										r="10"
										stroke="currentColor"
										stroke-width="4"
									></circle>
									<path
										class="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									></path>
								</svg>
							</span>
							<span v-else class="text-xs">
								{{ $t("addBtn") }}
							</span>
						</button>
						<button
							data-cy="drawer-category-add-btn"
							:disabled="addingProductCategory"
							v-else
							@click="addSubCategory"
							:style="
								GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
									? 'background:' + GET_CUSTOMIZATION.button
									: ''
							"
							:class="
								GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
									? ''
									: 'bg-green-500'
							"
							class="w-full text-white px-3 h-9 rounded-md whitespace-nowrap"
						>
							<span
								v-if="addingProductCategory"
								class="flex justify-center"
							>
								<svg
									class="animate-spin h-6 w-6 text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
								>
									<circle
										class="opacity-25"
										cx="12"
										cy="12"
										r="10"
										stroke="currentColor"
										stroke-width="4"
									></circle>
									<path
										class="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									></path>
								</svg>
							</span>
							<span v-else class="text-xs">
								{{ $t("addBtn") }}
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="container mb-5">
			<div
				class="row overflow-y-scroll"
				style="height: 68vh"
				v-infinite-scroll="loadMore"
			>
				<div class="col-md-12">
					<div
						class="drawer-category-management rounded-lg bg-white border-solid border-gray-400"
					>
						<div class="row">
							<div class="col-md-12">
								<div class="bg-white category-tree-header">
									<h4 class="text-base font-semibold">
										{{ $t("categoryStructure") }}
									</h4>
								</div>
							</div>
						</div>
						<div class="row" v-if="loading">
							<div class="col-md-12">
								<div class="bg-white h-8">
									<p
										class="flex justify-center text-base text-center"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-gray-400"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
									</p>
								</div>
							</div>
						</div>
						<div v-else>
							<div
								class="row"
								v-if="categoryListing.results.length > 0"
							>
								<div
									class="col-md-12"
									v-for="(
										category, key
									) in categoryListing.results"
									:key="key"
								>
									<category-tree
										:category="category"
										@removeItem="removeItem"
									/>
								</div>
							</div>
							<div class="row" v-else>
								<div class="col-md-12">
									<div class="bg-white h-8">
										<p class="text-base text-center">
											{{ $t("emptyEntry") }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ModalContainer
			v-if="showCategoryModal"
			width="md:w-1/3 w-11/12"
			:title="$t('customerSpecificCategoryText')"
			@handleClose="showCategoryModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="openCustomersDropdown = false"
					v-if="openCustomersDropdown"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form>
							<div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("customerName") }}</label
									>
									<div>
										<input
											v-model="customerSearchName"
											type="text"
											@input="filterCustomers"
											@focus="
												openCustomersDropdown = true
											"
											:placeholder="$t('searchCustomers')"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
									<div
										style="z-index: 99999"
										class="w-1/2 absolute bg-white shadow border rounded mt-1"
										v-if="
											openCustomersDropdown &&
											filteredCustomer.length > 0
										"
									>
										<ul>
											<li
												class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
												v-for="item in filteredCustomer.slice(
													0,
													7
												)"
												:key="item.id"
												@click="addCustomerToList(item)"
											>
												{{ item.customer_name }}
											</li>
										</ul>
									</div>
								</div>
								<div class="row">
									<div
										class="col-md-6 mb-1 mt-3"
										v-for="customer in selectedCustomers"
										:key="customer.id"
									>
										<div
											class="flex justify-between bg-white border rounded-md shadow-sm p-1"
										>
											<div>
												{{ customer.customer_name }}
											</div>
											<div>
												<span
													class="cursor-pointer"
													@click="
														removeUserFromSelectedList(
															customer
														)
													"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														viewBox="0 0 20 20"
														fill="currentColor"
													>
														<path
															fill-rule="evenodd"
															d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
															clip-rule="evenodd"
														/>
													</svg>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group mt-3">
								<button
									@click="showCategoryModal = false"
									type="button"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span>{{ $t("setCustomer") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
	</div>
</template>

<script>
import categoryTree from "./components/categoryTree";
import { mapGetters } from "vuex";
import ModalContainer from "./../../customComponent/Modal";
import debounce from "lodash.debounce";

export default {
	name: "ArticleCategoryStructure",
	components: { categoryTree, ModalContainer },
	data: function () {
		return {
			addingProductCategory: false,
			statusUpdate: false,
			loading: true,
			updating: false,
			value2: false,
			searchValue: "",
			showCategoryModal: false,
			showCategory: false,
			openCustomersDropdown: false,
			customerSearchName: "",
			selectedCustomers: [],
			filteredCustomer: [],
			openChildList: false,
			loadingCategorySelect: false,
			categoryTreeList: {
				results: [],
				count: 1,
				page: 1,
				total_pages: 1,
			},
			categoryListing: { results: [], count: 1, page: 1, total_pages: 1 },
			cateForm: {
				category: "",
				value: "",
				parent: null,
			},
			subcategoryList: [],
		};
	},
	computed: {
		...mapGetters({
			USER_TOKEN: "auth/USER_TOKEN",
			GET_CUSTOMIZATION: "portal/GET_CUSTOMIZATION",
			GET_COMPANY_SETTING: "portal/GET_COMPANY_SETTING",
			GET_CATEGORY_STRUCTURE: "portal/GET_ARTICLE_CATEGORY_STRUCTURE",
			GET_PRODUCT_CATEGORY: "portal/GET_PRODUCT_CATEGORY",
			GET_LOCALE: "utility/GET_LOCALE_LANG",
			GET_ALL_CUSTOMERS: "portal/GET_ALL_CUSTOMERS",
		}),
	},
	mounted() {
		this.getCategoryStructure();
		this.getCategoryTree();
		this.getSubCategory();
		this.$store.dispatch("portal/getAllEndCustomers");
		this.$store.dispatch("portal/getProductCategory");
	},
	methods: {
		onSearchCategory: debounce(function () {
			if (this.searchValue) {
				this.$store
					.dispatch("portal/getCategoryStructure", {
						data: `?limit=50&company_id=${this.GET_COMPANY_SETTING.id}&search=${this.searchValue}`,
					})
					.then((resp) => {
						this.loading = false;
						resp.data.data.results.map((item) => {
							item.checked = false;
							item.child_node.map((ch) => {
								ch.checked = false;
							});
						});
						this.categoryTreeList = resp.data.data;
					})
					.catch((err) => {
						this.loading = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			} else {
				this.getCategoryTree();
			}
		}, 300),
		openCategory: function () {
			this.showCategoryModal = this.showCategory;
		},
		removeItem: function () {
			this.getCategoryStructure();
		},
		filterCustomers() {
			this.$store
				.dispatch("portal/getCompanyEndCustomers", {
					page:
						this.$services.endpoints.COMPANY_ENDCUSTOMERS +
						`?limit=50&search=${this.customerSearchName}`,
				})
				.then((res) => {
					this.filteredCustomer = res.data.data;
				})
				.catch((err) => {
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		addCustomerToList(customer) {
			// Check if customer exist already
			const filter = this.selectedCustomers.filter(
				(item) => parseInt(item.id) === parseInt(customer.id)
			);
			if (filter.length < 1) {
				this.selectedCustomers.push(customer);
			}
			this.openCustomersDropdown = false;
		},
		removeUserFromSelectedList(customer) {
			const filterRemove = this.selectedCustomers.filter(
				(item) => item.id !== customer.id
			);
			this.selectedCustomers = filterRemove;
		},
		addCategory() {
			if (this.cateForm.category === "" || this.cateForm.value === "") {
				return this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			} else if (
				this.cateForm.category === "child" &&
				!this.cateForm.parent
			) {
				this.$services.helpers.notification(
					this.$t("selectCategoryParent"),
					"error",
					this
				);
			} else {
				this.addingProductCategory = true;
				if (this.cateForm.category === "parent") {
					const payload = {
						name: this.cateForm.value,
						account_number: 0,
						cost_center: 0,
					};
					if (this.selectedCustomers.length > 0) {
						let customers_id = [];
						this.selectedCustomers.map((item) => {
							if (customers_id.includes(item.id) === false) {
								customers_id.push(parseInt(item.id));
							}
						});
						payload["allowed_customers"] = customers_id;
					}
					this.$store
						.dispatch("portal/addProductCategory", payload)
						.then((res) => {
							this.addingProductCategory = false;
							this.$services.helpers.notification(
								this.$t("productCategorySuccess"),
								"success",
								this
							);
							this.cateForm.category = "";
							this.cateForm.value = "";
							this.selectedCustomers = [];
							this.showCategory = false;
							this.$store.dispatch("portal/getProductCategory");
							this.getCategoryStructure();
						})
						.catch((err) => {
							this.addingProductCategory = false;
							if (err.response.status === 401) {
								this.$store.commit(
									"auth/CLEAR_AUTH_USER",
									null
								);
								window.Bus.$emit("sign-out");
							} else {
								if (
									err.response.data.hasOwnProperty(
										"message"
									) &&
									err.response.data.hasOwnProperty(
										"message_swe"
									)
								) {
									if (this.GET_LOCALE_LANG === "se") {
										this.$services.helpers.notification(
											err.response.data.message_swe,
											"error",
											this
										);
									} else {
										this.$services.helpers.notification(
											err.response.data.message,
											"error",
											this
										);
									}
								} else {
									this.$services.helpers.notification(
										err.response.data.message,
										"error",
										this
									);
								}
							}
						});
				} else {
					const payload = {
						name: this.cateForm.value,
						category: parseInt(this.cateForm.parent),
					};
					this.$store
						.dispatch("portal/addChildCategory", payload)
						.then((res) => {
							this.addingProductCategory = false;
							this.$services.helpers.notification(
								this.$t("childCategorySuccess"),
								"success",
								this
							);
							this.cateForm.category = "";
							this.cateForm.value = "";
							let data = res.data.data;
							let indexItem =
								this.GET_CATEGORY_STRUCTURE.results.findIndex(
									(item) => item.id === this.cateForm.parent
								);
							if (indexItem > -1) {
								this.GET_CATEGORY_STRUCTURE.results[
									indexItem
								].child_node = data.child_node;
							}
							this.cateForm.parent = null;

							this.getCategoryTree();
							this.getCategoryStructure();
						})
						.catch((err) => {
							this.addingProductCategory = false;
							if (err.response.status === 401) {
								this.$store.commit(
									"auth/CLEAR_AUTH_USER",
									null
								);
								window.Bus.$emit("sign-out");
							} else {
								if (
									err.response.data.hasOwnProperty(
										"message_swe"
									) &&
									err.response.data.hasOwnProperty("message")
								) {
									if (this.GET_LOCALE === "se") {
										this.$services.helpers.notification(
											err.response.data.message_swe,
											"error",
											this
										);
									} else {
										this.$services.helpers.notification(
											err.response.data.message,
											"error",
											this
										);
									}
								} else if (
									err.response.data.hasOwnProperty("message")
								) {
									this.$services.helpers.notification(
										err.response.data.message,
										"error",
										this
									);
								}
							}
						});
				}
			}
		},
		addSubCategory() {
			if (
				this.cateForm.value === "" ||
				this.cateForm.value === null ||
				this.cateForm.value === undefined
			) {
				return this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
			}
			let categoryList = this.categoryTreeList.results.filter(
				(item) => item.checked === true
			);
			if (categoryList.length < 1) {
				return this.$services.helpers.notification(
					this.$t("emptyNodeSelected"),
					"error",
					this
				);
			}
			this.addingProductCategory = true;
			let categoryTreePayload = [];
			categoryList.map((category) => {
				let nodeDict = {
					category: category.id,
					child_category: [],
				};
				let childList = category.child_node.filter(
					(item) => item.checked === true
				);
				childList.map((item) => {
					nodeDict.child_category.push(item.id);
				});
				categoryTreePayload.push(nodeDict);
			});
			let payload = {
				nodes: categoryTreePayload,
				subcategory: "",
			};

			const subcategory = {
				name: this.cateForm.value,
			};
			let subcategoryIndex = this.subcategoryList.findIndex(
				(item) => item.name === subcategory.name
			);
			if (subcategoryIndex > -1) {
				let sub = this.subcategoryList[subcategoryIndex];
				payload["subcategory"] = [sub.id];
				this.addingProductCategory = true;
				this.createCategoryTree(payload);
			} else {
				this.$store
					.dispatch("portal/addSubCategory", subcategory)
					.then((res) => {
						payload["subcategory"] = [res.data.data.id];
						this.createCategoryTree(payload);
					})
					.catch((err) => {
						this.addingProductCategory = false;
						window.Bus.$emit("output-error", err);
						// if (err.response.status === 401) {
						// 	this.$store.commit('auth/CLEAR_AUTH_USER', null)
						// 	window.Bus.$emit('sign-out')
						// }
					});
			}
		},
		createCategoryTree: function (payload) {
			this.$store
				.dispatch("portal/assignSubCategoryToStructure", {
					data: payload,
				})
				.then((_) => {
					this.$services.helpers.notification(
						this.$t("subcategoryAssignedSuccess"),
						"success",
						this
					);
					this.getSubCategory();
					this.getCategoryStructure();
					this.getCategoryTree();
					this.addingSubCategory = false;
					this.addingProductCategory = false;
					this.cateForm = {
						category: "",
						value: "",
						parent: null,
					};
				})
				.catch((err) => {
					this.addingSubCategory = false;
					this.addingProductCategory = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					} else {
						if (
							err.response.data.hasOwnProperty("message_swe") &&
							err.response.data.hasOwnProperty("message")
						) {
							if (this.GET_LOCALE === "se") {
								this.$services.helpers.notification(
									err.response.data.message_swe,
									"error",
									this
								);
							} else {
								this.$services.helpers.notification(
									err.response.data.message,
									"error",
									this
								);
							}
						} else {
							this.$services.helpers.notification(
								err.response.data.message,
								"error",
								this
							);
						}
					}
				});

			this.getSubCategory();
		},
		hideStatus() {
			window.Bus.$emit("remove-category-update");
			this.statusUpdate = false;
		},
		getCategoryStructure() {
			this.loadingCategorySelect = true;
			this.$store
				.dispatch("portal/getCategoryStructure", {
					data: `?limit=50&company_id=${this.GET_COMPANY_SETTING.id}`,
				})
				.then((resp) => {
					this.loading = false;
					this.categoryListing = resp.data.data;
					this.loadingCategorySelect = false;
				})
				.catch((err) => {
					this.loading = false;
					this.loadingCategorySelect = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		loadMore: function () {
			if (
				this.categoryListing.page !==
					this.categoryListing.total_pages &&
				this.categoryListing.page <= this.categoryListing.total_pages
			) {
				this.loading = true;
				let pageUrl = `?page=${
					this.categoryListing.page + 1
				}&limit=50&company_id=${this.GET_COMPANY_SETTING.id}`;
				this.$store
					.dispatch("portal/getCategoryStructure", { data: pageUrl })
					.then((resp) => {
						this.loading = false;
						this.categoryListing.results =
							this.categoryListing.results.concat(
								resp.data.data.results
							);
						this.categoryListing.page = resp.data.data.page;
						this.categoryListing.count = resp.data.data.count;
						this.categoryListing.total_pages =
							resp.data.data.total_pages;
					})
					.catch((err) => {
						this.loading = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		showDropDown: function () {
			if (this.openChildList === true) {
				this.openChildList = false;
			} else {
				this.openChildList = true;
			}
		},
		getCategoryTree: function () {
			this.$store
				.dispatch("portal/getCategoryStructure", {
					data: `?company_id=${this.GET_COMPANY_SETTING.id}`,
				})
				.then((resp) => {
					// let payload = resp.data.data
					resp.data.data.results.map((item) => {
						item.checked = false;
						item.child_node.map((ch) => {
							ch.checked = false;
						});
					});
					this.categoryTreeList = resp.data.data;
				})
				.catch((err) => {
					this.loading = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		checkBox: function (item) {
			let askNodeIndex = this.categoryTreeList.results.findIndex(
				(box) => item.id === box.id
			);
			if (askNodeIndex > -1) {
				const newArticleArray = [...this.categoryTreeList.results];
				newArticleArray[askNodeIndex] = {
					...newArticleArray[askNodeIndex],
					checked: item.checked,
				};
				let status = item.checked;
				if (status === false) {
					newArticleArray[askNodeIndex].child_node.map((res) => {
						res.checked = false;
					});
				}
				this.categoryTreeList.results = newArticleArray;
			}
		},
		checkChildNodeBox: function (mainID, node) {
			let askNodeIndex = this.categoryTreeList.results.findIndex(
				(box) => mainID === box.id
			);
			if (askNodeIndex > -1) {
				const newArticleArray = [...this.categoryTreeList.results];
				const mainCategory = newArticleArray[askNodeIndex];
				let askChildNodeIndex = mainCategory.child_node.findIndex(
					(item) => item.id === node.id
				);
				const newChildNodeArray = [...mainCategory.child_node];
				if (askChildNodeIndex > -1) {
					newChildNodeArray[askChildNodeIndex] = {
						...newChildNodeArray[askChildNodeIndex],
						checked: node.checked,
					};
					if (node.checked === true) {
						newArticleArray[askNodeIndex] = {
							...newArticleArray[askNodeIndex],
							checked: node.checked,
						};
					}
					if (node.checked === false) {
						let remainChildCheck = newChildNodeArray.filter(
							(item) => item.checked === true
						);
						if (remainChildCheck.length === 0) {
							newArticleArray[askNodeIndex] = {
								...newArticleArray[askNodeIndex],
								checked: false,
							};
						}
					}
					this.categoryTreeList.results = newArticleArray;
				}
			}
		},
		getSubCategory: function () {
			this.$store.dispatch("portal/getSubCategory").then((resp) => {
				this.subcategoryList = resp.data.data;
			});
		},
	},
	created: function () {
		// const customScroll = new SimpleBar(document.getElementById('customScroll'));
	},
};
</script>

<style scoped>
.category-tree-header {
	padding-top: 7px;
	padding-bottom: 7px;
	padding-left: 13px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.el-switch__core:after {
	content: "";
	position: absolute;
	top: 1px;
	left: 1px;
	border-radius: 100%;
	transition: all 0.3s;
	width: 13px !important;
	height: 10px !important;
	background-color: #fff;
}

.el-switch__core {
	height: 14px !important;
	width: 36px;
}

.child-category-icon {
	position: absolute;
	top: 9px;
	right: 10px;
}

.ul-select {
	padding: 10px;
}

.checkbox-select__dropdown {
	z-index: 99;
	border: 1px solid;
	border-radius: 2px;
	top: 36px;
	border-top: 1px solid transparent !important;
}

#customScroll {
	overflow-y: auto;
}
</style>
