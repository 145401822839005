<template>
	<div v-loading="loading" class="flex flex-col p-3 mt-3">
		<div class="flex bg-white p-3 mb-4">
			<div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
				<div class="col-span-1">
					<p class="customer-name">{{ customer.customer_name }}</p>
				</div>
				<div class="col-span-1">
					<button v-on:click="addLocation"
							class="w-1/4 float-right text-white px-3 bg-green-500 py-2 rounded-md whitespace-nowrap md:mt-7">
						<span class="text-xs">
                                        {{ $t('add') }}
                                    </span>
					</button>
				</div>
			</div>
		</div>
		<div class="flex bg-white p-3 mb-4">
			<div class="drawer-category-management table-responsive rounded-lg bg-white border">
				<table style="width: 100%;" id="exampledestroy" class="table table-hover table-bordered">
					<thead>
					<tr>
						<th> {{ $t('locationName') }}</th>
						<th>{{ $t('streetAddress') }}</th>
						<th>{{ $t('zipCode') }}</th>
						<th>{{ $t('country') }}</th>
						<th>{{ $t('city') }}</th>
						<th>{{ $t('phoneNumber') }}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(item, i) in locationsList.results" :key="i">
						<td>{{ item.location_name }}</td>
						<td>{{ item.street_address }}</td>
						<td>{{ item.zip_code }}</td>
						<td>{{ getCountry(item.country) }}</td>
						<td>{{ item.city }}</td>
						<td>{{ item.phone_number }}</td>
						<td>
							<div class="flex justify-center space-x-2">
                                                <span v-on:click="updateLocation(item)"
													  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                                                    <img src="./../../assets/images/icons/edit.png" alt="">
                                                </span>
								<span v-on:click="deleteLocation(item.id)"
									  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                                                    <img src="./../../assets/images/icons/delete.png" alt="">
                                                </span>
							</div>
						</td>
					</tr>
					</tbody>
				</table>
			</div>

		</div>

	</div>
</template>
<script>
import {ALL_COUNTRY_NAME_CODE} from "@/services/constant";

export default {
	name: "CustomerDeliveryAddress",
	props: {
		customer_id: {
			required: true
		},
		customer: {
			type: Object
		}
	},
	data: function () {
		return {
			loading: false,
			locationsList: {results: []}
		}
	},
	mounted() {
		window.Bus.$on('reload-customer-delivery-address', e => {
			this.locationsList.results.push(e)
		})
		this.getLocation()

	},
	methods: {
		updateLocation(data) {
			window.Bus.$emit('open-edit-customer-delivery', data)
		},
		deleteLocation: function (id) {
			this.$store.dispatch('portal/deleteCustomerDeliveryAddress', {id: id}).then(res => {
				let index = this.locationsList.results.findIndex(item => item.id === parseInt(id))
				if (index >= -1) {
					this.locationsList.results.splice(index, 1)
				}
			})
		},
		addLocation: function () {
			window.Bus.$emit('open-add-customer-delivery', this.customer_id)
		},
		getLocation() {
			this.loading = true
			this.$store.dispatch('portal/getCustomerDeliveryAddress', {URL: `?customer__id=${this.customer_id}&limit=1000000`}).then(resp => {
				this.loading = false
				this.locationsList = resp.data.data
			}).catch(err => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getCountry: function (symbol) {
			if (ALL_COUNTRY_NAME_CODE.hasOwnProperty(symbol)) {
				return ALL_COUNTRY_NAME_CODE[symbol]
			} else {
				return ''
			}
		}
	}
}
</script>

<style scoped>
.customer-name {
	position: relative;
	top: 25px;
	font-weight: 600;
}
</style>
