<template>
	<div class="app-container app-theme-white body-tabs-shadow">
		<div class="app-container dm-sans-font">
			<div class="d-flex">
				<div class="text-center mt-4">
					<img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/logo/web.png" alt="logo">
				</div>
			</div>
			<div class="flex justify-center mt-40">
				<div class="md:mt-16">
					<div class="no-gutters row  flex justify-center mt-10 mb-5" v-if="loading">
						<svg class="animate-spin h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
							 viewBox="0 0 24 24">
							<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
									stroke-width="4"></circle>
							<path class="opacity-75" fill="currentColor"
								  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
						</svg>
					</div>
					<br>
					<div class="flex flex-col justify-center mt-3" v-if="onBoardError">
						<span class="text-red-500 font-semibold text-xl text-center mb-5">{{ errorMessage }}</span> <br>
						<button v-if="fortnoxError" style="margin: auto;" @click="backToFortnox"
								class="bg-green-500 px-3 py-2 rounded shadow-sm text-xl md:w-80">
							<div class="flex justify-center items-center m-auto">
								<span class="text-white">Back to dashboard</span>
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "ConnectMicrosoft",
	data() {
		return {
			current: new Date().getFullYear(),
			baseURL: window.location.origin,
			loading: true,
			finalDisplay: false,
			showFirstPage: false,
			companyName: '',
			errorMessage: '',
			checking: true,
			success: false,
			onBoardError: false,
			fortnoxError: false,
			processing: false,
			resending: false
		}
	},
	mounted() {
		const {code, state} = this.$route.query
		if (code && state) {
			this.loading = true
			this.initializeToken(code, state)
		} else {
			this.$router.replace({name: 'welcome'})
		}
	},
	computed: {
		...mapGetters({
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
	methods: {
		getPermissionUrl() {
			this.processing = true
			this.$store.dispatch('auth/getPermissionUrl')
				.then(res => {
					this.processing = false
					window.open(res.data.url, '_self')
				})
				.catch(_ => {
					this.processing = false
				})
		},
		backToFortnox() {
			this.$router.replace({name: 'dashboard'})
		},
		initializeToken(code, state) {
			this.loading = true
			const payload = {
				code: code,
				state: state
			}
			this.$store.dispatch('auth/getMicroSoftToken', payload)
				.then(response => {
					window.location.href = response.data.redirect_url
				})
				.catch(err => {
					this.loading = false
					this.onBoardError = true
					if (err.response.status === 400) {
						if (err.response.data.hasOwnProperty('message_swe') && err.response.data.hasOwnProperty('message')) {
							if (this.GET_LOCALE === 'se') {
								this.errorMessage = err.response.data.message_swe
							} else {
								this.errorMessage = err.response.data.message
							}
						} else {
							this.errorMessage = err.response.data.message
						}
						if (err.response.data.hasOwnProperty('redirect_url')) {
							setTimeout(function () {
								window.location.href = err.response.data.redirect_url
							}, 800)
						}
					}
				})
		},
	}
}
</script>

<style scoped>

</style>
