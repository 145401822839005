<template>
    <div class="app-container app-theme-white body-tabs-shadow">
        <div class="app-container dm-sans-font">
            <div class="d-flex">
                <div class="text-center mt-4">
                    <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/logo/web.png" alt="logo">
                </div>
            </div>
            <div class="flex justify-center mt-10">
                <div class="md:mt-16">
                    <div class="no-gutters row flex justify-center mt-10 mb-5">
                         <div class="theme-main-size font-semibold text-lg md:text-2xl text-blue-900 md:tracking-wide whitespac-nowrap ">{{ $t('authenticationInProcess') }}</div>
                    </div> <br>
                    <div class="no-gutters row flex justify-center mt-10 mb-5" v-if="loading">
                        <svg class="animate-spin h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div> <br>
                    <div class="flex flex-col justify-center mt-3" v-if="error">
                        <span class="text-red-500 font-semibold text-xl text-center mb-5">{{ errorMessage }}</span> <br>
                        <button  style="margin: auto;" class="bg-green-500  px-3 py-2 rounded shadow-sm text-xl md:w-80">
                            <div class="flex justify-center items-center m-auto">
                                <span class="text-white">Go to login</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localeChanged } from 'vee-validate'

export default {
    name: 'CustomerSupport',
    data:function () {
       return {
           loading: true,
           processing: false,
           error: false,
           errorMessage: '',
       }
    },
    mounted () {
      const customerSupport = this.$route.query.support_id
      const companyId = this.$route.query.company_id
      this.error = false
      this.loading = true
      this.errorMessage = ''
      if (customerSupport && companyId) {
          if (customerSupport !== '' && companyId !== '') {
         this.$store.dispatch('auth/loginCustomerSupport', { support_id : customerSupport, company_id : companyId })
                    .then(res => {
                            if (res.data.user_group[0].name === 'ShopOwner' || res.data.user_group[0].name === 'ShopOwnerEmployee') {
                                if (!res.data.subscription) {
                                    this.$router.replace({ name : 'onboardsubcription', params : { id : res.data.company_id } })
                                } else {
                                    this.$services.helpers.notification(this.$t('loginSuccess'), 'success', this)
                                    this.setRandomColor()
                                    this.$store.commit('auth/UPDATE_USER_TOKEN', res.data)
                                    if (window.localStorage.getItem('firstT')) {
                                        setTimeout(() => {
                                            window.localStorage.removeItem('firstT')
                                            this.$router.replace({ name : 'settings' })
                                        }, 2000)
                                    } else {
                                        setTimeout(() => {
                                            this.$router.replace({ name : 'dashboard' })
                                        }, 2000)
                                    }
                                }
                            } else { // if (res.data.user_group[0].name === 'EndUserCustomer')
                                this.$services.helpers.notification(this.$t('loginSuccess'), 'success', this)
                                this.$store.commit('auth/UPDATE_CUS_TOKEN', res.data)
                                setTimeout(() => {
                                    this.$router.replace({ name : 'customerdash' })
                                    // window.location.href = this.baseURL + '/customer/'
                                }, 2000)
                            }
                    })
                    .catch(() => {
                        this.loading = false
                        this.error = true
                        this.errorMessage = this.$t('loginError')
                    })
      } else {
           this.loading = false
           this.error = true
           this.errorMessage = 'Requested action could not be perform'
        }
      } else {
           this.loading = false
           this.error = true
           this.errorMessage = 'Requested action could not be perform'
      }
   },
    methods:{
           changeLocale (locale) {
                i18n.locale = locale
                this.$store.commit('utility/CHANGE_LOCALE', locale)
                localeChanged()
            },
           setRandomColor () {
                const color = ['#512DA8', '#7E3469', '#EE4D81', '#344258', '#546295', '#1E2C41']
                const choose = color[Math.floor(Math.random() * color.length)]
                if (!window.localStorage.getItem('randomc')) {
                    window.localStorage.setItem('randomc', choose)
                }
                return false
            }
    }
}
</script>

<style scoped>

</style>
