import bus from '../../bus'
import {ALL_COUNTRY_NAME_CODE} from "@/services/constant";

export default {
	notification(message, status, _) {
		window.Bus.$emit('on-notify', {'message': message, 'status': status})
		// if (status === 'success') {
		// 	// bus.$emit('notify-me', {
		// 	// 	permanent: false,
		// 	// 	timeout: 2000,
		// 	// 	status: 'is-success shadow-lg rounded',
		// 	// 	maxNotifications: 1,
		// 	// 	data: {
		// 	// 		title: 'Success',
		// 	// 		text: message
		// 	// 	}
		// 	// })
		// 	this.$notify({
		// 		group: 'b2b',
		// 		title: 'Success',
		// 		text: message,
		// 		type: 'success',
		// 		duration: 4000,
		// 	});
		// } else if (status === 'error') {
		// 	// bus.$emit('notify-me', {
		// 	// 	permanent: false,
		// 	// 	timeout: 2000,
		// 	// 	status: 'is-danger shadow-lg rounded',
		// 	// 	maxNotifications: 1,
		// 	// 	data: {
		// 	// 		title: 'Error',
		// 	// 		text: message
		// 	// 	}
		// 	// })
		// 	this.$notify({
		// 		group: 'b2b',
		// 		title: 'Error',
		// 		text: message,
		// 		type: 'error',
		// 		duration: 4000,
		// 	});
		// } else {
		// 	this.$notify({
		// 		group: 'b2b',
		// 		title: 'Processing',
		// 		text: message,
		// 		type: 'processing',
		// 		duration: 4000,
		// 	});
		// 	// bus.$emit('notify-me', {
		// 	// 	timeout: 2000,
		// 	// 	permanent: false,
		// 	// 	status: 'is-info shadow-lg rounded',
		// 	// 	data: {
		// 	// 		title: 'Processing',
		// 	// 		text: message
		// 	// 	}
		// 	// })
		// }
	},
	// oldNotification (message, status, self) {
	//     if (status === 'success') {
	//         self.$notify({ group: 'top', title: 'Success', text: `${message}` }, 3900)
	//     } else if (status === 'error') {
	//         self.$notify({ group: 'top', title: 'Error', text: `${message}` }, 3900)
	//     } else if (status === 'progress') {
	//         self.$notify({ group: 'top', title: 'Processing', text: `${message}` }, 3900)
	//     } else {
	//         self.$notify({ group: 'top', title: 'Warning', text: `${message}` }, 3900)
	//     }
	// },
	isLetter(str) {
		return str.length === 1 && str.match(/[a-z]/i);
	},
	animateValue: (obj, start, end, duration) => {
		if (document.getElementById(obj) !== null) {
			let startTimestamp = null
			const step = (timestamp) => {
				if (!startTimestamp) startTimestamp = timestamp
				const progress = Math.min((timestamp - startTimestamp) / duration, 1)
				if (document.getElementById(obj)) {
					document.getElementById(obj).innerHTML = Math.floor(progress * (end - start) + start)
					if (progress < 1) {
						window.requestAnimationFrame(step)
					}
				}
			}
			window.requestAnimationFrame(step)
		}
	},
	validateEmailAddress(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(email)
	},
	validateCompanyNumber(number) {
		if (!number.includes('-')) {
			return true
		}
		if (number.split('-')[0].length === 6 && number.split('-')[1].length === 4) {
			return false
		}
		return true
	},
	getCountryList() {
		let countries = []
		for (const [key, value] of Object.entries(ALL_COUNTRY_NAME_CODE)) {
			countries.push({
				key: key,
				name: value
			})
		}
		return countries
	},
	cleanData(name) {
		if (['nan'].includes(name)) {
			return ''
		} else {
			return name
		}
	},
	formatPrice(amount) {
		if (amount === '' || amount === null || amount === undefined) {
			return 0
		}
		if (parseFloat(amount) <= 999) {
			return amount
		}

		return parseFloat(amount).toLocaleString().replaceAll(',', ' ')
	}

}
