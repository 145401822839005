import moment from 'moment'
import endpoints from './endpoints'
import helpers from './helpers'


moment.locale('en')

export default {
    install (Vue, opts) {
        Vue.prototype.$services = {
            endpoints,
            helpers,
            moment
        }
    }
}
