const BASKET = 'cart_item'
const LOCALE = 'xlocale'
const CUSTOMIZATION = '__bCustomization__'
const COMPANY_WHITELABEL_CUSTOMIZATION_SETTING = '__company__whitelabel_customization__setting__'
const COMPANY_ID = '__b2b_company_id__'
const CURRENT_SELLER_COMPANY_ID = '__b2b_current_seller_company_id__'
const COMPANY_INSTANCE = '__b2b_company__'
const BUYER_ID = '__b2b_buyer_id__'
const USER_SETTINGS = '__b2b_user_settings__'
const USER_SETTINGS_HIDE_FIELD = '__b2b_user_settings_hide_field__'
const CUSTOMER_USER_ID = '__b2b_customer_user_id__'
const CUSTOMER_CURRENT_ACTIVE_COMPANY = '__b2b__current__company'
export const saveCartItem = (data) => {
	window.localStorage.setItem(BASKET, JSON.stringify(data))
}
export const saveUserSetting = (data) => {
	window.localStorage.setItem(USER_SETTINGS, JSON.stringify(data))
}

export const getUserSetting = () => {
	return JSON.parse(window.localStorage.getItem(USER_SETTINGS))
}
export const saveSellerSetting = (data) => {
	window.localStorage.setItem(CURRENT_SELLER_COMPANY_ID, JSON.stringify(data))
}
export const removeSellerSetting = () => {
	window.localStorage.removeItem(CURRENT_SELLER_COMPANY_ID)
}
export const getSellerSetting = () => {
	return JSON.parse(window.localStorage.getItem(CURRENT_SELLER_COMPANY_ID))
}

export const saveCompanyInstance = (data) => {
	window.localStorage.setItem(COMPANY_INSTANCE, JSON.stringify(data))
}
export const getCompanyInstance = () => {
	return JSON.parse(window.localStorage.getItem(COMPANY_INSTANCE))
}
export const saveCompanyCustomizationSetting = (data) => {
	window.localStorage.setItem(COMPANY_WHITELABEL_CUSTOMIZATION_SETTING, JSON.stringify(data))
}

export const getCompanyCustomizationInstance = () => {
	return JSON.parse(window.localStorage.getItem(COMPANY_WHITELABEL_CUSTOMIZATION_SETTING))
}

export const saveUserSettingHideFieldStatus = (data) => {
	window.localStorage.setItem(USER_SETTINGS_HIDE_FIELD, JSON.stringify(data))
}
export const getUserSettingHideFieldStatus = () => {
	return JSON.parse(window.localStorage.getItem(USER_SETTINGS_HIDE_FIELD))
}

export const getCartItem = () => {
	return JSON.parse(window.localStorage.getItem(BASKET))
}

export const clearCartItem = () => {
	window.localStorage.setItem(BASKET, JSON.stringify([]))
}

export const saveLocale = (payload) => {
	window.localStorage.setItem(LOCALE, payload)
}
export const saveCompany = (payload) => {
	window.localStorage.setItem(COMPANY_ID, payload)
}
export const saveBuyer = (payload) => {
	window.localStorage.setItem(BUYER_ID, JSON.stringify(payload))
}
export const getCompanyId = () => {
	return window.localStorage.getItem(COMPANY_ID)
}
export const removeLocalCompany = () => {
	try {
		window.localStorage.removeItem(COMPANY_ID)
	} catch (e) {
	}
}
export const getBuyerId = () => {
	return JSON.parse(window.localStorage.getItem(BUYER_ID))
}

export const removeLocalBuyer = () => {
	try {
		window.localStorage.removeItem(BUYER_ID)
	} catch (e) {
	}

}

export const getCustomization = () => {
	return JSON.parse(window.localStorage.getItem(CUSTOMIZATION))
}

export const saveCustomization = data => {
	window.localStorage.setItem(CUSTOMIZATION, JSON.stringify(data))
}
export const getLocale = () => {
	return window.localStorage.getItem(LOCALE)
}

export const CalculateGrossMargin = (cost, sales, defaultVat) => {
	const costPrice = cost
	const salesPriceExVat = sales

	const grossProfit = salesPriceExVat - costPrice // Get gross profit

	const profitMargin = grossProfit / salesPriceExVat
	const marginPercentage = parseFloat(profitMargin * 100).toFixed(2)
	const val = parseFloat(marginPercentage) > -1 ? marginPercentage : 0
	return {'marginPercentage': val}
}

export const CalculatePriceIncVat = (vat, price, qty) => {
	const vatPercent = vat
	const salesPriceExVat = price
	const articleQty = qty
	const salesIncVat = salesPriceExVat * (1 + parseFloat(vatPercent / 100))
	return parseFloat(salesIncVat * articleQty)
}

export const CalculateSalesPriceExVat = (cost, margin, defaultVat) => {
	const costPrice = cost
	const grossMargin = 1 - parseFloat(margin / 100)
	const salesPriceExVat = costPrice / grossMargin

	return {salesPriceExVat}
}

export const storeTourGuide = data => {
	return window.localStorage.setItem('__b2bShopOwnerTourGuide__', JSON.stringify(data))
}

export const getShopOwnerTourGuide = () => {
	return JSON.parse(window.localStorage.getItem('__b2bShopOwnerTourGuide__'))
}
export const saveCompanyInfo = data => {
	if (data.hasOwnProperty('id')) {
		window.localStorage.setItem('__b2b__company__id', data.id)
	} else {
		window.localStorage.setItem('__b2b__company__id', '')
	}
	return window.localStorage.setItem(CUSTOMER_CURRENT_ACTIVE_COMPANY, JSON.stringify(data))
}
export const storeBuyerTourGuide = data => {
	return window.localStorage.setItem('__b2bBuyerTourGuide__', JSON.stringify(data))
}
export const getCurrentCompany = () => {
	return JSON.parse(window.localStorage.getItem(CUSTOMER_CURRENT_ACTIVE_COMPANY))
}
export const getCurrentCompanyID = () => {
	return window.localStorage.getItem('__b2b__company__id')
}
export const getBuyerTourGuide = () => {
	return JSON.parse(window.localStorage.getItem('__b2bBuyerTourGuide__'))
}
export const saveUserId = (userID) => {
	window.localStorage.setItem(CUSTOMER_USER_ID, userID)
}
export const getUserId = () => {
	return window.localStorage.getItem(CUSTOMER_USER_ID)
}
export const categoryFiltering = (payload) => {
	let filterItem = []
	payload.item.map(catItem => {
		filterItem = Object.values(catItem)
	})
	return !!(filterItem.includes(payload.value));
}
export default {
	saveCartItem,
	getCartItem,
	clearCartItem,
	saveLocale,
	getLocale,
	CalculateGrossMargin,
	CalculatePriceIncVat,
	CalculateSalesPriceExVat,
	getCustomization,
	saveCustomization,
	getShopOwnerTourGuide,
	storeTourGuide,
	storeBuyerTourGuide,
	getBuyerTourGuide,
	getCurrentCompanyID,
	getCurrentCompany,
	saveCompanyInfo,
	saveCompany,
	getCompanyId,
	saveBuyer,
	getBuyerId,
	removeLocalCompany,
	removeLocalBuyer,
	getUserSetting,
	saveUserSetting,
	getUserSettingHideFieldStatus,
	saveUserSettingHideFieldStatus,
	saveSellerSetting, getSellerSetting, getUserId, saveUserId
}
