<template>
	<form v-on:submit.prevent="createUnit">
		<div class="form-group">
			<label
				class="col-form-label font-bold whitespace-nowrap text-xs">{{
					$t('title')
				}}</label>
			<div>
				<input v-model="form.title" type="text"
					   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
			</div>
		</div>
		<div class="form-group">
			<label
				class="col-form-label font-bold whitespace-nowrap text-xs">{{
					$t('unit')
				}}</label>
			<div>
				<input v-model="form.unit" type="text"
					   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
				<!--				<select v-model="form.unit"-->
				<!--						class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">-->
				<!--					<option v-for="(unit,i) in unitTypeList" :key="i" :value="unit.key">{{ unit.title }}</option>-->
				<!--				</select>-->
			</div>
		</div>
		<div class="form-group flex space-x-5 mt-3">
			<button type="submit" :disabled="adding"
					:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
					:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-blue-900'"
					class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full">
                                    <span v-if="adding" class="flex align-items-center space-x-3">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('adding') }}
                                    </span>
				<span v-else>{{ $t('addBtn') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
import {mapGetters} from "vuex"
import {UNIT_TYPES} from "../../../../services/constant"

export default {
	name: "addUnitType",
	components: {},
	data: function () {
		return {
			form: {
				title: '',
				unit: ''
			},
			adding: false,
			unitTypeList: []
		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
		})
	},
	methods: {
		createUnit: function () {
			this.adding = true
			this.$store.dispatch('portal/createUnit', {data: this.form}).then(resp => {
				this.adding = false
				this.form = {
					title: '',
					unit: ''
				}
				this.$services.helpers.notification(this.$t('unitCreated'), 'success', this)
				window.Bus.$emit('reload-unit')
			}).catch(err => {
				this.adding = false
				window.Bus.$emit('output-error', err)
			})
		}
	},
	mounted() {
		this.unitTypeList = UNIT_TYPES
	}
}
</script>

<style scoped>

</style>
