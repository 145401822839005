var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-header header-shadow roboto-font"},[_c('div',{staticClass:"app-header__logo nav-header-side",style:(_vm.GET_CUSTOMIZATION && _vm.GET_CUSTOMIZATION.menu
				? 'background:' + _vm.GET_CUSTOMIZATION.menu
				: 'background: #181C4C')},[_c('div',{staticClass:"logo-sr"}),_c('div',{staticClass:"header__pane ml-auto"})]),_c('div',{staticClass:"app-header__mobile-menu"},[_c('div',[_c('button',{staticClass:"hamburger hamburger--elastic mobile-toggle-nav",attrs:{"type":"button","id":"hamburger-btn"},on:{"click":_vm.toggleSidebar}},[_vm._m(0)])])]),_c('div',{staticClass:"app-header__menu"},[_c('span',[_c('button',{staticClass:"btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav",attrs:{"type":"button","id":"top-wrapper"},on:{"click":_vm.toggleNavTop}},[_vm._m(1)])])]),_c('div',{staticClass:"app-header__content",attrs:{"id":"div-to-open"}},[_vm._m(2),_c('div',{staticClass:"app-header-right"},[_c('div',{staticClass:"header-dots"},[(_vm.notifyOpen)?_c('div',{staticClass:"absolute bg-red-30 inset-0 z-0",on:{"click":function($event){_vm.notifyOpen = false}}}):_vm._e(),_c('div',{staticClass:"dropdown md:w-16"},[_c('button',{staticClass:"p-0 btn btn-link no-deco",attrs:{"data-cy":"notify-btn","type":"button"},on:{"click":_vm.openNotifyDiv}},[_c('div',{staticClass:"flex align-items-center space-x-4"},[_c('span',[_c('span',{staticClass:"icon-wrapper icon-wrapper-alt rounded-circle"},[_c('span',{staticClass:"icon-wrapper-bg border-2 border-gray-900"}),_c('img',{attrs:{"src":require("./../assets/images/icons/notification.png"),"alt":""}}),_c('span',{staticClass:"flex justify-center align-items-center text-xs icon text-white bg-yellow-500 rounded-full h-4 w-8 p-2 absolute ml-4 -mt-3 z-50",staticStyle:{"font-size":"9.5px !important"}},[_vm._v(_vm._s(_vm.unReadNotificationCount))])])])])]),(_vm.notifyOpen)?_c('div',{staticClass:"w-60 width-div-mobile fixed md:absolute z-50 shadow bg-white border rounded mt-10 p-3 md:p-5 right-md",attrs:{"data-cy":"notify-opener"}},[_c('div',[_c('div',{staticClass:"flex flex-col"},[_vm._m(3),_c('div',{staticClass:"text-center menu-header-content text-dark flex justify-content-between"},[_c('div',[(
												_vm.unReadNotificationCount ===
												0
											)?_c('h5',{staticClass:"text-sm menu-header-title"},[_vm._v(" "+_vm._s(_vm.$t("noNotify"))+" ")]):_c('h5',{staticClass:"text-sm menu-header-title"},[_vm._v(" "+_vm._s(_vm.$t("notify"))+" ")])]),_c('div',{staticClass:"flex"},[(
												_vm.unReadNotificationCount > 0
											)?_c('span',{staticClass:"cursor-pointer font-semibold mr-1",on:{"click":_vm.markAllNotification}},[_vm._v(_vm._s(_vm.$t("markAll")))]):_vm._e(),_c('span',{staticClass:"cursor-pointer font-semibold",on:{"click":_vm.openNotificationUrl}},[_vm._v(_vm._s(_vm.$t("viewAll")))])])])]),(
									_vm.GET_NOTIFICATION &&
									_vm.unReadNotificationCount > 0
								)?_c('div',{staticClass:"mt-3 overflow-y-scroll",class:_vm.GET_NOTIFICATION.data.length > 3
										? 'notify-height'
										: ''},_vm._l((_vm.GET_NOTIFICATION.data),function(notify,i){return _c('div',{key:i},[_c('NotificationHandler',{attrs:{"notify":notify}})],1)}),0):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"dropdown-side-top dropdown md:w-32"},[_c('locale-component')],1)]),_c('div',{staticClass:"header-btn-lg pr-0"},[_c('div',{staticClass:"widget-content p-0"},[_c('div',{staticClass:"widget-content-wrapper"},[_c('div',{staticClass:"widget-content-left"},[_c('div',{staticClass:"btn-group"},[_c('a',{staticClass:"p-0 btn d-flex align-items-center",attrs:{"data-cy":"user-company","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[(_vm.loadingAuth)?_c('p',{staticClass:"h-10 w-10 bg-gray-300 animate-pulse rounded-full"}):_vm._e(),(
											!_vm.loadingAuth &&
											_vm.GET_COMPANY_USER.company_logo ===
												null
										)?_c('div',{staticClass:"flex justify-center align-items-center no-profile-img",style:('background:' + _vm.rcolor)},[_c('div',[(
													_vm.GET_COMPANY_USER.name !==
													undefined
												)?_c('span',{staticClass:"font-black"},[_vm._v(" "+_vm._s(_vm._f("initialNames")(_vm.GET_COMPANY_USER.name))+" ")]):_vm._e()])]):_vm._e(),(
											!_vm.loadingCompany &&
											_vm.GET_COMPANY_USER.company_logo
										)?_c('img',{staticClass:"rounded-circle mr-1",attrs:{"src":_vm.GET_COMPANY_USER.company_logo,"alt":"b2bportal | preview","width":"42"}}):_vm._e(),(_vm.loadingCompany)?_c('p',{staticClass:"bg-gray-300 w-40 h-4 rounded ml-2 animate-pulse"}):_c('div',{staticClass:"header-user-name ml-2 hidden md:block link-theme-color font-semibold"},[(
												_vm.GET_COMPANY_USER.name !==
												undefined
											)?_c('span',[_vm._v(_vm._s(_vm._f("capitalizeEachString")(_vm.GET_COMPANY_USER.name)))]):_vm._e()]),_c('i',{staticClass:"icon ion-ios-arrow-down ml-2 opacity-8"})]),_c('div',{staticClass:"rm-pointers tweak-style dropdown-menu-lg dropdown-menu dropdown-menu-right",attrs:{"data-cy":"company-panel","tabindex":"-1","role":"menu","aria-hidden":"true"}},[_c('div',{staticClass:"scroll-area-xs override-height"},[_c('div',{staticClass:"scrollbar-container ps"},[_c('ul',{staticClass:"nav flex-column"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"text-sm link-theme-color nav-link",attrs:{"href":"javascript:void(0);"},on:{"click":_vm.logoutUser}},[_c('span',{staticClass:"text-xl icon-color mr-2 mt-1 mb-1 iconly-brokenLogout"}),_vm._v(" "+_vm._s(_vm.$t("logout"))+" ")])])])])]),_vm._m(4)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"hamburger-box"},[_c('span',{staticClass:"hamburger-inner"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"btn-icon-wrapper"},[_c('i',{staticClass:"fa fa-ellipsis-v fa-w-6"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-header-left"},[_c('ul',{staticClass:"header-megamenu nav"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"w-36"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav flex-column"},[_c('li',{staticClass:"nav-item-divider nav-item"}),_c('li',{staticClass:"nav-item-btn text-center nav-item"},[_vm._v(" Version 1.2.0 ")])])
}]

export { render, staticRenderFns }