<template>
	<span @mouseover="openDelete = true" @mouseleave="openDelete = false" class="position-relative">
	<span @click="toggleName" v-if="!updateName">{{ item.name }}</span>
            <input :value="item.name" @keypress.enter="updateNameData($event)"
				   @change="updateNameData($event)" class="focus:outline-none z-50 relative"
				   v-else/>
		<i class="fa fa-times text-danger delete-icon cursor-pointer" v-if="openDelete === true"
		   v-on:click="deleteItem"></i>
	</span>
</template>

<script>
export default {
	name: "SubCategoryComponent",
	props: {
		subcategory: {
			type: Object
		},
		childCategory: {
			type: Number
		}
	},
	data: function () {
		return {
			item: {},
			updateName: false,
			openDelete: false
		}
	},
	mounted() {
		this.item = this.subcategory
	},
	methods: {
		updateNameData: function (e) {
			if (e.target.value !== this.item.name) {
				this.$store.dispatch('portal/updateSubCategory', {name: e.target.value, id: this.item.id}).then(_ => {
					this.item.name = e.target.value
					window.Bus.$emit('update-child-sub-category', {name: e.target.value, id: this.item.id})
				}).catch(err => {

				})
			}
			this.updateName = false
			this.openDelete = false
		},
		toggleName: function () {
			this.updateName = !this.updateName
		},
		deleteItem: function () {
			this.$confirm(`${this.$t('removeSubCategory')} ${this.item.name}?`, `${this.$t('warning')}`, {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('cancel'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true
						instance.confirmButtonText = 'Loading...'
						this.$store.dispatch('portal/deleteChildSubCategoryStructure', {
							'sub_category': [this.item.id],
							'child_category': [this.childCategory]
						}).then(_ => {
							instance.confirmButtonLoading = false
							done()
							window.Bus.$emit('remove-child-sub-category', {id: this.item.id, child_category: this.childCategory})
						}).catch(error => {
							done()
							instance.confirmButtonLoading = false
							if (error.response.status === 400 || error.response.status === 500) {
								this.$services.helpers.notification(this.$t('serverError'), 'error', this)
							}
						})
					} else {
						done()
						instance.confirmButtonLoading = false
					}
				}
			}).then(() => {
				window.Bus.$emit('remove-faq', {id: this.data.id})
			})
		}
	}
}
</script>

<style scoped>
.delete-icon {
	font-size: 10px;
	position: absolute;
	top: -6px;
	right: -5px;
}
</style>
