import { render, staticRenderFns } from "./orderRowForm.vue?vue&type=template&id=ad48b450&scoped=true&"
import script from "./orderRowForm.vue?vue&type=script&lang=js&"
export * from "./orderRowForm.vue?vue&type=script&lang=js&"
import style0 from "./orderRowForm.vue?vue&type=style&index=0&id=ad48b450&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad48b450",
  null
  
)

export default component.exports