import Login from '../../views/auth/Login.vue'
import Connect from '../../views/entry-point/Connect.vue'
import ConnectMicroSoft from '../../views/entry-point/ConnectMicrosoft'
import Proceed from '../../views/entry-point/Proceed.vue'
import Main from '../../views/portal/Main.vue'
import CustomerMain from '../../views/customer/Main.vue'
import CustomerSupport from '../../views/entry-point/CustomerSupport'
import AdministratorMain from '../../views/administrator/Main.vue'

const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/connect',
		name: 'connect',
		component: Connect
	},
	{
		path: '/connect/oauth365',
		name: 'ms-connect',
		component: ConnectMicroSoft
	},
	{
		path: '/onboarding',
		name: 'welcome',
		// component: Entry,
		component: () => import(/* webpackChunkName: "entry-chunck" */ '../../views/entry-point/Entry')
	},
	{
		path: '/signup',
		name: 'seller-signup',
		component: () => import(/* webpackChunkName: "entry-chunck" */ '../../views/auth/SellerSignup')
	},
	{
		path: '/proceed',
		name: 'confirm',
		component: Proceed
	},
	{
		path: '/verify-email',
		name: 'verify-email',
		component: () => import(/* webpackChunkName: "email-verify-chunck" */ '../../views/entry-point/EmailVerification')
	},
	{
		path: '/register-shopowner',
		name: 'register',
		component: () => import(/* webpackChunkName: "shop-signup-chunck" */ '../../views/auth/ShopSignUp.vue')
	},
	{
		path: '/customer_support',
		name: 'customer_support',
		component: CustomerSupport
	},
	{
		path: '/privacy-policy',
		name: 'privacy_policy',
		component: () => import(/* webpackChunkName: "privacy-policy-chunck" */ '../../views/auth/PrivacyPolicy')
	},
	{
		path: '/buyer-signup',
		name: 'buyerregister',
		component: () => import(/* webpackChunkName: "buyer-chunck" */ '../../views/auth/BuyerSignUp.vue')
	},
	{
		path: '/select-subscription/',
		name: 'onboardsubcription',
		component: () => import(/* webpackChunkName: "subcription-chunck" */ '../../views/auth/Subscription.vue')
	},
	{
		path: '/forget-password',
		name: 'forget',
		component: () => import(/* webpackChunkName: "forget-chunck" */ '../../views/auth/forget-password/Forget.vue')
	},
	{
		path: '/invite/',
		name: 'invite',
		component: () => import(/* webpackChunkName: "account-invite-chunck" */ '../../views/auth/AccountInvite.vue')
	},
	{
		path: '/unavailable/',
		name: 'notfound',
		component: () => import(/* webpackChunkName: "notfound-chunck" */ '../../views/NotFound.vue')
	},
	{
		path: '/reset-password',
		name: 'reset',
		component: () => import(/* webpackChunkName: "reset-chunck" */ '../../views/auth/ResetPassword.vue')
	},
	{
		path: '/customer',
		// name: 'customeraccount',
		meta: {
			requireAuth: true,
			title: 'Customer Dashboard | B2B Portal',
		},
		component: CustomerMain,
		children: [
			{
				path: '',
				name: 'customerdash',
				component: () => import(/* webpackChunkName: "dashboard-chunck" */ '../../views/customer/Dashboard.vue')
			},
			{
				path: 'products/:company_id',
				name: 'productarticles',
				component: () => import(/* webpackChunkName: "article-chunck" */ '../../views/customer/Article.vue')
			},
			{
				path: 'product/:company_id/:product_id',
				name: 'single-product',
				component: () => import(/* webpackChunkName: "product-view-chunck" */ '../../views/customer/ProductView.vue')
			},
			{
				path: 'confirmation/:company_id',
				name: 'orderconfirmation',
				component: () => import(/* webpackChunkName: "order-confirm-chunck" */ '../../views/customer/OrderConfirmation.vue')
			},
			{
				path: 'checkout/:company_id',
				name: 'checkout',
				component: () => import(/* webpackChunkName: "checkout-chunck" */ '../../views/customer/Checkout.vue')
			},
			{
				path: 'orders/:company_id',
				name: 'customerhistory',
				component: () => import(/* webpackChunkName: "order-page-chunck" */ '../../views/customer/OrderPage.vue')
			},
			{
				path: 'invoice',
				name: 'customerinvoice',
				component: () => import(/* webpackChunkName: "invoice-chunck" */ '../../views/customer/Invoice.vue')
			},
			{
				path: 'integration-overview',
				name: 'webshopoverview',
				component: () => import(/* webpackChunkName: "integration-chunck" */ '../../views/customer/IntegrationOverview.vue')
			},
			{
				path: 'webshop/:id/:name',
				name: 'singlewebshop',
				component: () => import(/* webpackChunkName: "single-webshop-chunck" */ '../../views/customer/SingleWebshop.vue')
			},
			{
				path: 'webshop-products/:id',
				name: 'webshopproducts',
				component: () => import(/* webpackChunkName: "webshop-prod-chunck" */ '../../views/customer/WebshopProduct.vue')
			},
			{
				path: 'logs/:company_id',
				name: 'userlogs',
				meta: {
					title: 'Customers Logs',
				},
				component: () => import(/* webpackChunkName: "logs-chunck" */ '../../views/customer/Logs.vue')
			},
			{
				path: 'invite/:company_id',
				name: 'invitebuyers',
				meta: {
					title: 'Invite Customers',
				},
				component: () => import(/* webpackChunkName: "invite-chunck" */ '../../views/customer/Invite.vue')
			},
			{
				path: 'subcription',
				name: 'customersubscription',
				meta: {
					title: 'Subscription',
				},
				component: () => import(/* webpackChunkName: "customer-sub-chunck" */ '../../views/customer/Subscription.vue')
			},
			{
				path: 'add-woocommerce',
				name: 'addwoo',
				component: () => import(/* webpackChunkName: "addwoo-chunck" */ '../../views/customer/AddWoo.vue')
			},
			{
				path: 'add-shopify',
				name: 'addshopify',
				component: () => import(/* webpackChunkName: "shopify-chunck" */ '../../views/customer/AddShopify.vue')
			},
			{
				path: 'add-fortnox',
				name: 'addfortnox',
				component: () => import(/* webpackChunkName: "fortnox-chunck" */ '../../views/customer/AddFortnox.vue')
			},
			{
				path: 'blogs/:company_id',
				name: 'bloglist',
				meta: {
					title: 'Company Blog',
				},
				component: () => import(/* webpackChunkName: "blog-list-chunck" */ '../../views/customer/BlogList.vue')
			},
			{
				path: 'blog/:company_id/:id',
				name: 'singleBlog',
				meta: {
					title: 'Company Blog',
				},
				component: () => import(/* webpackChunkName: "single-blog-chunck" */ '../../views/customer/SingleBlog.vue')
			},
			{
				path: 'faqs/:company_id',
				name: 'customer_faqz',
				meta: {
					title: 'Company Faqs',
				},
				component: () => import(/* webpackChunkName: "customer-faq-chunck" */ '../../views/customer/Faqz.vue')
			},
			{
				path: 'settings/:company_id',
				name: 'customer-settings',
				meta: {
					title: 'Settings',
				},
				component: () => import(/* webpackChunkName: "settings-chunck" */ '../../views/customer/Settings')
			},
			{
				name: 'customer-notifications',
				path: 'notifications',
				meta: {
					title: 'Notifications',
				},
				component: () => import(/* webpackChunkName: "portal-article-detail-chunck" */ '../../views/customer/notifications/Notifications')
			},
			{
				name: 'customer-videos',
				path: 'videos-guides/:company_id',
				meta: {
					title: 'Video Guides',
				},
				component: () => import(/* webpackChunkName: "portal-article-detail-chunck" */ '../../views/customer/videoComponent')
			},
		]
	},
	{
		path: '/portal',
		// name: 'app',
		meta: {
			requireAuth: true,
			title: 'Dashboard | B2B Portal',
		},
		component: Main,
		children: [
			{
				path: '',
				name: 'dashboard',
				meta: {
					title: 'Dashboard | B2B Portal',
				},
				component: () => import(/* webpackChunkName: "portal-dash-chunck" */ '../../views/portal/Dashboard.vue')
			},
			{
				path: '404/unavailable',
				name: 'usernotfound',
				meta: {
					title: 'Unavailable | B2B Portal',
				},
				component: () => import(/* webpackChunkName: "portal-notfound-chunck" */ '../../views/portal/NotFound.vue')
			},
			{
				path: 'billing',
				name: 'billings',
				meta: {
					title: 'Settings | B2B Portal',
				},
				component: () => import(/* webpackChunkName: "billing-portal-chunck" */ '../../views/portal/Billing.vue')
			},
			{
				path: 'users',
				name: 'users',
				meta: {
					title: 'User Settings',
				},
				component: () => import(/* webpackChunkName: "portal-users-chunck" */ '../../views/portal/Users.vue')
			},
			{
				path: 'article',
				name: 'article',
				meta: {
					title: 'Article Settings',
				},
				component: () => import(/* webpackChunkName: "article-portal-chunck" */ '../../views/portal/Article.vue')
			},
			{
				path: 'invite-user',
				name: 'invitecustomers',
				meta: {
					title: 'Invite Settings',
				},
				component: () => import(/* webpackChunkName: "portal-invite-chunck" */ '../../views/portal/Invite.vue')
			},
			{
				path: 'orders',
				name: 'orders',
				meta: {
					title: 'Orders',
				},
				component: () => import(/* webpackChunkName: "portal-order-chunck" */ '../../views/portal/OrderPage.vue')
			},
			{
				path: 'invoice',
				name: 'invoice',
				meta: {
					title: 'Orders',
				},
				component: () => import(/* webpackChunkName: "portal-invoice-chunck" */ '../../views/portal/Invoice.vue')
			},
			{
				path: 'subscription',
				name: 'subscription',
				meta: {
					title: 'Subscription',
				},
				component: () => import(/* webpackChunkName: "portal-sub-chunck" */ '../../views/portal/Subscription.vue')
			},
			{
				path: 'customers',
				name: 'customersettings',
				meta: {
					title: 'Customers',
				},
				component: () => import(/* webpackChunkName: "portal-settings-chunck" */ '../../views/portal/SettingCustomer.vue')
			},
			{
				path: 'accounts',
				name: 'accounts',
				meta: {
					title: 'Accounts Settings',
				},
				component: () => import(/* webpackChunkName: "portal-account-chunck" */ '../../views/portal/Account.vue')
			},
			{
				path: 'logs',
				name: 'shopownerlogs',
				meta: {
					title: 'Shopowner Logs',
				},
				component: () => import(/* webpackChunkName: "portal-logs-chunck" */ '../../views/portal/Logs.vue')
			},
			{
				path: 'settings',
				name: 'settings',
				meta: {
					title: 'Company Settings',
				},
				component: () => import(/* webpackChunkName: "portal-all-settings-chunck" */ '../../views/portal/Settings.vue')
			},
			{
				path: 'create-blog',
				name: 'createblog',
				meta: {
					title: 'Company Blog',
				},
				component: () => import(/* webpackChunkName: "create-blog-chunck" */ '../../views/portal/CreateBlog.vue')
			},
			{
				path: 'preview-blog',
				name: 'previewblog',
				meta: {
					title: 'Company Blog',
				},
				component: () => import(/* webpackChunkName: "blog-preview-chunck" */ '../../views/portal/BlogPreview.vue')
			},
			{
				path: 'email-template-blog',
				name: 'email-template',
				meta: {
					title: 'Company Blog Email Template',
				},
				component: () => import(/* webpackChunkName: "blog-preview-chunck" */ '../../views/portal/components/blogComponent/blogEmailTemplate.vue')
			},
			{
				path: 'edit-blog/:id',
				name: 'editblog',
				meta: {
					title: 'Company Edit Blog',
				},
				component: () => import(/* webpackChunkName: "edit-blog-chunck" */ '../../views/portal/EditBlog.vue')
			},
			{
				path: 'blogs',
				name: 'bloglistShopOwner',
				meta: {
					title: 'Company Blog',
				},
				component: () => import(/* webpackChunkName: "portal-blog-list-chunck" */ '../../views/portal/BlogList.vue')
			},
			{
				path: 'faqs',
				name: 'faqz',
				meta: {
					title: 'Company Faqs',
				},
				component: () => import(/* webpackChunkName: "portal-faq-chunck" */ '../../views/portal/Faqz.vue')
			},
			{
				name: 'article-detail',
				path: 'article/:article_id',
				meta: {
					title: 'Article Detail',
				},
				component: () => import(/* webpackChunkName: "portal-article-detail-chunck" */ '../../views/portal/articleDetailComponent')
			},
			{
				name: 'portal-notifications',
				path: 'notifications',
				meta: {
					title: 'Notifications',
				},
				component: () => import(/* webpackChunkName: "portal-article-detail-chunck" */ '../../views/portal/notifications/Notifications')
			},
			{
				name: 'portal-videos',
				path: 'videos-guides',
				meta: {
					title: 'Video Guides',
				},
				component: () => import(/* webpackChunkName: "portal-article-detail-chunck" */ '../../views/portal/videoComponent')
			},
			{
				name: 'portal-integrations',
				path: 'integrations',
				meta: {
					title: 'Integrations',
				},
				component: () => import(/* webpackChunkName: "portal-article-detail-chunck" */ '../../views/portal/Integrations')
			},

		]
	},
	{
		path: '/administrator',
		meta: {
			requireAuth: true,
			title: 'Administrator Dashboard | B2B Portal',
		},
		component: AdministratorMain,
		children: [
			{
				path: '',
				name: 'admin-dashboard',
				meta: {
					title: 'Dashboard | B2B Portal',
				},
				component: () => import(/* webpackChunkName: "administrator-dash-chunck" */ '../../views/administrator/Dashboard.vue')
			},
		]
	},
	{
		path: '*',
		redirect: to => {
			return {name: 'login'}
		}
	},
]


export default routes
