export const en = {
    messages: {
        required: 'This field is required',
        email : 'The {_field_} field must be a valid email',
        min: '{_field_} field must have no less than {length} characters',
        max: 'The {_field_} field should not be greater than {length} characters',
        minmax: 'The {_field_} field must have at least {min} characters and {max} characters at most',
        // minmaxuser: 'The {_field_} field must have at least {min} characters and {max} characters at most',
        // minmaxpass: 'The {_field_} field must have at least {min} characters and {max} characters at most',
        validcom : 'Sample valid {_field_} is xxxxxx-xxxx'
    }
}

export const se = {
    messages: {
        required: 'Fält är obligatoriska',
        email : '{_field_} fältet måste innehålla en giltig e-postadress',
        min: '{_field_} fältet får inte ha mindre än {length} tecken',
        max: '{_field_} fältet får inte ha mer än {length} tecken',
        minmax: '{_field_} fältet får ha minst {min} och max {max} tecken',
        // minmaxuser: '{_field_} fältet får ha minst {min} och max {max} tecken',
        // minmaxpass: '{_field_} fältet får ha minst {min} och {max} tecken',
        validcom : 'Exempel på giltigt format för {_field_} fältet är xxxxxx-xxxx'
    }
}

export const dk = {
    messages: {
        required: 'Dette felt er påkrævet',
        email : ' {_field_} felt skal være en gyldig e -mail',
        min: '{_field_} felt må ikke indeholde mindst {length} tegn',
        max: '{_field_} felt må ikke være større end {length} tegn',
        minmax: '{_field_} felt skal have mindst {min} tegn og højst {max} tegn',
        // minmaxuser: '{_field_} felt skal have mindst {min} tegn og højst {max} tegn',
        // minmaxpass: '{_field_} felt skal have midnst {min} tegn og højst {max} tegn',
        validcom : 'Eksepmpel på gyldigt format for {_field_} feltet er xxxxxx-xxxx'
    }
}

export const no = {
    messages: {
        required: 'Dette feltet er obligatorisk',
        email : '{_field_} feltet må ha inneholde en gyldig e-postadresse ',
        min: '{_field_} feltet må inneholde ikke mindre enn {length} tegn',
        max: '{_field_} feltet må inneholde mer enn {length} tegn ',
        minmax: '{_field_} feltet må inneholde minst  {min} tegn og maks {max} tegn ',
        // minmaxuser: '{_field_} feltet må ha minst {min} tegn og maks {max} tegn',
        // minmaxpass: '{_field_} feltet må ha minst {min} tegn og {max} tegn',
        validcom : 'Eksempel på gyldig format for {_field_} feltet er xxxxxx-xxxx'
    }
}


export default { en, se, dk, no }