<template>
<span class="position-relative w-full">
	<span @click="toggleName" v-if="!updateName">{{ category.name }}</span>
            <input :value="category.name" @keypress.enter="updateNameData($event)"
				   @change="updateNameData($event, item)" class="focus:outline-none z-50 relative input-border w-full"
				   v-else/>

	</span>
</template>

<script>
export default {
	name: "EditArticleCategory",
	props: {
		category: {
			type: Object
		},
	},
	data: function () {
		return {
			updateName: false,
			item: {},
		}
	},
	mounted() {
		this.item = this.category
	},
	methods: {
		updateNameData: function (e) {
			if (e.target.value !== this.item.name) {
				this.$store.dispatch('portal/updateProductCategory', {
					id: this.category.id,
					name: e.target.value
				}).then(_ => {
					this.category.name = e.target.value
				}).catch(err => {

				})
			}

			this.updateName = false
		},
		toggleName: function () {
			this.updateName = !this.updateName
			this.item = this.category
		}
	}
}
</script>

<style scoped>
.input-border {
	border-bottom: 1px solid #d7d7d7;
}
</style>
