<template>
	<div v-loading="loading" class="flex flex-col p-3 mt-3">
		<div class="flex bg-white p-3 mb-4">
			<div class="grid grid-cols-1 md:grid-cols-5 gap-4 w-full">
				<div class="col-span-2">
					<div class="form-group">
						<label for="specification" class="font-semibold">{{ $t('specificationTitle') }}</label>
						<input type="text" v-model="form.title"
							   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
					</div>
				</div>
				<div class="col-span-2">
					<div class="form-group">
						<label for="specification" class="font-semibold">{{ $t('specificationText') }}</label>
						<input type="text" v-model="form.text"
							   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
					</div>
				</div>
				<div class="col-span-1">
					<button :disabled='updating' v-on:click="addSpecification"
							class="w-full text-white px-3 bg-green-500 py-2 rounded-md whitespace-nowrap md:mt-7">
						<span v-if="updating" class="flex justify-center">
							<svg class="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
								 viewBox="0 0 24 24">
							<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
									stroke-width="4"></circle>
							<path class="opacity-75" fill="currentColor"
								  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
							</svg>
						</span>
						<span class="text-xs" v-else>
                                        {{ $t('add') }}
                                    </span>
					</button>
				</div>
			</div>
		</div>
		<div class="flex bg-white p-3 mb-4">
			<div class="drawer-category-management table-responsive rounded-lg bg-white border">
				<table style="width: 100%;" id="exampledestroy" class="table table-hover table-bordered">
					<thead>
					<tr>
						<th> {{ $t('specificationTitle') }}</th>
						<th>{{ $t('specificationText') }}</th>
						<th>{{ $t('actions') }}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(item, i) in specificationList.results" :key="i">
						<td>{{ item.title }}</td>
						<td>{{ item.text }}</td>
						<td>
							<div class="flex justify-center space-x-2">
                                                <span v-on:click="updateSpecification(item)"
													  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                                                    <img src="./../../assets/images/icons/edit.png" alt="">
                                                </span>
								<span v-on:click="deleteSpecification(item.id)"
									  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                                                    <img src="./../../assets/images/icons/delete.png" alt="">
                                                </span>
							</div>
						</td>
					</tr>
					</tbody>
				</table>
			</div>

		</div>
		<div class="pt-2 pl-3 pr-3">
			<div class="flex flex-col md:flex-row justify-between mb-4">
				<!--                        <span class="flex justify-between align-items-center space-x-5 mt-4">-->
				<!--                            <span class="text-base">{{ $t('show') }}</span>-->
				<!--                            <span>-->
				<!--                                <select v-model="limit" class="form-select">-->
				<!--                                    <option value="50" selected>50</option>-->
				<!--                                    <option value="100">100</option>-->
				<!--                                    <option value="200">200</option>-->
				<!--                                    <option value="400">400</option>-->
				<!--                                    <option value="500">500</option>-->
				<!--                                </select>-->
				<!--                            </span>-->
				<!--                        </span>-->
				<!--				<div class="pagination-block"> &lt;!&ndash;GET_ARTICLES.total_pages-&ndash;&gt;-->
				<!--					<paginate-->
				<!--						:page-count="specificationList.total_pages"-->
				<!--						:click-handler="changePageNumber"-->
				<!--						prev-text="<"-->
				<!--						next-text=">"-->
				<!--						:container-class="'pagination space-x-2'">-->
				<!--					</paginate>-->
				<!--				</div>-->
			</div>
		</div>
	</div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import {getCompanyId} from "../../services/storage-window";

export default {
	name: "ArticleSpecification",
	components: {paginate: Paginate,},
	props: {
		article_id: {
			required: true
		},
		articleSpecificationObj: {
			type: Object
		}
	},
	data: function () {
		return {
			specificationList: {results: [], count: 1, total_pages: 1},
			updating: false,
			loading: true,
			limit: 20,
			form: {
				text: '',
				title: '',
				article_id: '',
			}
		}
	},
	computed: {},
	mounted() {
		this.specificationList = {results: [], count: 1, total_pages: 1}
		this.getArticleSpecification()
	},
	methods: {
		changePageNumber(number) {
			this.loading = true
			let pageUrl = `?article__id=${this.article_id}&page=${number}&limit=${this.limit}`
			this.$store.dispatch('portal/getArticleSpecification', {URL: pageUrl})
				.then(resp => {
					this.loading = false
					this.specificationList = resp.data.data
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getArticleSpecification: function () {
			this.loading = true
			let pageUrl = `?article__id=${this.article_id}`
			this.$store.dispatch('portal/getArticleSpecification', {URL: pageUrl}).then(resp => {
				this.loading = false
				this.specificationList = resp.data.data
			}).catch(err => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		addSpecification: function () {
			this.updating = true
			if (this.form.text === '' || this.form.title === '') {
				this.updating = false
				return this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			}
			this.form.article_id = this.article_id
			this.$store.dispatch('portal/saveArticleSpecification', this.form).then(resp => {
				this.updating = false
				this.specificationList.results.push(resp.data.data)
				this.$services.helpers.notification(this.$t('addedSuccess'), 'success', this)
				this.form = {
					text: '',
					title: '',
					article_id: '',
				}

			}).catch(err => {
				this.updating = false
				window.Bus.$emit('output-error', err)
			})
		},
		deleteSpecification: function (id) {
			let data = {
				id: id,
				data: ''
			}
			this.$store.dispatch('portal/deleteArticleSpecification', data).then(_ => {
				let index = this.specificationList.results.findIndex(item => item.id === parseInt(id))
				if (index > -1) {
					this.specificationList.results.splice(index, 1)
				}
			})

		},
		updateSpecification: function (item) {
			this.$emit('editArticleSpecification', item)

		}
	}
}
</script>

<style scoped>

</style>
