export default {
	GET_LOCALE_LANG(state) {
		return state.setLocale
	},
	MODAL_INVITE(state) {
		return state.model_invite
	},
	MODAL_PHOTO(state) {
		return state.model_photo
	},
	MODAL_UTIL(state) {
		return state.model_util
	},
	GET_CATEGORY_DRAWER(state) {
		return state.categoryDrawer
	},
	GET_CUSTOMER_SETTING_DRAWER(state) {
		return state.customerSettingDrawer
	},
	GET_CUSTOMER_CATEGORY_DRAWER(state) {
		return state.customerCategoryDrawer
	},
	GET_BLOG_DRAWER(state) {
		return state.blogDrawer
	},
	GET_CREATE_ORDER_DRAWER(state) {
		return state.createOrderDrawer
	},
	GET_CREATE_ARTICLE_DRAWER(state) {
		return state.createArticleDrawer
	},
	GET_CREATE_CUSTOMER_DRAWER(state) {
		return state.createCustomerDrawer
	},
	GET_EDIT_CUSTOMER_DRAWER(state) {
		return state.editCustomerDrawer
	},
	GET_ORDER_DETAIL_DRAWER(state) {
		return state.orderDetailDrawer
	},
	GET_ORDER_EDIT_DRAWER(state) {
		return state.editOrderDrawer
	},
	GET_CART_DRAWER(state) {
		return state.openCartDrawer
	},
	GET_CUSTOMER_SPECIFIC_SLIDER(state) {
		return state.openCustomerSpecificSlider
	},
}
