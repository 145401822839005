<template>
	<div
		:class="notify.is_read === false ? 'bg-gray-100' : ''"
		class="border-b-2 mb-2"
	>
		<div class="p-1">
			<h4 class="timeline-title font-semibold">
				{{ notify.title }}
				<span
					class="badge badge-info badge-email"
					v-if="notify.type === 'email'"
					>{{ notify.type }}</span
				>
			</h4>
			<p class="pt-1"></p>
			<div v-if="notify.text.length <= 80">
				{{ notify.text }}
			</div>
			<div v-else>
				<span v-if="open">{{ notify.text }}</span>
				<span v-else>{{ notify.text.slice(0, 80) + "..." }}</span>
				<span
					@click.prevent="openMessage"
					v-if="open"
					class="cursor-pointer font-semibold link-color"
				>
					{{ $t("less") }}</span
				>
				<span
					@click.prevent="openMessage"
					class="cursor-pointer font-semibold link-color"
					v-else
				>
					{{ $t("more") }}</span
				>
			</div>
			<span class="flex justify-end mr-2">
				<button
					:disabled="marking"
					v-if="!notify.is_read"
					v-on:click.prevent="readNotification(notify.id)"
					class="font-semibold cursor-pointer text-blue-600"
				>
					{{ $t("markRead") }}
				</button>
			</span>
			<span class="divide-x"></span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "notification-handler",
	props: {
		notify: {
			type: Object,
			required: true,
		},
		mode: {
			type: String,
			default: "seller",
		},
	},
	data() {
		return {
			open: false,
			marking: false,
		};
	},
	computed: {
		...mapGetters({
			USER_TOKEN: "USER_TOKEN",
			USER_REFRESH: "USER_REFRESH",
			GET_USER_COMPANY_ID: "GET_USER_COMPANY_ID",
		}),
	},
	methods: {
		openMessage() {
			this.open = !this.open;
		},
		showAllMessage() {
			let text = "";
			if (this.open) {
				text = this.notify.text;
			} else {
				text = this.notify.text.slice(0, 20).join(" ") + "...";
			}
			return text;
		},
		sliceMessage() {},
		readNotification(notifyId) {
			this.marking = true;
			if (this.mode === "seller") {
				this.$store
					.dispatch("portal/readNotification", { id: notifyId })
					.then((res) => {
						this.marking = false;
						window.Bus.$emit("reload-notification");
						this.$services.helpers.notification(
							this.$t("notificationNowMarkAsRead"),
							"success",
							this
						);
					})
					.catch((err) => {
						this.marking = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.location.reload();
						}
					});
			} else {
				this.$store
					.dispatch("customer/readNotification", { id: notifyId })
					.then((res) => {
						this.marking = false;
						window.Bus.$emit("reload-notification");
						this.$services.helpers.notification(
							this.$t("notificationNowMarkAsRead"),
							"success",
							this
						);
					})
					.catch((err) => {
						this.marking = false;
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.location.reload();
						}
					});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.badge-email {
	font-size: 7px;
	padding: 4px 5px;
}
</style>
