<template>
	<div id="accordion" class="border-solid category-header bg-white">
		<div class="flex justify-content-between">
			<div class="flex">
				<span
					class="col-span-1"
					v-if="openChild === false"
					v-on:click="openChildCategory"
				>
					<i class="fa fa-chevron-circle-right span-icon"></i>
				</span>
				<span
					class="col-span-1"
					v-if="openChild === true"
					v-on:click="openChildCategory"
				>
					<i class="fa fa-chevron-circle-down span-icon"></i>
				</span>
				<h5 class="mb-0 text-base font-normal col-span-1 ml-3">
					{{ category.name }}
				</h5>
			</div>
			<div>
				<span class="flex">
					<span
						class="text-gray-900 float-right mr-2 cursor-pointer"
						v-on:click="openCategory"
						v-if="showHere === true"
						><i class="el-icon-user"></i
					></span>
					<span
						class="text-gray-900 float-right mr-2 cursor-pointer"
						v-if="showHere === true"
						v-on:click="editTree"
						><i class="el-icon-edit text-info"></i
					></span>

					<span
						class="text-gray-900 cursor-pointer mr-2"
						v-if="
							category.show_only_product_in_category === false &&
							showHere === true
						"
						v-on:click="markAsPin(true)"
					>
						<img
							src="./../../../assets/images/icons/Paomedia-Small-N-Flat-Pin.512.png"
							alt="pin-icon"
							class="w-4 h-4"
						/>
					</span>

					<span
						class="text-gray-900 cursor-pointer mr-2"
						v-if="category.show_only_product_in_category === true"
						v-on:click="markAsPin(false)"
					>
						<img
							src="./../../../assets/images/icons/Paomedia-Small-N-Flat-Pin.512.png"
							alt="pin-icon"
							class="w-4 h-4"
						/>
					</span>

					<span
						class="text-gray-900 float-right mr-4 cursor-pointer"
						v-on:click="deleteTree"
						v-if="showHere === true"
						><i class="el-icon-delete text-danger"></i
					></span>
				</span>
			</div>
		</div>
		<div class="collapse" :class="{ show: openChild === true }">
			<div
				class="category-sub-header"
				v-for="(item, key) in category.child_node"
				:key="key"
				:class="{
					'border-bottom': key + 1 < category.child_node.length,
				}"
			>
				<div class="subcategory">
					<span
						>{{ item.name }}
						<i
							class="ml-1 mr-1 not-italic"
							v-if="item.subcategory.length > 0"
						>
							>>
						</i>
					</span>
					<span
						class="font-normal cursor-pointer mr-2"
						v-for="(sub, k) in item.subcategory"
						:key="k"
						>{{ sub.name
						}}<i
							class="ml-1 mr-1 not-italic"
							v-if="k + 1 !== item.subcategory.length"
						>
							>></i
						>
					</span>
				</div>
			</div>
		</div>
		<ModalContainer
			v-if="showCategoryModal"
			width="md:w-1/3 w-11/12"
			:title="$t('customerSpecificCategoryText')"
			@handleClose="showCategoryModal = false"
			:close="!true"
		>
			<div class="container" slot="body">
				<div
					class="absolute inset-0 z-40"
					@click="openCustomersDropdown = false"
					v-if="openCustomersDropdown"
				></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="updateCategoryRelevant">
							<div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs"
										>{{ $t("customerName") }}</label
									>
									<div>
										<input
											v-model="customerSearchName"
											type="text"
											@input="filterCustomerList"
											@focus="
												openCustomersDropdown = true
											"
											:placeholder="$t('searchCustomers')"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
									<div
										style="z-index: 99999"
										class="w-1/2 absolute bg-white shadow border rounded mt-1"
										v-if="
											openCustomersDropdown &&
											filteredCustomer.length > 0
										"
									>
										<ul>
											<li
												class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
												v-for="item in filteredCustomer.slice(
													0,
													7
												)"
												:key="item.id"
												@click="addCustomerToList(item)"
											>
												{{ item.customer_name }}
											</li>
										</ul>
									</div>
								</div>
								<div class="row">
									<div
										class="col-md-6 mb-1 mt-3"
										v-for="customer in selectedCustomers"
										:key="customer.id"
									>
										<div
											class="flex justify-between bg-white border rounded-md shadow-sm p-1"
										>
											<div>
												{{ customer.customer_name }}
											</div>
											<div>
												<span
													class="cursor-pointer"
													@click="
														removeUserFromSelectedList(
															customer
														)
													"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														viewBox="0 0 20 20"
														fill="currentColor"
													>
														<path
															fill-rule="evenodd"
															d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
															clip-rule="evenodd"
														/>
													</svg>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group mt-3">
								<button
									:disabled="updating"
									:style="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? 'background:' +
											  GET_CUSTOMIZATION.button
											: ''
									"
									:class="
										GET_CUSTOMIZATION &&
										GET_CUSTOMIZATION.button
											? ''
											: 'bg-blue-900'
									"
									class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full"
								>
									<span
										v-if="updating"
										class="flex align-items-center space-x-3"
									>
										<svg
											class="animate-spin mr-2 h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												class="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												stroke-width="4"
											></circle>
											<path
												class="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
										{{ $t("updating") }}
									</span>
									<span v-else>{{ $t("update") }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5"></div>
		</ModalContainer>
	</div>
</template>

<script>
import ModalContainer from "./../../../customComponent/Modal";
import { mapGetters } from "vuex";
import debounce from "lodash.debounce";

export default {
	name: "categoryTree",
	components: {
		ModalContainer,
	},
	props: {
		category: {
			type: Object,
		},
	},
	data: function () {
		return {
			checked: false,
			openChild: false,
			updating: false,
			showCategoryModal: false,
			openCustomersDropdown: false,
			customerSearchName: "",
			selectedCustomers: [],
			filteredCustomer: [],
			showHere: true,
			categoryObj: {},
			customerList: [],
		};
	},
	mounted() {
		this.categoryObj = this.category;
		this.selectedCustomers = [];
		this.selectedCustomers = this.category.allowed_customers;
		window.Bus.$on("remove-child-category", (e) => {
			let childIndex = this.categoryObj.child_node.findIndex(
				(item) => item.id === e.id
			);
			if (childIndex > -1) {
				this.categoryObj.child_node.splice(childIndex, 1);
			}
		});
		window.Bus.$on("remove-child-sub-category", (e) => {
			let childIndex = this.categoryObj.child_node.findIndex(
				(child) => child.id === e.child_category
			);
			if (childIndex > -1) {
				let childObj = this.categoryObj.child_node[childIndex];
				let subChildIndex = childObj.subcategory.findIndex(
					(subIn) => subIn.id === e.id
				);
				if (subChildIndex > -1) {
					childObj.subcategory.splice(subChildIndex, 1);
					this.categoryObj.child_node[childIndex] = childObj;
				}
			}
		});
		window.Bus.$on("update-child-sub-category", (e) => {
			this.categoryObj.child_node.map((item) => {
				let subIndex = item.subcategory.findIndex(
					(subIn) => subIn.id === e.id
				);
				if (subIndex > -1) {
					item.subcategory[subIndex].name = e.name;
				}
			});
		});
	},
	computed: {
		...mapGetters({
			USER_TOKEN: "auth/USER_TOKEN",
			GET_CUSTOMIZATION: "portal/GET_CUSTOMIZATION",
			GET_COMPANY_SETTING: "portal/GET_COMPANY_SETTING",
			GET_LOCALE: "utility/GET_LOCALE_LANG",
			GET_ALL_CUSTOMERS: "portal/GET_ALL_CUSTOMERS",
		}),
	},
	methods: {
		markAsPin: function (status) {
			let description =
				status === true
					? this.$t("pinCategory").replace(
							"{category_name}",
							this.category.name
					  )
					: this.$t("unPinCategory").replace(
							"{category_name}",
							this.category.name
					  );
			let self = this;
			this.$confirm(`${description}`, `${this.$t("info")}`, {
				confirmButtonText: this.$t("yes"),
				cancelButtonText: this.$t("cancel"),
				type: "warning",
				beforeClose: (action, instance, done) => {
					if (action === "confirm") {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = this.$t("loading");
						this.$store
							.dispatch("portal/updateProductCategory", {
								id: self.category.id,
								name: self.category.name,
								show_only_product_in_category: status,
							})
							.then((_) => {
								instance.confirmButtonLoading = false;
								done();
								self.category.show_only_product_in_category =
									status;
								this.$services.helpers.notification(
									this.$t("categoryUpdated"),
									"success",
									this
								);
							})
							.catch((error) => {
								done();
								instance.confirmButtonLoading = false;
								if (
									error.response.status === 400 ||
									error.response.status === 500 ||
									error.response.status === 403
								) {
									self.$services.helpers.notification(
										this.$t("serverError"),
										"error",
										this
									);
								}
							});
					} else {
						done();
						instance.confirmButtonLoading = false;
					}
				},
			})
				.then(() => {})
				.catch(() => {});
		},
		filterCustomer: debounce(function () {
			let URL = `?limit=30&search=${this.customerSearchName}`;
			this.$store
				.dispatch("portal/getAllEndCustomers", { URL: URL })
				.then((resp) => {});
		}, 500),
		filterCustomerList: debounce(function () {
			if (this.customerSearchName !== "") {
				this.$store
					.dispatch("portal/getCompanyEndCustomers", {
						page:
							this.$services.endpoints.COMPANY_ENDCUSTOMERS +
							`?limit=50&search=${this.customerSearchName}`,
					})
					.then((res) => {
						this.filteredCustomer = res.data.data;
					})
					.catch((err) => {
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		}, 500),
		addCustomerToList(customer) {
			// Check if customer exist already
			const filter = this.selectedCustomers.filter(
				(item) => parseInt(item.id) === parseInt(customer.id)
			);
			if (filter.length < 1) {
				this.selectedCustomers.push(customer);
			}
			this.openCustomersDropdown = false;
		},
		removeUserFromSelectedList(customer) {
			this.selectedCustomers = this.selectedCustomers.filter(
				(item) => item.id !== customer.id
			);
		},
		updateCategoryRelevant: function () {
			let customers_id = [];
			this.selectedCustomers.map((item) => {
				if (customers_id.includes(item.id) === false) {
					customers_id.push(parseInt(item.id));
				}
			});
			let payload = {
				id: this.category.id,
				name: this.category.name,
				allowed_customers: customers_id,
			};
			this.updating = true;
			this.$store
				.dispatch("portal/updateProductCategory", payload)
				.then((res) => {
					this.updating = false;
					this.showCategoryModal = false;
					this.customerSearchName = "";
					this.$services.helpers.notification(
						this.$t("categorySpecificUpdate"),
						"success",
						this
					);
				})
				.then((err) => {
					this.updating = false;
					try {
						if (err.response.status === 400) {
							this.$services.helpers.notification(
								err.response.data.message,
								"error",
								this
							);
						} else if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_USER", null);
							window.Bus.$emit("sign-out");
						}
					} catch (e) {}
				});
		},
		openCategory: function () {
			this.showCategoryModal = !this.showCategoryModal;
		},
		openChildCategory: function () {
			this.openChild = !this.openChild;
		},
		editTree: function () {
			window.Bus.$emit("edit-category-tree", this.category);
		},
		deleteTree: function () {
			this.$confirm(
				`${this.$t("removeBlogCategory")} ${this.category.name}?`,
				`${this.$t("warning")}`,
				{
					confirmButtonText: this.$t("yes"),
					cancelButtonText: this.$t("cancel"),
					type: "warning",
					beforeClose: (action, instance, done) => {
						if (action === "confirm") {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = "Loading...";
							this.$store
								.dispatch("portal/deleteProductCategory", {
									id: this.category.id,
								})
								.then((_) => {
									instance.confirmButtonLoading = false;
									done();
									this.$emit("removeItem");
								})
								.catch((error) => {
									done();
									instance.confirmButtonLoading = false;
									if (
										error.response.status === 400 ||
										error.response.status === 500 ||
										error.response.status === 403
									) {
										this.$services.helpers.notification(
											this.$t("serverError"),
											"error",
											this
										);
									}
								});
						} else {
							done();
							instance.confirmButtonLoading = false;
						}
					},
				}
			)
				.then(() => {})
				.catch(() => {});
		},
	},
};
</script>

<style scoped>
.category-header {
	padding-top: 7px;
	padding-bottom: 7px;
	padding-left: 13px;
	border: 1px solid rgb(243 244 246);
}

.category-sub-header {
	padding-top: 12px;
	padding-bottom: 7px;
	padding-left: 31px;
}

.border-bottom {
	border-bottom: 1px solid rgb(243 244 246) !important;
}

.span-icon {
	font-size: 16px;
	position: relative;
	top: 3px;
}
</style>
