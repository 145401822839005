<template>
	<div class="subcategory" @click="actionTag">
		<span class="text-blue-500 cursor-pointer">{{ data.name }}</span> >>
		<span v-if="openActionTag === true"
			  style="position: absolute;display: flex">
			      <div class="flex justify-center space-x-2">
	            <span v-on:click="deleteSubCategory(data)"
					  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
	                                                <img src="./../../../assets/images/icons/delete.png" alt="">
	                 </span>
	        <span v-on:click="updateChildCategory(data)"
				  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
	                                                <img src="./../../../assets/images/icons/edit.png" alt="">
	           </span>
	      </div>
		</span>
	</div>
	<!--  <tr>-->
	<!--    <td><input type="checkbox" class="font-xs checkbox-height" v-model="data.checked" v-on:change="checkboxOrder"-->
	<!--               :value="data.checked"></td>-->
	<!--    &lt;!&ndash; <td></td> &ndash;&gt;-->
	<!--    <td>{{ $t('childCategory') }}</td>-->
	<!--    <td>-->

	<!--      <div style="display: flex;justify-content: space-around">-->
	<!--        <span><span class="font-semibold">{{ $t('childCategory') }}:</span> {{ data.name }}</span>-->
	<!--      </div>-->

	<!--      &lt;!&ndash;            <span v-else>&ndash;&gt;-->
	<!--      &lt;!&ndash;                <form @submit.prevent="updateChildCategory" class="z-50 relative w-full">&ndash;&gt;-->
	<!--      &lt;!&ndash;                    <input v-model="childEditText" type="text" class="focus:outline-none w-full">&ndash;&gt;-->
	<!--      &lt;!&ndash;                </form>&ndash;&gt;-->
	<!--      &lt;!&ndash;            </span>&ndash;&gt;-->
	<!--    </td>-->
	<!--    <td>-->

	<!--    </td>-->
	<!--  </tr>-->
</template>

<script>
export default {
	name: 'CategoryChildTableBodyDrawer',
	props: {
		data: {
			type: Object,
			require: true,
		},
		category: {
			type: Object,
			require: true,
		},
	},
	data() {
		return {
			checked: false,
			updateStatus: false,
			childEditText: this.data.name,
			openActionTag: false
		}
	},
	mounted() {
		window.Bus.$on('remove-category-update', _ => {
			this.updateStatus = false
		})
	},
	methods: {
		actionTag: function () {
			this.openActionTag = !this.openActionTag
		},
		checkboxOrder(e) {
			window.Bus.$emit('update_delete_checkbox', {
				category_id: this.data.category_id,
				value: this.data.checked,
				id: this.data.id
			})
		},
		toggleStatus() {
			this.updateStatus = !this.updateStatus
			if (this.updateStatus) {
				window.Bus.$emit('update-category-backdrop')
			}
		},
		updateChildCategory(data) {
			window.Bus.$emit('update-category-subcategory-event', {type: 'sub-category', data: data})
		},
		deleteSubCategory(data) {
			window.Bus.$emit('delete-category-subcategory-event', {type: 'sub-category', data: data})
		}
	}
}
</script>

<style lang="scss" scoped>
.table tbody tr > td {
	padding: 0.4rem 0.55rem !important;
	font-size: 15px !important;
	color: #6F6F6F !important;
}

.subcategory {
	position: relative;
}
</style>
