export default {
	UPDATE_AUTH_USER(state, payload) {
		state.authUser = payload
	},
	SET_COMPANIES(state, payload) {
		state.companies = payload
	},
	SET_BUYERS(state, payload) {
		state.buyers = payload
	},
}

