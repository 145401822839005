<template>
	<div class="bg-white p-2">
		<div class="flex justify-start lg:justify-end">
			<button v-on:click="uploadImages"
					class="w-24 text-white px-3 bg-blue-800 py-2 rounded-md whitespace-nowrap md:mt-7">
				<span class="text-xs">
                                        {{ $t('add') }}
                                    </span>
			</button>
		</div>
		<div class="mt-3">
			<div class="grid grid-cols-1 gap-1 lg:grid-cols-3 gap-3 ">
				<div v-for="(img,i) in article.images" :key="i" class="bg-white shadow border rounded relative h-4/5">
					<v-lazy-image class="cursor-pointer object-cover h-full" :src="img.image" alt=""/>
					<div
						class="rounded-md w-full h-full absolute top-0 bottom-0 left-0 right-0 opacity-0 hover:opacity-100">
						<div class="flex items-center w-full h-full justify-center">

							<span v-on:click="deleteImage(img.id,i)"
								  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                    		<img src="./../../../../assets/images/icons/delete.png" alt="">
                </span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VLazyImage from 'v-lazy-image/v2'
import {mapGetters} from "vuex"
import bus from "../../../../bus";
import {getCompanyId} from "../../../../services/storage-window";

export default {
	name: "articleImages",
	props: {
		article: {
			type: Object,
		}
	},
	computed: {
		...mapGetters({
			GET_ARTICLES: 'portal/GET_ARTICLES',
		})
	},
	components: {
		VLazyImage
	},
	methods: {
		uploadImages: function () {
			this.article.index = this.GET_ARTICLES.data.findIndex(item => item.article_number === this.article.article_number)
			bus.$emit('open-article-modal', this.article)
		},
		deleteImage: function (image_id, key) {
			const self = this
			this.$confirm(`${this.$t('removeImage')}?`, `${this.$t('warning')}`, {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('cancel'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true
						instance.confirmButtonText = 'Loading...'
						let payload = {
							article_id: self.article.id,
							image_id: image_id
						}
						self.$store.dispatch('portal/deleteImage', payload)
							.then(_ => {
								done()
								this.$services.helpers.notification(this.$t('imageRemoveSuccess'), 'success', self)
								try {
									let articleIndex = self.GET_ARTICLES.data.findIndex(item => item.article_number === self.article.article_number)
									const ImagesFile = self.GET_ARTICLES.data[articleIndex].images

									ImagesFile.splice(key, 1)
									const article = self.GET_ARTICLES.data[articleIndex]
									article.images = ImagesFile
									self.GET_ARTICLES.data[articleIndex] = article
									self.modalImage = false
									instance.confirmButtonLoading = false
								} catch (e) {

								}
							}).catch((err) => {
							done()
							instance.confirmButtonLoading = false
							window.Bus.$emit('output-error', err)
						})
					} else {
						done()
					}
				}
			}).then(() => {
				// window.Bus.$emit('fetch-article')
			})
				.catch(() => {

				})
		}
	},

}
</script>

<style scoped>

</style>
