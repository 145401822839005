<template>
	<div class="app-container df bg-gray-100 body-tabs-shadow app-theme-gray fixed-header fixed-sidebar"
		 id="app-root-container">
		<NavBar/>
		<div class="app-main single-main">
			<div class="absolute bg-red-30 inset-0 z-100" style="z-index:9999999999" @click="closeNotifyDiv"
				 v-if="showFullCover"></div>
			<SideBar/>
			<div class="app-main__outer">
				<router-view/>
				<footer-dash/>
			</div>
		</div>
		<Notification/>
	</div>
</template>

<script>
import NavBar from "./includes/NavBar";
import SideBar from "./includes/SideBar";
import FooterDash from "../../components/Footer";

export default {
	name: "main-admin",
	components: {
		NavBar,
		SideBar,
		FooterDash,
		Notification: () => import('./../../customComponent/NotificationAlert')
	},
	data: function () {
		return {
			showFullCover: false
		}
	},
	methods: {
		closeNotifyDiv() {
			window.Bus.$emit('close-cart')
			this.showFullCover = false
		},

	},
	mounted() {
		window.Bus.$on('sign-out', _ => {
			if (this.$route.path !== '/') {
				this.$store.commit('utility/CHANGE_LOCALE', 'se')
				this.$store.commit('auth/CLEAR_AUTH_USER', null)
				this.$router.replace({name: 'login'})
			}
		})
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	padding-top: unset !important;
}

.form-control {
	height: unset !important;
	padding: .275rem .75rem !important;
}

.app-theme-gray.app-container {
	background: #f1f4f6 !important
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control, .form-select {
	border: 1px solid $form-border !important;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	// cursor: pointer;
}

.carousel-cell {
	width: 100%; /* full width */
	height: 200px;
	//   background: #222;
	/* center images in cells with flexbox */
	display: flex;
	align-items: center;
	justify-content: center;
}

.carousel.is-fullscreen .carousel-cell {
	height: 100%;
}

.carousel-cell img {
	display: block;
	max-height: 100%;
}

.height-increase {
	height: 63vh;
}

.drawer-category-management {
	.table th {
		color: #050505 !important;
		padding: 0.6rem 0.55rem !important;
	}

	.table tbody tr > td {
		font-size: 13px !important;
		color: #6F6F6F !important;
		padding: 0.4rem 0.55rem !important;
	}
}

.previewTitle {
	text-align: center;
	margin-right: 25px;
	margin-left: 25px;
}
</style>
