const PORTAL_TOKEN = '__b2bxx__'
const PORTAL_CUS = '__b2bcusxx__'

export const saveToken = (token) => {
	window.localStorage.setItem(PORTAL_TOKEN, token)
}


export const saveCusToken = (token) => {
	window.localStorage.setItem(PORTAL_CUS, token)
}

export const getToken = () => {
	return window.localStorage.getItem(PORTAL_TOKEN)
}

export const getCusToken = () => {
	return window.localStorage.getItem(PORTAL_CUS)
}

export const destroyToken = () => {
	window.localStorage.removeItem(PORTAL_TOKEN)
}

export const destroyCusToken = () => {
	window.localStorage.removeItem(PORTAL_CUS)
}

export default {saveToken, getToken, destroyToken, saveCusToken, getCusToken, destroyCusToken}
