export default {
	GET_AUTH_USER(state) {
		return state.authUser
	},
	GET_COMPANY_USER(state) {
		return state.companyUser
	},
	GET_COMPANY_SETTING(state) {
		return state.companySetting
	},
	GET_COMPANY_FORM_HELP_TEXT(state) {
		return state.CompanyFormHelpText
	},
	GET_ARTICLES(state) {
		return state.articles
	},
	GET_COPY_ARTICLES(state) {
		return state.copyArticles
	},
	GET_SINGLE_ARTICLE_CHECKED(state) {
		if (state.articles.data) {
			const filterChecked = state.articles.data.filter(item => item.checked === true)
			if (filterChecked.length > 0 && filterChecked.length < 2) {
				return {status: true, data: filterChecked}
			} else {
				return {status: false, data: []}
			}
		}
	},
	GET_ARTICLES_CHECKED(state) {
		if (state.articles.data) {
			const filterChecked = state.articles.data.filter(item => item.checked === true)
			if (filterChecked.length > 0) {
				return {status: true, data: filterChecked}
			} else {
				return {status: false, data: []}
			}
		}
	},
	GET_PRODUCT_CATEGORY_CHECK(state) {
		if (state.productCategory.data) {
			const filterChecked = state.productCategory.data.filter(item => item.checked === true)
			if (filterChecked.length > 0) {
				return {status: true, data: filterChecked}
			} else {
				return {status: false, data: []}
			}
		}
	},
	GET_PRODUCT_CATEGORY(state) {
		return state.productCategory
	},
	GET_SPREADSHEET_ARTICLES(state) {
		return state.articleSpreadSheet
	},
	GET_ORDERS(state) {
		return state.orders
	},
	GET_SUBCRIPTION_TYPE(state) {
		return state.subscriptionType
	},
	GET_NOTIFICATION(state) {
		return state.userNotification
	},
	GET_ACTIVE_ACCOUNTS(state) {
		return state.activeAccounts
	},
	GET_USER_SUBSCRIPTION(state) {
		return state.userSubscription
	},
	GET_SHOP_BILLING(state) {
		return state.shopBilling
	},
	GET_INVOICE(state) {
		return state.invoice
	},
	GET_USER_DETAILS(state) {
		return state.userDetails
	},
	GET_PRICE_LIST(state) {
		return state.priceList
	},
	GET_COST_CENTER(state) {
		return state.costCenter
	},
	GET_CUSTOMER_CATEGORY(state) {
		return state.customerCategory
	},
	GET_CUSTOMIZATION(state) {
		return state.customization
	},
	GET_SHOP_USERS(state) {
		return state.shopUsers
	},
	GET_SHOP_CUSTOMERS(state) {
		return state.shopCustomers
	},
	GET_SHOP_CUSTOMERS_DRAWER(state) {
		return state.shopCustomersDrawer
	},
	GET_SHOP_CUSTOMERS_DRAWER_SETTINGS(state) {
		return state.shopCustomersDrawerSetting
	},
	GET_APPLICATION_LOGS(state) {
		return state.applicationLog
	},
	GET_ALL_CUSTOMERS(state) {
		return state.allCustomers
	},
	GET_SHOP_CUSTOMERS_CHECKED(state) {
		if (state.shopCustomers.data) {
			const filterChecked = state.shopCustomers.data.filter(item => item.checked === true)
			if (filterChecked.length > 0) {
				return {status: true, data: filterChecked}
			} else {
				return {status: false, data: []}
			}
		}
	},
	GET_DASHBOARD_METRICS(state) {
		return state.dashboardMetrics
	},
	GET_SHOPOWNER_TOUR(state) {
		return state.shopOwnerTour
	},
	GET_BLOG_CATEGORY(state) {
		return state.blogCategory
	},
	GET_BLOG_TAG(state) {
		return state.blogTag
	},
	GET_BLOG_POST(state) {
		return state.blogPost
	},
	GET_SINGLE_BLOG_POST(state) {
		return state.singleBlogPost
	},
	GET_CATEGORY_CHECKED(state) {
		if (state.blogCategory) {
			const filter = state.blogCategory.filter(item => item.checked === true)
			if (filter.length > 0) {
				return {state: true, data: filter}
			} else {
				return {status: false, data: []}
			}
		}
	},
	GET_CHECKED_BLOG_POST(state) {
		return state.blogPost.data.filter(item => item.checked === true)
	},
	GET_BLOG_POST_PREVIEW(state) {
		return state.blogPreview
	},
	GET_BLOG_POST_IMAGE(state) {
		return state.blogImage
	},
	GET_FAQ(state) {
		return state.faqPost
	},
	GET_FAQ_CATEGORY(state) {
		return state.faqPostCategory
	},
	GET_COMPANY_CHECKOUT_CUSTOMIZER(state) {
		return state.companyCheckoutCustomizer
	},
	GET_COMPANY_SUBSCRIPTION_SETTING(state) {
		if (state.companySubscriptionSettings !== null) {
			return state.companySubscriptionSettings
		} else {
			return {
				seller_login_description: '',
				english_as_default: false,
				full_logo: '',
				half_logo: '',
				brand_name: '',
				link_color: '',
				menu_color: null,
				button_color: null,
			}
		}

	},
	GET_ARTICLE_CATEGORY_STRUCTURE(state) {
		return state.articleCategoryStructure
	},
	GET_ARTICLE_ACTIVE_FILTER(state) {
		return state.articleFilter
	},
	GET_ARTICLE_PRODUCT_PRICE_FILTER(state) {
		return state.productPriceFilter
	},
	GET_ORDER_FILTER(state) {
		return state.orderFilter
	},
	GET_CUSTOMER_FILTER(state) {
		return state.customerFilter
	},
	GET_UNIT_TYPE(state) {
		return state.unitTypeObj
	},
	GET_ALL_ARTICLES(state) {
		return state.allArticles
	},
	GET_ALL_ACTIVE_ACCOUNTS(state) {
		return state.allActiveAccounts
	},
	GET_ALL_COST_CENTER(state) {
		return state.allCostCenters
	},
	GET_ALL_NOTIFICATION(state) {
		return state.allNotification
	},
}
