import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {getToken} from './../services-jwt'
import {getCompanyId, getBuyerId} from "../storage-window";

Vue.use(VueAxios, axios)

const http = Vue.axios.create({
	baseURL: process.env.VUE_APP_BASE_URL_EXTERNAL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
})


http.interceptors.request.use(
	function (req) {
		const token = `${getToken()}`
		if (token) {
			req.headers.common.Authorization = `Token ${token}`
		}
		try {
			if (window.location.pathname.includes('/portal')) {
				if (getCompanyId()) {
					if (req.url.includes('?') === false) {
						if (req.url.includes('company_id') === false) {
							req.url += `?company_id=${getCompanyId()}`
						}
					} else {
						if (req.url.includes('company_id') === false) {
							req.url += `&company_id=${getCompanyId()}`
						}
					}
				}
			}
		} catch (e) {
		}
		return req
	},
	function (err) {
		// console.clear()
		return Promise.reject(err)
	}
)


export default http

