<template>
	<div class="dm-sans-font bg-white">
		<div class="flex justify-between text-dark cart-slider-header mb-2 border-b">
					<span class="menu-header-subtitle mb-2 font-semibold text-base" v-if="CART_NUMBER !== null">{{
							$t('youHave')
						}} (<b>{{ CART_NUMBER }}</b>) {{ $t('itemsInCart') }}</span>
			<span class="cursor-pointer relative" v-on:click="closeSlider">
						<i class="el-icon-close absolute top-2 right-1"></i>
					</span>

		</div>
		<div class="mt-2">
			<div class="cp-listing-loop" :class="CART_NUMBER > 0 ? 'notify-height' : ''"
				 id="extend-height">
				<CartProduct/>
			</div>
			<div class="cp-listing-loop" v-if="CART_NUMBER === 0 || CART_NUMBER === null">

			</div>
		</div>
	</div>
</template>

<script>
import CartProduct from "../../../../customComponent/CartProduct";
import {mapGetters} from "vuex"

export default {
	name: "cartComponent",
	props: {},
	components: {
		CartProduct
	},
	computed: {
		...mapGetters({
			CART_NUMBER: 'customer/CART_NUMBER',
		})
	},
	methods: {
		closeSlider: function () {
			window.Bus.$emit('close-cart-slider')
		}
	}
}
</script>

<style scoped>

</style>
