<template>
	<span @mouseover="openDelete = true" @mouseleave="openDelete = false" class="position-relative">
	<span @click="toggleName" v-if="!updateName">{{ item.name }}</span>
            <input :value="item.name" @keypress.enter="updateNameData($event, item)"
				   @change="updateNameData($event, item)" class="focus:outline-none z-50 relative"
				   v-else/>
		<i class="fa fa-times text-danger delete-icon cursor-pointer" v-if="openDelete === true"
		   v-on:click="deleteItem"></i>
	</span>
</template>

<script>
export default {
	name: "ArticleChildCategory",
	props: {
		childCategory: {
			type: Object
		},
		categoryId: {
			type: Number
		}
	},
	data: function () {
		return {
			item: {},
			updateName: false,
			openDelete: false
		}
	},
	mounted() {
		this.item = this.childCategory
	},
	methods: {
		toggleName: function () {
			this.updateName = !this.updateName
		},
		updateNameData: function (e) {
			if (e.target.value !== this.item.name) {
				this.$store.dispatch('portal/updateCategoryStructure', {
					id: this.item.id,
					data: {name: e.target.value, category: this.categoryId}
				}).then(_ => {
					this.item.name = e.target.value
				}).catch(err => {

				})
			}

			this.updateName = false
		},
		deleteItem: function () {
			this.$confirm(`${this.$t('removeChildCategory')} ${this.item.name}?`, `${this.$t('warning')}`, {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('cancel'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true
						instance.confirmButtonText = 'Loading...'
						this.$store.dispatch('portal/deleteCategoryStructure', {id: this.item.id}).then(_ => {
							instance.confirmButtonLoading = false
							done()
							window.Bus.$emit('remove-child-category', {id: this.item.id, category: this.categoryId})
						}).catch(error => {
							done()
							instance.confirmButtonLoading = false
							if (error.response.status === 400 || error.response.status === 500) {
								this.$services.helpers.notification(this.$t('serverError'), 'error', this)
							}
						})
					} else {
						done()
						instance.confirmButtonLoading = false
					}
				}
			}).then(() => {
				window.Bus.$emit('remove-faq', {id: this.data.id})
			})
		}
	}
}
</script>

<style scoped>
.delete-icon {
	font-size: 10px;
	position: absolute;
	top: -6px;
	right: -5px;
}
</style>
