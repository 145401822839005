<template>
    <div class="app-container app-theme-white body-tabs-shadow">
        <div class="app-container dm-sans-font overflow-x-hidden">
            <div class="app-container">
                <div class="d-flex">
                    <div class="text-center mt-4">
                        <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/logo/web.png" alt="logo">
                    </div>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8 mb-5 mt-10">
                            <div class="flex flex-col text-center mt-10 text-gray-900 font-semibold mb-10 p-2">
                                <div class="theme-main-size  md:mb-12">{{ $t('proceedText') }}</div>
                                <br><br>
                                <span class="mt-5 flex flex-col md:flex-row space-y-4 md:space-y-0 justify-between md:space-x-20 w-full">
                                    <router-link :to="{ name : 'onboardsubcription' }" class="__el__center bg-yellow-600 text-white no-deco px-4 py-2 rounded shadow-sm text-xl md:w-1/2" :disabled="processing">
                                        <span class="m-auto">{{ $t('onBoardSeller') }}</span>
                                    </router-link>
<!--                                    <router-link :to="{ name : 'buyerregister' }" class="btn-custom no-deco px-4 py-2 rounded shadow-sm text-xl md:w-1/2" :disabled="processing">-->
<!--                                        <span class="m-auto">{{ $t('onBoardBuyer') }}</span>-->
<!--                                    </router-link>-->
                                </span>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
                <div class="absolute bottom-0 w-full">
                    <div class="text-center py-10">
                        <span class="text-center">Copyright © <span>{{ current }} B2B Portal</span></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <Notification /> -->
    </div>
</template>

<script>
export default {
    name : 'ProceedInstall',
    data () {
        return {
            current : new Date().getFullYear(),
            baseURL : window.location.origin,
            processing : false,
        }
    },
    mounted () {
        const entryAuth = window.localStorage.getItem('__entryAuth__')

        if (!entryAuth) {
            // this.$router.push({ name : 'welcome' })
        }
    }
}
</script>

<style scoped>
.__el__center {
    margin: auto;
    display: table;
}
</style>
