<template>
	<div class="bg-gray-900 flex flex-col shopowner-app-sidebar app-sidebar sidebar-shadow"
		 :style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.menu ? 'background:'+GET_CUSTOMIZATION.menu : 'background: #181C4C'">
		<div data-cy='logo' class="flex justify-center mb-3" v-if="systemMode === 'whitelabel'">
			<img class="b2b-logo" :src="GET_COMPANY_SUBSCRIPTION_SETTING.half_logo"
				 :alt="GET_COMPANY_SUBSCRIPTION_SETTING.brand_name" style="height: 50px;width: auto">
		</div>
		<div data-cy='logo' class="flex justify-center mb-3" v-else>
			<v-lazy-image class="b2b-logo" :src="imageLogo" alt=""/>
		</div>
		<div class="flex justify-center font-semibold text-white text-xl" v-else>
			B2B Portal
		</div>
		<div class="app-header__logo bg-gray-900">
			<div class="logo-srcs"></div>
			<div class="header__pane ml-auto">
				<div>
					<button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
							data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
					</button>
				</div>
			</div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
            <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
		</div>
		<div class="scrollbar-sidebar">
			<div class="app-sidebar__inner flex items-center">
				<ul class="vertical-nav-menu overflow-x-hidden">
					<li class="menu-link mm-active v-step-0">
						<router-link data-cy='dash-shopowner' :to="{ name : 'admin-dashboard' }">
							<span class="flex align-items-center custom-left absolute" style="left: 15px"><img
								class="h-3 mr-3" src="./../../../assets/images/icons/dashboard_2.png"
								alt=""> {{ $t('dashboard') }}</span>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import logoImage from './../../../assets/images/logo/old-web.png'
import VLazyImage from "v-lazy-image/v2"

export default {
	name: "SideBar",
	components: {VLazyImage},
	data() {
		return {
			baseURL: window.location.origin,
			userSubscription: [],
			userObject: null,
			showSubscription: false,
			showBlogMenu: false,
			systemMode: '',
			imageLogo: logoImage
		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
			GET_COMPANY_SUBSCRIPTION_SETTING: 'portal/GET_COMPANY_SUBSCRIPTION_SETTING'
		})
	},
	mounted() {
		window.Bus.$on('update-company-images', e => {
			if (process.env.VUE_APP_SYSTEM_MODE === 'whitelabel') {
				this.$store.dispatch('auth/getCompanySetup', {company_id: process.env.VUE_APP_COMPANY_ID}).then(resp => {
					this.$store.commit('portal/UPDATE_COMPANY_SUBSCRIPTION_SETTING', resp.data.data)
				})
			}
		})
	},
	beforeMount() {
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE
		if (process.env.VUE_APP_SYSTEM_MODE === 'whitelabel') {
			this.$store.dispatch('auth/getCompanySetup', {company_id: process.env.VUE_APP_COMPANY_ID}).then(resp => {
				this.$store.commit('portal/UPDATE_COMPANY_SUBSCRIPTION_SETTING', resp.data.data)
			})
		}
	},
	methods: {
		openBlogMenu() {
			this.showBlogMenu = !this.showBlogMenu
			if (this.showBlogMenu) {
				document.getElementById('sub_id').classList.add('mm-show')
				// document.getElementById('sub_id').classList.add('override-height')
				// document.getElementById('top-side-to-scroll').classList.add('overflow-y-scroll')
			} else {
				document.getElementById('sub_id').classList.remove('mm-show')
				// document.getElementById('top-side-to-scroll').classList.remove('overflow-y-scroll')
			}
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables";

.app-sidebar .app-sidebar__inner {
	padding: unset !important;
}

.sidebar-bg-custom {
	background: $production-theme;
	border-right: 1px solid #344258;
}

.app-sidebar__heading {
	// color: #ffffff !important;
	// font-size: 0.6rem;
}

.vertical-nav-menu li.mm-active > a {
	// color: #bfc3ce !important;
	font-size: 0.8rem;
	font-weight: unset !important;
}

.vertical-nav-menu li a {
	// color: #bfc3ce !important;
	font-size: 0.8rem;
}

.vertical-nav-menu li.menu-link:hover {
	border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: #2e346c !important;
	opacity: 0.5;
}

.vertical-nav-menu ul > li > a {
	// color: #bfc3ce !important;
	font-size: 0.8rem;
	transition: all .3s ease;
}

.vertical-nav-menu li a {
	line-height: 2.8rem !important;
	height: 2.8rem !important;
}

.vertical-nav-menu li a:hover:not(.router-link-exact-active) {
	background: unset !important;
	text-decoration: none;
	margin-left: 13px;

	i.metismenu-state-icon {
		margin-left: 10px;
		position: sticky;
	}
}

.vertical-nav-menu i.metismenu-icon {
	opacity: unset !important;
}

.vertical-nav-menu ul {
	transition: padding 300ms;
	padding: .5em 0 0 0.6rem !important;
}

.router-link-exact-active {
	// border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: #2e346c !important;
	// background: #9FA2B4 !important;
	color: #FFFFFF !important;
	opacity: 0.9;
	left: 11px;
}

// .subscription-section li .router-link-exact-active{
//     color: #bfc3ce !important;
//     font-size: 0.8rem;
//     font-weight: bold;
// }
.mr-13 {
	margin-left: 13px;
}

.vertical-nav-menu ul > li > a {
	color: #d7d7d8 !important;
}
</style>
