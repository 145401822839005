import {saveToken, saveCusToken, destroyToken, destroyCusToken} from './../../../../services/services-jwt'
import {saveLocale, saveCompanyInfo, saveCompany} from './../../../../services/storage-window'

export default {
	UPDATE_USER_TOKEN(state, payload) {
		state.token = payload.token
		saveToken(payload.token)
		saveCompany(payload.company_id)
	},
	CLEAR_AUTH_USER(state, payload) {
		state.token = payload
		saveLocale('se')
		destroyToken()
	},
	UPDATE_CUS_TOKEN(state, payload) {
		state.cusToken = payload.token
		saveCusToken(payload.token)
	},
	UPDATE_USER_PROFILE(state, payload) {
		state.user = payload
	},
	CLEAR_AUTH_CUS(state, payload) {
		state.cusToken = payload
		saveLocale('se')
		saveCompanyInfo({})
		saveCompany('')
		destroyCusToken()
	}
	// UPDATE_FAQ_DATA (state, payload) {
	//     state.faqData = payload
	//     state.copyFaqData = payload
	// },
	// UPDATE_FAQ_CATEGORY (state, payload) {
	//     state.faqCategory = payload
	//     state.copyFaqCategory = payload
	//     if (state.faqData.length > 0) {
	//         state.faqCategory.forEach(element => {
	//             const objct = state.faqData.filter(item => item.category === element.id)
	//             element.subLink = objct
	//         })
	//     }
	// },
	// FILTER_CATEGORY (state, payload) {
	//     // let filterFaq = []
	//     // const filterCategory = state.copyFaqCategory.filter(item =>
	//     //     item.category_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
	//     // )
	//     const filterFaq = state.copyFaqData.filter(item =>
	//         item.question.toLowerCase().includes(`${payload.value.toLowerCase()}`)
	//     )

	//     if (filterFaq.length < 1) {
	//         const filterCategory = state.copyFaqCategory.filter(item =>
	//             item.category_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
	//         )
	//         // console.log('maian:', filterCategory.length)
	//         if (filterCategory.length > 0) {
	//             state.faqCategory = filterCategory
	//             const filterq = state.copyFaqData.filter(item => item.category === filterCategory[0].id)
	//             // console.log('fil;', filterq)
	//             state.faqData = filterq
	//             // console.log('state data:', state.faqData)
	//         } else {
	//             state.faqCategory = state.copyFaqCategory
	//         }
	//     } else {
	//         state.faqCategory = state.copyFaqCategory
	//     }
	//     // if (filterCategory.length > 0) {
	//     //     filterFaq = state.copyFaqData.filter(item => item.category === filterCategory[0].id)
	//     // }
	//     // state.faqCategory = filterCategory
	//     // state.faqData = filterFaq
	//     state.faqData = filterFaq
	// },
	// SET_FAQ_LIST (state, payload) {
	//     const filterList = state.copyFaqData.filter(item => item.id === payload.id)
	//     state.faqData = filterList
	// },
	// CATEGORY_FAQ_ALL (state, _) {
	//     state.faqCategory = state.copyFaqCategory
	//     state.faqData = state.copyFaqData
	// },
	// UPDATE_APP_INTEGRATION (state, payload) {
	//     const appFilter = []
	//     state.appIntegration = payload
	//     state.copyAppIntegration = payload
	//     payload.forEach(element => {
	//         if (!appFilter.includes(element.name)) {
	//             appFilter.push(element.name)
	//         }
	//     })
	//     state.appFilterState = appFilter
	// },
	// SUBSCRIPTION_ALL (state, payload) {
	//     state.allSubscription = payload
	// },
	// FILTER_WEBAPPS (state, payload) {
	//     const filterApp = state.copyAppIntegration.filter(item =>
	//         parseInt(item.accounting[0]) === parseInt(payload.value)
	//     )
	//     state.appIntegration = filterApp
	// },
	// GET_FILTER_APP_SELECT (state, payload) {
	//     const filterApp = state.copyAppIntegration.filter(item =>
	//         item.name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
	//     )
	//     state.appIntegration = filterApp
	// },
	// GET_ALL_APPS (state, _) {
	//     state.appIntegration = state.copyAppIntegration
	// },
	// SET_USER_COMPANY_ID (state, payload) {
	//     state.userCompanyID = payload
	//     setUserCompany(payload, 'company')
	// },
	// SET_USER_FIRM_ID (state, payload) {
	//     state.userFirmID = payload
	//     setUserCompany(payload, 'Firm')
	// },
	// SET_USER_COMPANY_BILLING (state, payload) {
	//     state.companyBilling = payload
	// },
	// SET_USER_COMPANY_PAYMENT_TYPE (state, payload) {
	//     state.setPaymentType = payload
	// },

}
