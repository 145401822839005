<template>
	<div class="category-header bg-white">
		<div class="flex justify-content-between">
			<div class="flex w-full">
				<p
					class="mb-0 text-base font-normal col-span-1 ml-3 w-full"
					id="category_name"
				>
					<edit-article-category :category="dataObj" />
					<i
						class="fa fa-times text-danger"
						v-if="showCatAction === true"
					></i>
				</p>
			</div>
		</div>
		<div class="collapse show">
			<div
				class="category-sub-header"
				v-for="(item, key) in dataObj.child_node"
				:key="key"
				:class="{
					'border-bottom': key + 1 < dataObj.child_node.length,
				}"
			>
				<div class="subcategory">
					<span
						><article-child-category
							:child-category="item"
							:category-id="dataObj.id"
						/><i
							class="ml-1 mr-1 not-italic"
							v-if="item.subcategory.length > 0"
						>
							>>
						</i>
					</span>
					<span
						class="font-normal cursor-pointer mr-2"
						v-for="(sub, k) in item.subcategory"
						:key="k"
						><sub-category-component
							:subcategory="sub"
							:child-category="item.id"
						/><i
							class="ml-1 mr-1 not-italic"
							v-if="k + 1 !== item.subcategory.length"
						>
							>></i
						>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SubCategoryComponent from "./SubCategoryComponent";
import ArticleChildCategory from "./ArticleChildCategory";
import EditArticleCategory from "./categoryTreeComponent/EditArticleCategory";

export default {
	name: "EditComponentTree",
	components: {
		SubCategoryComponent,
		ArticleChildCategory,
		EditArticleCategory,
	},
	props: {
		category: {
			type: Object,
		},
	},
	data: function () {
		return {
			dataObj: {},
			showCatAction: false,
		};
	},
	mounted() {
		this.dataObj = this.category;
	},
	methods: {
		editCatName: function () {},
	},
};
</script>

<style scoped>
/*.category-header {*/
/*	padding-top: 7px;*/
/*	padding-bottom: 7px;*/
/*	padding-left: 13px;*/
/*}*/

.category-sub-header {
	padding-top: 12px;
	padding-bottom: 7px;
	padding-left: 31px;
}

.border-bottom {
	border-bottom: 1px solid rgb(243 244 246) !important;
}

.span-icon {
	font-size: 16px;
	position: relative;
	top: 3px;
}
</style>
