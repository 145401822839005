<template>
	<form>
		<div>
			<div class="form-group unset-margin">
				<label
					class="col-form-label font-bold whitespace-nowrap text-xs">{{
						$t('articleName')
					}}</label>
				<div>
					<input v-model="articleSearchName" type="text" @input="filterArticle"
						   @focus="openCustomersDropdown = true"
						   :placeholder="$t('searchArticles')"
						   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
				</div>
				<div style='z-index:99999'
					 class="w-1/2 absolute bg-white shadow border rounded mt-1"
					 v-if="openArticleDropdown && filteredArticle.length > 0">
					<ul>
						<li class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
							v-for="item in filteredArticle.slice(0, 7)" :key="item.id"
							@click="addArticleToList(item)">{{ item.description }}
						</li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6 mb-1 mt-2" v-for="(article,key) in selectedArticle"
					 :key="article.id">
					<div class="flex justify-between bg-white border rounded-md shadow-sm p-1">
						<div>{{ article.description }}</div>
						<div>
                                                <span class="cursor-pointer"
													  @click="removeUserFromSelectedList(key)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
														 class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														 viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd"
														  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														  clip-rule="evenodd"/>
                                                    </svg>
                                                </span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group flex space-x-5 mt-40">
			<button type="submit" :disabled="addingRelevant"
					:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
					:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-blue-900'"
					class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full">
                                    <span v-if="addingRelevant" class="flex align-items-center space-x-3">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('adding') }}
                                    </span>
				<span v-else>{{ $t('addBtn') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
import {mapGetters} from "vuex"

export default {
	name: "linkedProduct",
	data: function () {
		return {
			addingRelevant: false,
			openArticleDropdown: false,
			articleSearchName: '',
			filteredArticle: [],
			selectedArticle: [],
		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
		})
	},
	methods: {
		filterArticle: function () {
			this.$store.dispatch('portal/getArticles', {url: `?limit=50&search=${this.articleSearchName}`})
				.then(res => {
					this.filteredArticle = res.data.data
					this.openArticleDropdown = true
				}).catch((err) => {
				window.Bus.$emit('output-error', err)
			})
		},
		addArticleToList: function (item) {
			let exist = this.selectedArticle.filter(item_ => parseInt(item.id) === parseInt(item_.id))

			if (exist.length < 1) {
				this.selectedArticle.push(item)
			}
			this.openArticleDropdown = false
		},
		removeUserFromSelectedList: function (key) {
			this.selectedArticle.splice(key, 1)
		}
	}
}
</script>

<style scoped>

</style>
