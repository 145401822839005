<template>

	<div class=" flex">
		<span :class="`increase-button cursor-pointer rounded-l-md w-${width} h-${height}`"
			  v-on:click="onChangeInput('decrease')">
						<i class="el-icon-minus left-icon"></i>
					</span>

		<input type="text" :class="`input-el cart-input w-${inputWidth} h-${inputHeight}`" autocomplete="off"
			   @keyup="onInput"
			   max="Infinity" min="0"
			   role="spinbutton" aria-valuemax="Infinity" aria-valuemin="0"
			   aria-valuenow="0" aria-disabled="false" v-model="value"/>
		<span :class="`increase-button cursor-pointer w-${width} h-${height}`" v-on:click="onChangeInput('increase')"><i
			class="el-icon-plus right-icon"></i></span>

	</div>
</template>

<script>
export default {
	name: "inputNumber",
	props: {
		num: {
			type: Number,
			required: true
		},
		cartNumber: {
			type: Number,
			default: null,
			required: false
		},
		width: {
			type: Number,
			default: 6,

		},
		height: {
			type: Number,
			default: 9,

		},
		inputWidth: {
			type: Number,
			default: 10,
		},
		inputHeight: {
			type: Number,
			default: 9,
		},
		mode: {
			type: String,
			default: 'product-display'
		}
	},
	data: function () {
		return {
			value: 0
		}
	},
	mounted() {
		this.value = this.num
		window.Bus.$on('revert-input-to-zero', e => {
			if (this.mode === 'product-display') {
				this.value = 0
			}
		})
	},
	methods: {
		onChangeInput(mode) {
			let value = parseInt(this.value)
			if (value < 0) {
				value = Math.abs(parseInt(value))
			}
			if (mode === 'increase') {
				value += 1
			} else {
				if (value < 0) {
					value = Math.abs(parseInt(value))
				} else if (value === 0) {
					value = 0
				}
				if (value !== 1 && value > 1) {
					value -= 1
				} else if (value === 1 || value === 0) {
					value = 0
				} else {
					value = 1
				}

			}
			this.value = value
			this.$emit('onchange', value)
		},
		onInput() {
			let value = this.value
			if (value < 0) {
				value = Math.abs(parseInt(this.value))
			}
			if (isNaN(value) === true) {
				this.value = 1
				this.$emit('onchange', 1)
			}
			this.value = value
			this.$emit('onchange', Math.abs(parseInt(this.value)))
		}

	},
	watch: {
	}

}
</script>

<style scoped>
.input-el {
	border: 1px solid #f1eded;
	/*width: 54px;*/
	/*height: 32px;*/
	text-align: center;
}

.increase-button {
	/*width: 28px;*/
	font-size: 12px;
	background: #f5f7fa;
	/*height: 32px;*/
	padding: 8px;
	border: 1px solid #f5f7fa;
	border-radius: 0px;
}

.cart-input:focus-visible {
	border: 1px solid #f5f7fa !important;
	outline: 1px solid #f5f7fa !important;;
}

.left-icon {
	position: relative;
	right: 2px;
}

.right-icon {
	position: relative;
	right: 3px;
}

/*.cart-input:focus {*/
/*	background-color: #f5f7fa !important;*/
/*}*/
</style>
