<template>
	<div class="app-header header-shadow roboto-font">
		<div
			class="app-header__logo nav-header-side"
			:style="
				GET_CUSTOMIZATION && GET_CUSTOMIZATION.menu
					? 'background:' + GET_CUSTOMIZATION.menu
					: 'background: #181C4C'
			"
		>
			<div class="logo-sr"></div>
			<div class="header__pane ml-auto"></div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button
					type="button"
					id="hamburger-btn"
					@click="toggleSidebar"
					class="hamburger hamburger--elastic mobile-toggle-nav"
				>
					<span class="hamburger-box">
						<span class="hamburger-inner"></span>
					</span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
			<span>
				<button
					type="button"
					id="top-wrapper"
					class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
					@click="toggleNavTop"
				>
					<span class="btn-icon-wrapper">
						<i class="fa fa-ellipsis-v fa-w-6"></i>
					</span>
				</button>
			</span>
		</div>
		<div class="app-header__content" id="div-to-open">
			<div class="app-header-left">
				<ul class="header-megamenu nav"></ul>
			</div>
			<div class="app-header-right">
				<div class="header-dots">
					<div
						class="absolute bg-red-30 inset-0 z-0"
						@click="notifyOpen = false"
						v-if="notifyOpen"
					></div>
					<div class="dropdown md:w-16">
						<button
							data-cy="notify-btn"
							type="button"
							@click="openNotifyDiv"
							class="p-0 btn btn-link no-deco"
						>
							<!--aria-haspopup="truei" aria-expanded="falsei" data-toggle="dropdowni"-->
							<div class="flex align-items-center space-x-4">
								<span>
									<span
										class="icon-wrapper icon-wrapper-alt rounded-circle"
									>
										<span
											class="icon-wrapper-bg border-2 border-gray-900"
										></span>
										<img
											src="./../assets/images/icons/notification.png"
											alt=""
										/>
										<span
											class="flex justify-center align-items-center text-xs icon text-white bg-yellow-500 rounded-full h-4 w-8 p-2 absolute ml-4 -mt-3 z-50"
											style="font-size: 9.5px !important"
											>{{ unReadNotificationCount }}</span
										>
									</span>
								</span>
							</div>
						</button>
						<div
							v-if="notifyOpen"
							data-cy="notify-opener"
							class="w-60 width-div-mobile fixed md:absolute z-50 shadow bg-white border rounded mt-10 p-3 md:p-5 right-md"
						>
							<div>
								<div class="flex flex-col">
									<div class="flex justify-center">
										<div class="w-36"></div>
									</div>
									<div
										class="text-center menu-header-content text-dark flex justify-content-between"
									>
										<div>
											<h5
												class="text-sm menu-header-title"
												v-if="
													unReadNotificationCount ===
													0
												"
											>
												{{ $t("noNotify") }}
											</h5>
											<h5
												class="text-sm menu-header-title"
												v-else
											>
												{{ $t("notify") }}
											</h5>
										</div>
										<div class="flex">
											<span
												class="cursor-pointer font-semibold mr-1"
												v-on:click="markAllNotification"
												v-if="
													unReadNotificationCount > 0
												"
												>{{ $t("markAll") }}</span
											>
											<span
												class="cursor-pointer font-semibold"
												v-on:click="openNotificationUrl"
												>{{ $t("viewAll") }}</span
											>
										</div>
									</div>
								</div>
								<div
									v-if="
										GET_NOTIFICATION &&
										unReadNotificationCount > 0
									"
									class="mt-3 overflow-y-scroll"
									:class="
										GET_NOTIFICATION.data.length > 3
											? 'notify-height'
											: ''
									"
								>
									<div
										class=""
										v-for="(
											notify, i
										) in GET_NOTIFICATION.data"
										:key="i"
									>
										<NotificationHandler :notify="notify" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="dropdown-side-top dropdown md:w-32">
						<locale-component />
					</div>
				</div>
				<div class="header-btn-lg pr-0">
					<div class="widget-content p-0">
						<div class="widget-content-wrapper">
							<div class="widget-content-left">
								<div class="btn-group">
									<a
										data-cy="user-company"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										class="p-0 btn d-flex align-items-center"
									>
										<p
											class="h-10 w-10 bg-gray-300 animate-pulse rounded-full"
											v-if="loadingAuth"
										></p>
										<div
											class="flex justify-center align-items-center no-profile-img"
											:style="'background:' + rcolor"
											v-if="
												!loadingAuth &&
												GET_COMPANY_USER.company_logo ===
													null
											"
										>
											<div>
												<span
													class="font-black"
													v-if="
														GET_COMPANY_USER.name !==
														undefined
													"
												>
													{{
														GET_COMPANY_USER.name
															| initialNames
													}}
												</span>
											</div>
										</div>
										<img
											class="rounded-circle mr-1"
											:src="GET_COMPANY_USER.company_logo"
											alt="b2bportal | preview"
											width="42"
											v-if="
												!loadingCompany &&
												GET_COMPANY_USER.company_logo
											"
										/>
										<p
											class="bg-gray-300 w-40 h-4 rounded ml-2 animate-pulse"
											v-if="loadingCompany"
										></p>
										<div
											class="header-user-name ml-2 hidden md:block link-theme-color font-semibold"
											v-else
										>
											<span
												v-if="
													GET_COMPANY_USER.name !==
													undefined
												"
												>{{
													GET_COMPANY_USER.name
														| capitalizeEachString
												}}</span
											>
										</div>
										<i
											class="icon ion-ios-arrow-down ml-2 opacity-8"
										></i>
									</a>
									<div
										data-cy="company-panel"
										tabindex="-1"
										role="menu"
										aria-hidden="true"
										class="rm-pointers tweak-style dropdown-menu-lg dropdown-menu dropdown-menu-right"
									>
										<div
											class="scroll-area-xs override-height"
										>
											<!--style="height: 150px;"-->
											<div class="scrollbar-container ps">
												<ul class="nav flex-column">
													<li class="nav-item">
														<a
															href="javascript:void(0);"
															@click="logoutUser"
															class="text-sm link-theme-color nav-link"
														>
															<span
																class="text-xl icon-color mr-2 mt-1 mb-1 iconly-brokenLogout"
															></span>
															{{ $t("logout") }}
														</a>
													</li>
												</ul>
											</div>
										</div>
										<ul class="nav flex-column">
											<li
												class="nav-item-divider nav-item"
											></li>
											<li
												class="nav-item-btn text-center nav-item"
											>
												Version 1.2.0
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import bus from "./../bus";
import NotificationHandler from "./../customComponent/NotificationHandler";
import { getCompanyId } from "../services/storage-window";
import LocaleComponent from "./LocaleComponent";

export default {
	name: "navbar-dash",
	components: {
		NotificationHandler,
		LocaleComponent,
	},
	data() {
		return {
			baseURL: window.location.origin,
			user: {},
			authUser: {},
			notification: [],
			ownerCompany: null,
			notifyOpen: false,
			company: [],
			notify_count: 0,
			unread_count: 0,
			rcolor: window.localStorage.getItem("randomc") || "#000000",
			unread_notify: false,
			loadingCompany: true,
			loadingAuth: true,
			unReadNotificationCount: 0,
			// menuStyles : menuStyles
		};
	},
	watch: {
		notifyOpen(newVal, oldVal) {
			window.Bus.$emit("show-cover", this.notifyOpen);
		},
	},
	computed: {
		// menuStyleUpdate : function () {
		//     return menuStyles
		// },
		...mapGetters({
			USER_TOKEN: "auth/USER_TOKEN",
			GET_COMPANY_USER: "portal/GET_COMPANY_USER",
			GET_NOTIFICATION: "portal/GET_NOTIFICATION",
			GET_LOCALE_LANG: "utility/GET_LOCALE_LANG",
			GET_CUSTOMIZATION: "portal/GET_CUSTOMIZATION",
		}),
	},
	mounted() {
		this.getAuthUser();
		this.getCompanyUser();
		this.getUserNotification();
		this.getUserUnreadCount();

		bus.$on("update-company-logo", () => {
			this.getCompanyUser();
		});

		window.Bus.$on("reload-notification", (e) => {
			this.getUserNotification();
			this.getUserUnreadCount();
		});

		window.Bus.$on("close-cart", (e) => {
			this.notifyOpen = false;
		});
	},
	methods: {
		openNotificationUrl: function () {
			this.$router.replace({ name: "portal-notifications" });
		},
		markAllNotification: function () {
			this.$store
				.dispatch("portal/readAllNotification")
				.then((res) => {
					window.Bus.$emit("reload-notification");
					this.$services.helpers.notification(
						this.$t("notificationNowMarkAsRead"),
						"success",
						this
					);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
					}
				});
		},
		getUserUnreadCount() {
			this.unReadNotificationCount = 0;
			this.$store
				.dispatch("portal/getAllNotification", {
					data: `?status=unread`,
				})
				.then((resp) => {
					this.unReadNotificationCount = resp.data.total_count;
					if (resp.data.total_count > 900) {
						this.unReadNotificationCount = "900+";
					} else {
						this.unReadNotificationCount = resp.data.total_count;
					}
				});
		},
		getAuthUser() {
			this.loadingAuth = true;
			this.$store
				.dispatch("portal/getAuthUser")
				.then((_) => {
					this.loadingAuth = false;
				})
				.catch((err) => {
					this.loadingAuth = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		getCompanyUser() {
			let URL = "";
			if (getCompanyId()) {
				URL += `?company_id=${getCompanyId()}`;
			}
			this.$store
				.dispatch("portal/getCompanyUser", URL)
				.then((_) => {
					this.loadingCompany = false;
				})
				.catch((err) => {
					this.loadingCompany = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		getUserNotification() {
			this.$store.dispatch("portal/getUserNotification");
		},
		changeLocale(locale) {
			i18n.locale = locale;
			this.$services.moment.locale(locale);
			this.$store.commit("utility/CHANGE_LOCALE", locale);
			window.Bus.$emit("reload-locale-specific-component");
		},
		openNotifyDiv() {
			this.notifyOpen = !this.notifyOpen;
		},
		handleScroll(e) {
			if (parseInt(e.target.scrollTop) > 60) {
				document
					.getElementById("remove-div")
					.classList.add("hide-transition-height");
				document
					.getElementById("extend-height")
					.classList.add("notify-height");
			} else {
				document
					.getElementById("remove-div")
					.classList.remove("hide-transition-height");
				document
					.getElementById("extend-height")
					.classList.remove("notify-height");
			}
		},
		toggleSidebar() {
			document
				.getElementById("hamburger-btn")
				.classList.toggle("is-active");
			document
				.getElementById("app-root-container")
				.classList.toggle("sidebar-mobile-open");
		},
		toggleNavTop() {
			document
				.getElementById("div-to-open")
				.classList.toggle("header-mobile-open");
		},
		logoutUser() {
			this.changeLocale("se");
			this.$store.commit("auth/CLEAR_AUTH_USER", null);
			this.$router.replace({ name: "login" });
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./../assets/variables.scss";

// .app-theme-gray.fixed-header{
//     // .app-header__logo{
//     //     // background: #fff !important;
//     // }
// }
.h-5 {
	height: 3rem;
}

.logo-top {
	margin-top: -0.5rem;
}

.scrollbar-container {
	.nav-item {
		.nav-link {
			.metismenu-icon {
				font-size: 2rem;
			}
		}
	}
}

.header-user-name {
	font-weight: 600;
	font-size: 1.2rem;
}

// .app-header__logo .logo-src {
//     // height: 46px !important;
//     // width: 99px !important;
//     // background-repeat: no-repeat;
//     // margin-left: 4rem;
// }

.nav-item.nav-item-divider {
	margin: unset !important;
}

.dropdown-menu-header {
	margin-bottom: unset !important;
}

.nav-item.nav-item-btn {
	padding: unset !important;
}

.override-height {
	height: unset !important;
}

.skeleton-com-text {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
	// background-size: 800px 104px;
	height: 15px;
	width: 150px;
	border-radius: 5px;
}

.skeleton-img {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
	// background-size: 800px 104px;
	// height: px;
	// width: 45px;
	// border-radius: 5px;
}

.rounded-circle-top:hover {
	background: #fff !important;
	z-index: 999;
}

.change-style {
	padding: 1px;
	border: 1px solid #fff;
}

.tweak-style {
	left: 40px !important;
}

.dropdown-menu.dropdown-menu-lg {
	min-width: 15rem !important;
}

.text-big {
	font-size: 1rem !important;
}

.nav-link {
	padding: 0.1rem 1rem !important;
}

.app-theme-gray .app-header {
	background: #ffff !important;
}

.ps__thumb-y {
	background: #ee4d81 !important;
}

.notify-height {
	height: 500px !important;
	transition: height 1s ease-out;
}

.hide-transition-height {
	height: 0px;
	opacity: 0;
	display: none;
}

.transition-div {
	transition: display 1s ease-out;
}

.dropdown-side-top::before {
	position: absolute;
	left: -1px;
	top: 50%;
	background: #dee2e6;
	width: 1px;
	height: 30px;
	margin-top: -15px;
	content: "";
}

.icon-wrapper img {
	margin: 0 auto;
	// font-size: 1.7rem;
	position: relative;
	z-index: 5;
}

.app-header__logo {
	width: 200px !important;
}
</style>
