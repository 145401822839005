<template>
	<div class="flex flex-col dm-sans-font p-3 mt-3">
		<div class="container flex justify-between mb-1 bg-white section-container">
			<div class="w-full">
				<div class="w-full border-bottom" v-on:click="onOpenCustomerDetail">
					<i class="lnr-chevron-down font-semibold ml-1 icon-size" v-if="openCustomerDetail === false"></i>
					<i class="lnr-chevron-up font-semibold ml-1 icon-size" v-else></i><span
					class="ml-2 font-semibold text-base">{{ $t('billingDetail') }}</span></div>
				<div class="row form-row" v-if="openCustomerDetail === true">
					<div class="col-md-4">
						<div class="form-group w-full ">
							<div style='z-index:99999' class="w-dropdown absolute bg-white shadow border rounded"
								 v-if="openCustomer && filteredCustomer.length > 0">
								<ul class="list-ul">
									<li class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
										v-for="(item, i) in filteredCustomer.slice(0, 5)" :key="i"
										@click="setCustomer(item)">{{ item.customer_name }}
									</li>
								</ul>
							</div>
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('customerName') }}</span>
							<input type="text" v-model="form.customer_name" @input="filterCustomers"
								   class="w-full rounded input-height-41px p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('organizationNumber') }}</span>
							<input type="text" v-model="form.organization_number"
								   class="w-full rounded p-2 input-height-41px border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>
				</div>
				<div class="w-full border-bottom mt-3 mb-20px" v-on:click="onOpenDeliveryAddressDetail">
					<i class="lnr-chevron-down font-semibold ml-1 icon-size" v-if="deliveryAddressDetail === false"></i>
					<i class="lnr-chevron-up font-semibold ml-1 icon-size" v-else></i><span
					class="ml-2 font-semibold text-base">{{ $t('deliveryInformation') }}</span></div>
				<div class="row form-row" v-if="deliveryAddressDetail === true">
					<div class="col-md-4">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3">{{ $t('deliveryName') }}</span>
							<input type="text" v-model="form.delivery_name"
								   class="w-full input-height-41px rounded p-2 mt-1 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('deliveryCountry') }}</span>
							<el-select v-model="form.delivery_country" clearable filterable
									   :placeholder="$t('select')"
									   class="flex justify-between mt-1 align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
								<el-option
									v-for="(item,key) in countries"
									v-bind:key="key"

									:label="item.name"
									:value="item.key">
								</el-option>
							</el-select>
							<!--							<select style="outline: currentcolor none medium;" v-model="form.delivery_country"-->
							<!--									class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">-->
							<!--								<option v-for="(c,k) in countries" :key="k" :value="c.key"> {{ c.name }}</option>-->
							<!--							</select>-->
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('city') }}</span>
							<input type="text" v-model="form.delivery_city"
								   class="w-full rounded input-height-41px mt-1 p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('deliveryZipCode') }}</span>
							<input type="text" v-model="form.delivery_zipcode"
								   class="w-full rounded p-2 input-height-41px mt-1 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('deliveryAddress') }}</span>
							<input type="text" v-model="form.delivery_address"
								   class="w-full rounded p-2 input-height-41px mt-1 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>

				</div>
				<div class="w-full border-bottom mt-3 mb-20px" v-on:click="onOpenOrderDetail">
					<i class="lnr-chevron-down font-semibold ml-1 icon-size" v-if="openOrderDetail === false"></i>
					<i class="lnr-chevron-up font-semibold ml-1 icon-size" v-else></i><span
					class="ml-2 font-semibold text-base">{{ $t('orderDetail') }}</span></div>
				<div class="row form-row" v-if="openOrderDetail === true">
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('invoiceDate') }}</span>
							<input type="date" v-model="form.order_date"
								   class="w-full rounded p-2 input-height-41px border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('dueDate') }}</span>
							<input type="date" v-model="form.due_date"
								   class="w-full rounded p-2 input-height-41px border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('vatIncluded') }}</span>
							<toggle-component :picked="form.vat_included" @onpicked="setVatInclude" class="w-full"/>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('paymentTerms') }}</span>
							<!--							<select-->
							<!--								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"-->
							<!--								v-model="form.payment_terms">-->
							<!--								<option value="">&#45;&#45;</option>-->
							<!--								<option v-for="(cc,key) in paymentTerms" :value="cc.code" :key="key">{{-->
							<!--										cc.description-->
							<!--									}}-->
							<!--								</option>-->
							<!--							</select>-->
							<el-select v-model="form.payment_terms" clearable filterable
									   :placeholder="$t('select')"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
								<el-option
									v-for="(cc,key) in paymentTerms"
									v-bind:key="key"

									:label="cc.description"
									:value="cc.code">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('yourOrderNumber') }}</span>
							<input type="text" v-model="form.your_order_number"
								   class="w-full rounded p-2 border input-height-41px focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('yourReference') }}</span>
							<input type="text" v-model="form.your_reference"
								   class="w-full rounded p-2 border input-height-41px focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('ourReference') }}</span>
							<input type="text" v-model="form.our_reference"
								   class="w-full rounded p-2 border input-height-41px focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
								   style="outline: currentcolor none medium;">
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('priceList') }}</span>
							<!--							<select-->
							<!--								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"-->
							<!--								v-model="form.price_list">-->
							<!--								<option value="">&#45;&#45;</option>-->
							<!--								<option v-for="(cc,key) in priceList" :value="cc.code" :key="key">{{-->
							<!--										cc.description-->
							<!--									}}-->
							<!--								</option>-->
							<!--							</select>-->
							<el-select v-model="form.price_list" clearable filterable
									   :placeholder="$t('select')"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
								<el-option
									v-for="(cc,key) in priceList"
									v-bind:key="key"

									:label="cc.description"
									:value="cc.code">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('currency') }}</span>
							<!--							<select-->
							<!--								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"-->
							<!--								v-model="form.currency">-->
							<!--								<option value="">&#45;&#45;</option>-->
							<!--								<option v-for="(pr,key) in currencies" :value="pr.currency" :key="key">{{-->
							<!--										pr.currency-->
							<!--									}}-->
							<!--								</option>-->
							<!--							</select>-->
							<el-select v-model="form.currency" clearable filterable
									   :placeholder="$t('select')"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
								<el-option
									v-for="(cc,key) in currencies"
									v-bind:key="key"
									:label="cc.currency"
									:value="cc.currency">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('project') }}</span>

							<!--							<select-->
							<!--								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"-->
							<!--								v-model="form.project">-->
							<!--								<option value="">&#45;&#45;</option>-->
							<!--								<option v-for="(pr,key) in projectList" :value="pr.number" :key="key">{{-->
							<!--										pr.description-->
							<!--									}}-->
							<!--								</option>-->
							<!--							</select>-->

							<el-select v-model="form.project"  filterable clearable
									   :placeholder="$t('select')"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
								<el-option
									v-for="(cc,key) in projectList"
									v-bind:key="key"
									:label="cc.description"
									:value="cc.project_number">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('costCenter') }}</span>
<!--							<select-->
<!--								class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"-->
<!--								v-model="form.cost_center">-->
<!--								<option value="">&#45;&#45;</option>-->
<!--								<option v-for="(cc,key) in costCenter" :value="cc.number" :key="key">{{-->
<!--										cc.description-->
<!--									}}-->
<!--								</option>-->
<!--							</select>-->
							<el-select v-model="form.cost_center"  filterable clearable
									   :placeholder="$t('select')"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
								<el-option
									v-for="(cc,key) in costCenter"
									v-bind:key="key"
									:label="cc.description"
									:value="cc.code">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group w-full ">
							<span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('text') }}</span>
							<textarea v-model="form.comments"
									  class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"></textarea>
						</div>
					</div>
				</div>
				<div class="row mt-3">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-6"><span class="font-semibold text-base"></span>
							</div>
							<div class="col-md-6">
								<div class="order-action">
									<div class="w-48">
										<div class="order-type-holder">
											<div
												class="w-50 text-xs"
											>
												<span class="order-type-btn-left font-semibold cursor-pointer"
													  :class="{'active-type':  form.order_type === 'Order'}"
													  v-on:click="form.order_type = 'Order'">{{
														$t('order')
													}}</span>
											</div>
											<div
												class="text-xs w-50"
											>
												<span class="order-type-btn-right font-semibold cursor-pointer"
													  :class="{'active-type':  form.order_type === 'invoice'}"
													  v-on:click="form.order_type = 'invoice'">
													{{
														$t('invoiceType')
													}}
												</span>
											</div>
										</div>
									</div>
									<div>
										<span v-on:click="addRow" style="height: 32px;width: 32px"
											  class="cursor-pointer">
											<img alt="add row" id="image0_1507_294" width="32" height="32"
												 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAAAXNSR0IArs4c6QAAA3dJREFUeF7t3D1Pk1EUB/D/tS2Y+MKq4MBQNEajm8TvwCdQKcRBJ3CEwTjo4MukwUUHQyuyuOp3kA2j0agPmy+zghqblmseE0w1Jbfn9Ob4ePkzNuel9/echec014F/JgLOpAubgNBGQ0BoQhsJGLXhRBPaSMCoDSea0EYCRm040YQ2EjBqw4kuOvT0KHZvVnDReZwBcAzAHqPvbN3mqwNeeofl/R73FjL80HwB1USfreJQ2eEpPE5omv6vOR543gYmHmV4Lz2DGDqfZF/Byk5D3oLNsYeAcelki6FrY7jkPG5Ln2hK8c5jdnENC5IziaGnqlgBcErSJMHYZ/UMpyXn0kCvA9graZJg7EY9wz7JuTTQXtIg1dh6JnuXT2jlJBBaCSdNI7RUTBlPaCWcNI3QUjFlfMrQTedwuVXGUm5TamESHtcADCit+kpLF9phrv4Otzp1alXMOeBGX2LK5GSh2xUML73Gp06X6VEc8OU/P1O6idOShd7uYFNV/JN/oAgtnk1dAqF1buIsQovJdAmE1rmJswgtJtMlEFrnJs4itJhMl0BonZs4i9BiMl0CoXVu4ixCi8l0CYTWuYmzCC0m0yUQWucmzioi9O/NyN/vk8Wni5xw7igOajc1xYPushmJ7NV3Oc2mpnDQ3TYjfctELqDZ1BQOulTCyIM3+BjZJmq52mGMuE3Zb54LB+2B+UaGm1FlIhebrmLeA9clZQsHDaDpgSvlEh4WbbLPH8Fwq41JB1yV/myhiNCSQdk2lsvZKIzhIoQOG0WJIHQUxnARQoeNokQQOgpjuAihw0ZRIggdhTFchNBhoygRhI7CGC5C6LBRlAhCR2EMFyF02ChKBKGjMIaLEDpsFCWC0FEYw0W6bWo0m5Fwp94ikn0f3W1To9mM9MYYjkoWunNTkzNoNyNhwt4iUobuTcAoitCENhIwasOJJrSRgFEbTnRC0LyODfhSzzAkeaaaW8J4wSBgcMHgGGbhcUfyNFOL9R4zjTXclZxLPNEzVQx+BlYccFLSKKHY1W8DGH/8Ck3JmcTQefH8WuMS8GQHYq/6XZhovMUHCXIeq4LOE/PJXve44N2vi7qPJ3xf6QaAF95j+fsg7ksneeuBqKGlT3SnxxPaaAIITWgjAaM2nGhCGwkYteFEE9pIwKgNJ5rQRgJGbTjRhDYSMGrzE6KzlmrUJExFAAAAAElFTkSuQmCC"/>
										</span>

									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12">
						<div class="drawer-category-management table-responsive mt-3 mb-3 rounded-lg bg-white border">
							<table style="width: 100%;overflow-x: auto" id="exampledestroy"
								   class="table table-hover table-bordered">
								<thead>
								<tr>
									<th> {{ $t('articleNumber') }}</th>
									<th>{{ $t('description') }}</th>
									<th>{{ $t('price') }}</th>
									<th>{{ $t('discount') }}</th>
									<th>{{ $t('quantity') }}</th>
									<th>{{ $t('account') }}</th>
									<th>CS</th>
									<th>{{ $t('amount') }}</th>
									<th></th>
								</tr>
								</thead>
								<tbody>
								<order-row-form v-for="(item,key) in form.rows" :item="item" :key="key" :index="key"/>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-md-12">
						<div class="flex justify-center">
							<button :disabled='creating' type="button" v-on:click="createOrder"
									class="flex justify-center align-items-center space-x-4 btn-custom focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                                        <span v-if="creating" class="flex align-items-center space-x-3">
                                            <svg class="animate-spin mr-2 h-5 w-5 text-white"
												 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor"
												  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            {{ $t('pleaseWait') }}
                                        </span>
								<span v-else>{{ $t('createOrder') }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import orderRowForm from "./orderRowForm";
import debounce from "lodash.debounce"
import toggleComponent from "../../../../customComponent/toggleComponent";
import {mapGetters} from "vuex"

export default {
	name: "orderCreateForm",
	components: {orderRowForm, toggleComponent},
	data: function () {
		return {
			openCustomerDetail: true,
			deliveryAddressDetail: false,
			openOrderDetail: false,
			openCustomer: false,
			addNew: false,
			loading: false,
			creating: false,
			filteredCustomer: [],
			vatTypeList: [],
			form: {
				order_type: 'Order',
				customer_name: '',
				customer_number: '',
				organization_number: '',
				delivery_name: '',
				delivery_address: '',
				delivery_zipcode: '',
				delivery_city: '',
				delivery_country: 'SE',
				vat_included: true,
				order_date: '',
				due_date: '',
				your_order_number: '',
				your_reference: '',
				our_reference: '',
				payment_terms: '',
				price_list: '',
				currency: '',
				project: '',
				cost_center: '',
				vat_type: '',
				comments: '',
				rows: []
			},
			countries: [],
			currencies: [],
			projectList: [],
			costCenter: [],
			priceList: [],
			paymentTerms: [],
		}
	},
	methods: {
		onOpenCustomerDetail: function () {
			this.openCustomerDetail = !this.openCustomerDetail
		},
		onOpenDeliveryAddressDetail: function () {
			this.deliveryAddressDetail = !this.deliveryAddressDetail
		},
		onOpenOrderDetail: function () {
			this.openOrderDetail = !this.openOrderDetail
		},
		addRow: function () {
			this.form.rows.push({
				article_number: '',
				account_number: '',
				delivered_quantity: 1,
				cost_center: '',
				description: '',
				price: 0,
				total: 0,
				discount: '',
				discount_type: 'AMOUNT',
			})
		},
		setCustomer: function (item) {
			this.form.customer_name = item.customer_name
			this.form.customer_number = item.customer_number
			this.form.organization_number = this.$services.helpers.cleanData(item.organization_number)
			this.openCustomer = false
		},
		filterCustomers: debounce(function (event) {
			if (event.target.value) {
				let url = `?limit=50&search=${event.target.value}`
				this.$store.dispatch('portal/filterCompanyEndCustomers', {data: url}).then(resp => {
					this.openCustomer = true
					this.filteredCustomer = resp.data.data
				})
			}
		}),
		setVatInclude: function (e) {
			this.form.vat_included = e
		},
		getCostCenter: function () {
			this.$store.dispatch('portal/getCostCenter').then(resp => {
				this.costCenter = resp.data.results
			})
		},
		getCurrency: function () {
			this.$store.dispatch('portal/getCurrency').then(resp => {
				this.currencies = resp.data.results
			})
		},
		getPriceList: function () {
			this.$store.dispatch('portal/getPriceList').then(resp => {
				this.priceList = resp.data.PriceLists
			})
		},
		getProject: function () {
			this.$store.dispatch('portal/getProject').then(resp => {
				this.projectList = resp.data.results
			})
		},
		getPaymentTerms: function () {
			this.$store.dispatch('portal/getPaymentTerms').then(resp => {
				this.paymentTerms = resp.data.results
			})
		},
		createOrder: function () {
			this.creating = true
			let payload = {}
			for (const [key, value] of Object.entries(this.form)) {
				if (['rows'].includes(key) === false) {
					if (value !== '' && value !== null && value !== undefined) {
						payload[key] = value
					}
				}
			}
			if (payload['comments']) {
				payload['remarks'] = payload['comments']
			}
			if (this.form.rows.length <= 0) {
				this.creating = false
				return this.$services.helpers.notification(this.$t('emptyRows'), 'error', this)
			} else {
				let rows = []
				this.form.rows.map(item => {
					let data = {}
					Object.keys(item).map(key => {
						if (item[key] !== '' && item[key] !== null && item[key] !== undefined) {
							data[key] = item[key]
						}
					})
					if (Object.keys(data).length > 0) {
						rows.push(data)
					}

				})
				if (rows.length > 0) {
					payload['rows'] = rows
				} else {
					this.creating = false
					return this.$services.helpers.notification(this.$t('emptyRows'), 'error', this)
				}
			}
			this.$store.dispatch('portal/createOrder', {data: payload}).then(resp => {
				this.creating = false
				if (this.GET_LOCALE === 'se') {
					this.$services.helpers.notification(resp.data.message_swe, 'success', this)
				} else {
					this.$services.helpers.notification(resp.data.message, 'success', this)
				}

				this.form = {
					order_type: 'Order',
					customer_name: '',
					customer_number: '',
					organization_number: '',
					delivery_name: '',
					delivery_address: '',
					delivery_zipcode: '',
					delivery_city: '',
					delivery_country: 'SE',
					vat_included: true,
					invoice_date: '',
					due_date: '',
					your_order_number: '',
					your_reference: '',
					our_reference: '',
					payment_terms: '',
					price_list: '',
					currency: '',
					project: '',
					cost_center: '',
					vat_type: '',
					comments: '',
					rows: []
				}
				this.openCustomerDetail = true
				this.deliveryAddressDetail = false
				this.openOrderDetail = false
				window.Bus.$emit('reload-orders')
			}).catch(err => {
				this.creating = false
				window.Bus.$emit('output-error', err)
			})
		}
	},
	computed: {
		...mapGetters({
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
	mounted() {
		this.getCostCenter()
		this.getPriceList()
		this.getPaymentTerms()
		this.getProject()
		this.getCurrency()
		this.countries = this.$services.helpers.getCountryList()
		window.Bus.$on('remove-row-item', e => {
			this.form.rows.splice(e, 1)
		})
	}
}
</script>

<style scoped>
.form-row {
	margin-top: 13px;
}

.icon-size {
	font-size: 12px !important;
}

.border-bottom {
	border-bottom: 1px solid #d7d7d7;
}



.section-container {
	padding-top: 18px;
}

.order-type-holder {
	background: #333B95;
	border-radius: 5px;
	width: 100%;
	display: flex;
	height: 30px;
}

.order-action {
	display: flex;
	justify-content: flex-end;
}

.active-type {
	border: 1px solid #fff;
	background: #fff;
	color: #201d1d !important;
}

.order-type-btn-left {
	color: #FFFFFF;
	/*border: 1px solid #fff;*/
	/*background: #fff;*/
	padding-left: 20px;
	padding-right: 20px;
	position: absolute;
	top: 6px;
	margin-left: 9px;

	border-radius: 5px;
}

.order-type-btn-right {
	color: #FFFFFF;
	/*border: 1px solid #fff;*/
	/*background: #fff;*/
	padding-left: 20px;
	padding-right: 20px;
	position: absolute;
	top: 6px;
	margin-left: 3px;
	border-radius: 5px;
}

.mb-20px {
	margin-bottom: 20px !important;
}

.list-ul {
	text-align: left;
	padding-left: 5px;
}

.w-dropdown {
	margin-top: 55px !important;
	width: 97%;
}
</style>
