<template>
	<div class="py-2 px-4">
		<div class="absolute inset-0 z-40" @click="openCustomersDropdown = false"
			 v-if="openCustomersDropdown"></div>
		<div class="row dm-sans-font" v-loading="loadingRelevantArticle">
			<div class="col-md-12">
				<form @submit.prevent='setRelevantCustomerArticles'>
					<div>
						<div class="form-group unset-margin">
							<label
								class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('customerName')
								}}</label>
							<div>
								<input v-model="customerSearchName" type="text" @input="filterCustomers"
									   @focus="openCustomersDropdown = true"
									   :placeholder="$t('searchCustomers')"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
							<div style='z-index:99999'
								 class="w-10/12 absolute bg-white shadow border rounded mt-1"
								 v-if="openCustomersDropdown && filteredCustomer.length > 0">
								<ul class="overflow-y-auto h-64">
									<li class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
										v-for="item in filteredCustomer" :key="item.id"
										@click="addCustomerToList(item)">{{ item.customer_name }}
										{{ $services.helpers.cleanData(item.customer_number) }}
									</li>
								</ul>
							</div>
						</div>
						<div class="row">
							<div class="col-md-4 mb-1 mt-2" v-for="customer in selectedCustomers"
								 :key="customer.id">
								<div class="flex justify-between bg-white border rounded-md shadow-sm p-1">
									<div>{{ customer.customer_name }} {{ customer.customer_number }}</div>
									<div>
                                                <span class="cursor-pointer"
													  @click="removeUserFromSelectedList(customer)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
														 class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														 viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd"
														  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														  clip-rule="evenodd"/>
                                                    </svg>
                                                </span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group flex justify-center space-x-5 mt-40">
						<button type="submit" :disabled="addingRelevant"
								:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
								:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-blue-900'"
								class="flex justify-center w-48 align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full">
                                    <span v-if="addingRelevant" class="flex align-items-center space-x-3">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('adding') }}
                                    </span>
							<span v-else>{{ $t('addBtn') }}</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
import {getCompanyId} from "../../../../services/storage-window";

export default {
	name: "assignCustomerToArticle",
	props: {
		article_id: {
			// type: String,
			required: true
		}
	},
	data: function () {
		return {
			openCustomersDropdown: false,
			addingRelevant: false,
			loadingRelevantArticle: false,
			customerSearchName: '',
			filteredCustomer: [],
			selectedCustomers: [],
		}
	},
	methods: {
		addCustomerToList(customer) {
			// Check if customer exist already
			const filter = this.selectedCustomers.filter(item => parseInt(item.id) === parseInt(customer.id))
			if (filter.length < 1) {
				this.selectedCustomers.push(customer)
			}
			this.openCustomersDropdown = false
		},
		setRelevantCustomerArticles() {
			this.addingRelevant = true
			const payload = {
				is_hidden_update: true,
				articles: [],
				customer_ids: []
			}
			if (this.GET_ARTICLES_CHECKED.data.length > 0) {
				this.GET_ARTICLES_CHECKED.data.forEach(element => {
					payload.articles.push({article_id: parseInt(element.id)})
				})
			} else {
				payload.articles.push({article_id: parseInt(this.article_id)})
			}
			let self = this

			if (this.selectedCustomers.length < 1) {
				if (this.GET_ARTICLES_CHECKED.data.length === 0 && this.article_id) {
					this.$confirm(`${self.$t('removeAllCustomerFromArticle')}`, `${self.$t('warning')}`, {
						confirmButtonText: self.$t('yes'),
						cancelButtonText: self.$t('cancel'),
						type: 'warning',
						beforeClose: (action, instance, done) => {
							if (action === 'confirm') {
								instance.confirmButtonLoading = true
								instance.confirmButtonText = ''
								if (getCompanyId()) {
									payload['company_id'] = getCompanyId()
								}
								self.$store.dispatch('portal/updateRelevantBulkData', {data: payload})
									.then(_ => {
										// window.Bus.$emit('fetch-article', 'bulk')
										instance.confirmButtonText = self.$t('yes')
										instance.confirmButtonLoading = false
										done()
										self.$services.helpers.notification(`${self.$t('removeSuccessful')}`, 'success', self)
										self.modalRelevantArticle = false
										self.addingRelevant = false
									}).catch((err) => {
									done()
									self.addingRelevant = false
									instance.confirmButtonLoading = false
									window.Bus.$emit('output-error', err)
								})
							} else {
								done()
								self.addingRelevant = false
							}
						}
					}).then(() => {
						window.Bus.$emit('fetch-users')
					})
						.catch(() => {
							this.addingRelevant = false
						})
				} else {
					this.addingRelevant = false
					this.$services.helpers.notification(this.$t('customerSelectionEmpty'), 'error', this)
				}
			} else {
				this.addingRelevant = true
				const payload = {
					is_hidden_update: true,
					articles: [],
					customer_ids: []
				}
				if (this.GET_ARTICLES_CHECKED.data.length > 0) {
					this.GET_ARTICLES_CHECKED.data.forEach(element => {
						payload.articles.push({article_id: parseInt(element.id)})
					})
				} else {
					payload.articles.push({article_id: parseInt(this.article_id)})
				}
				this.selectedCustomers.forEach(element => {
					payload.customer_ids.push(element.id)
				})
				if (getCompanyId()) {
					payload['company_id'] = getCompanyId()
				}
				this.$store.dispatch('portal/updateRelevantBulkData', {data: payload})
					.then(_ => {
						self.addingRelevant = false
						self.$services.helpers.notification(self.$t('articleUpdateSuccess'), 'success', self)
						// self.selectedCustomers = []
						self.customerSearchName = ''
						// window.Bus.$emit('fetch-article', 'bulk')
						// this.modalRelevantArticle = false
					})
					.catch(err => {
						self.addingRelevant = false
						window.Bus.$emit('output-error', err)
					})
			}
		},
		removeUserFromSelectedList(customer) {
			this.selectedCustomers = this.selectedCustomers.filter(item => item.id !== customer.id)
		},
		filterCustomers() {
			let URL = `?limit=50&search=${this.customerSearchName}`
			if (getCompanyId()) {
				URL += `&company_id=${getCompanyId()}`
			}
			this.$store.dispatch('portal/getCompanyEndCustomers', {page: this.$services.endpoints.COMPANY_ENDCUSTOMERS + URL})
				.then(res => {
					this.filteredCustomer = res.data.data
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getArticleDetail() {
			this.loadingRelevantArticle = true
			this.$store.dispatch('portal/getSingleArticle', {url: `/${this.article_id}`})
				.then(res => {
					this.modalRelevantArticleObject = res.data
					this.getCustomers()
				})
				.catch(_ => {
					this.loadingRelevantArticle = false
				})
		},
		getCustomers: function () {
			this.loadingRelevantArticle = true
			if (this.modalRelevantArticleObject.allowed_customer_numbers.length > 0) {
				let customers = this.modalRelevantArticleObject.allowed_customer_numbers.join(';')
				this.$store.dispatch('portal/filterCompanyEndCustomers', {data: `?customer_ids=${customers}&is_paging=false`}).then(resp => {
					this.selectedCustomers = resp.data.data
					this.loadingRelevantArticle = false
				}).catch(res => {
					this.loadingRelevantArticle = false
				})
			} else {
				this.loadingRelevantArticle = false
			}

		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
			GET_ARTICLES_CHECKED: 'portal/GET_ARTICLES_CHECKED',
		})
	},
	mounted() {
		this.getArticleDetail()

	}
}
</script>

<style scoped>

</style>
