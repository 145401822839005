export default {
	authUser: {},
	companies: {
		'count': 1,
		'page': 1,
		'limit': 20,
		'total_pages': 1,
		'results': []
	},
	buyers: {
		'total_count': 1,
		'current_page': 1,
		'limit': 20,
		'total_pages': 1,
		'data': []
	},
}
