import HTTPEntry from './../../../../services/vue-axios/entry'
import endpoints from './../../../../services/endpoints'
import TYPES from "../../portal/types";

export default {
	async getPermissionUrl({commit}, _) {
		return new Promise((resolve, reject) => {
			HTTPEntry.get(endpoints.ONBOARDING_PERMISSION_URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async getAuthPermissionUrl({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(`${endpoints.ONBOARDING_PERMISSION_URL}obtain_oauth_url/`, payload.data)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async getAccessToken({_}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.ONBOARDING_ACCESS_TOKEN, payload)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async getMicroSoftToken({_}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.MICROSOFT_OAUTH_TOKEN, payload)
				.then(res => {
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async getEntryAccountName({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.get(endpoints.GET_ENTRY_ACCOUNT_NAME + `?auth_code=${payload}`)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				})
				.catch(err => {
					reject(err.response)
				})
		})
	},
	async registerShopOwner({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.CREATE_SHOPOWNER_ACCOUNT, payload)
				.then(res => {
					if (res.status === 201) {
						resolve(res)
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async registerSellerAccount({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.CREATE_SHOP_OWNER_ACCOUNT_V2, payload)
				.then(res => {
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async registerBuyerAccount({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.REGISTER_ENDCUSTOMERS, payload)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async loginClient({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.AUTH_USER_LOGIN, {
				email: payload.email,
				password: payload.pass,
			})
				.then(res => {
					if (res.status === 200) {
						// commit('UPDATE_USER_TOKEN', res.data)
						resolve(res)
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async loginCustomerSupport({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.CUSTOMER_SUPPORT_USER_LOGIN, {
				support_id: payload.support_id,
				company_id: payload.company_id,
			})
				.then(res => {
					if (res.status === 200) {
						// commit('UPDATE_USER_TOKEN', res.data)
						resolve(res)
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async EmailVerification({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.EMAIL_VERIFICATION, {
				code: payload.code,
				email: payload.email,
			})
				.then(res => {
					if (res.status === 200) {
						// commit('UPDATE_USER_TOKEN', res.data)
						resolve(res)
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async registerInviteCustomer({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.REGISTER_CUSTOMERS, payload)
				.then(res => {
					if (res.status === 200) {
						resolve('success')
					}
				})
				.catch(err => {
					reject(err.response)
				})
		})
	},
	async acceptInvitation({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.ACCEPT_INVITATION, payload)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				})
				.catch(err => {
					reject(err.response)
				})
		})
	},
	async forgetPassword(_, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.FORGET_PASSWORD_ENDPOINT, payload)
				.then(res => {
					if (res.status === 200) {

						resolve('success')
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async resetUserPassword(_, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(endpoints.RESET_PASSWORD_ENDPOINT, payload)
				.then(res => {
					if (res.status === 200) {
						resolve('success')
					}
				})
				.catch(err => {
					reject(err.response)
				})
		})
	},
	async getSubscription({commit}, _) {
		return new Promise((resolve, reject) => {
			HTTPEntry.get(endpoints.SUBSCRIPTION_ENDPOINT + 'type/')
				.then(res => {
					if (res.status === 200) {
						// commit(TYPES.UPDATE_USER_SUBCRIPTION_TYPE, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getSubscriptionType({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.get(endpoints.SUBSCRIPTION_ENDPOINT + `supplier_type/?${payload.data}`)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getCompanySetup({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.get(endpoints.SHOP_SETUP + `${payload.company_id}/`)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})

	}
}
