<template>
	<!-- Add a dynamic class based on the customCss property -->
	<div :class="['app-container', 'app-theme-white', 'body-tabs-shadow', {'custom-css': customCss}]">
	  <div class="app-container">
		<div class="row no-gutters md:h-screen">
		  <div
			class="col-md-6"
			:class="{ 'theme-side': systemMode === 'main' }"
			:style="leftColumnStyle"
		  >
			<div v-if="!hasLoginImage" class="p-4 flex flex-col space-y-5">
			  <div class="mb-5" v-if="systemMode === 'whitelabel'">
				<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
				  <v-lazy-image
					class="img-fluid logo-top logo-large logo-right"
					:src="GET_COMPANY_SUBSCRIPTION_SETTING.full_logo"
					alt="logo"
					v-if="GET_COMPANY_SUBSCRIPTION_SETTING.full_logo"
				  />
				</div>
				<div
				  class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description"
				>
				  {{ GET_COMPANY_SUBSCRIPTION_SETTING.seller_login_description }}
				</div>
			  </div>
			  <div class="mb-5" v-else>
				<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
				  <v-lazy-image
					class="md:h-auto logo-top md:pl-20 h-24 w-24 logo"
					:src="imageLogo"
					alt="logo"
				  />
				</div>
				<div
				  class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description"
				>
				  {{ $t("welcomeEntry") }}
				</div>
			  </div>
			</div>
		  </div>
		  <div class="col-md-6 relative">
			<div class="p-4 flex flex-col space-y-5">
			  <div class="mb-5">
				<div
				  class="flex md:justify-center align-items-center w-full space-x-4 md:-ml-10 mt-5"
				>
				  <div
					class="font-semibold text-lg theme-header-size text-blue-900 md:tracking-wide"
				  >
					{{ $t("loginHeader") }}
				  </div>
				</div>
			  </div>
			  <div class="flex md:justify-end md:mt-20">
				<div
				  class="login-border-section w-full md:w-4/5 md:pl-10 md:pr-10"
				>
				  <ValidationObserver
					ref="form"
					v-slot="{ invalid, handleSubmit }"
				  >
					<form
					  class="mt-5 mb-2"
					  @submit.prevent="
						handleSubmit(loginUser)
					  "
					>
					  <div class="form-group">
						<label
						  for="exampleEmail"
						  class="text-base font-semibold text-blue-900"
						  >{{ $t("email") }}</label
						>
						<div class="user-sec">
						  <ValidationProvider
							name="email"
							rules="required|email"
							v-slot="slotData"
						  >
							<input
							  v-model="email"
							  data-cy="email"
							  type="text"
							  class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
							  required
							/>
							<span
							  class="icon-check is--valid"
							  v-if="
								slotData.touched &&
								slotData.errors
								  .length < 1
							  "
							>
							  <svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-6 w-6 text-green-600 font-semibold"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							  >
								<path
								  stroke-linecap="round"
								  stroke-linejoin="round"
								  stroke-width="2"
								  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
								/>
							  </svg>
							</span>
							<small
							  class="text-red-500 font-semibold"
							  >{{
								slotData.errors[0]
							  }}</small
							>
						  </ValidationProvider>
						</div>
					  </div>
					  <div class="form-group">
						<label
						  for="exampleEmail"
						  class="text-base font-semibold text-blue-900"
						  >{{ $t("password") }}</label
						>
						<div>
						  <ValidationProvider
							name="password"
							rules="required"
							v-slot="slotData"
						  >
							<input
							  v-model="password"
							  data-cy="password"
							  name="password"
							  type="password"
							  class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
							  required
							/>
							<span
							  class="icon-check is--valid"
							  v-if="
								slotData.touched &&
								slotData.errors
								  .length < 1
							  "
							>
							  <svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-6 w-6 text-green-600 font-semibold"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							  >
								<path
								  stroke-linecap="round"
								  stroke-linejoin="round"
								  stroke-width="2"
								  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
								/>
							  </svg>
							</span>
							<small
							  class="text-red-500 font-semibold"
							  >{{
								slotData.errors[0]
							  }}</small
							>
						  </ValidationProvider>
						</div>
					  </div>
					  <div class="form-group mt-2">
						<label for="exampleEmail"></label>
						<div>
						  <button
							data-cy="login-btn"
							:style="
							  GET_COMPANY_SUBSCRIPTION_SETTING.button_color
								? 'background:' +
								  GET_COMPANY_SUBSCRIPTION_SETTING.button_color
								: 'background:#E8B105'
							"
							class="w-full login-btn-success px-4 py-2 rounded-md"
							type="submit"
							:disabled="
							  invalid || processing
							"
						  >
							<p
							  v-if="processing"
							  class="text-white mx-auto center-text"
							>
							  <svg
								class="animate-spin mr-2 h-5 w-5 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							  >
								<circle
								  class="opacity-25"
								  cx="12"
								  cy="12"
								  r="10"
								  stroke="currentColor"
								  stroke-width="4"
								></circle>
								<path
								  class="opacity-75"
								  fill="currentColor"
								  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								></path>
							  </svg>
							</p>
							<span
							  v-else
							  class="text-white"
							  >{{ $t("login") }}</span
							>
						  </button>
						</div>
					  </div>
					  <div class="form-group mt-1">
						<label
						  for="exampleEmail"
						  class="override-pb override-pt col-form-label label-context"
						></label>
						<div>
						  <span
							class="font-md-login font-semibold text-blue-900"
							><router-link
							  data-cy="forget-link"
							  :to="{ name: 'forget' }"
							  class="no-deco no-de-hover"
							  >{{
								$t("forgetPassword")
							  }}?</router-link
							></span
						  ><br /><br />
						</div>
					  </div>
					  <div class="form-group mt-10"></div>
					</form>
				  </ValidationObserver>
				</div>
			  </div>
			</div>
			<div
			  class="container text-blue-900 text-center md:bottom-0 md:absolute mb-4 flex justify-center"
			>
			  <span
				>Copyright ©
				<span v-if="systemMode === 'whitelabel'"
				  >{{ current }}
				  {{
					GET_COMPANY_SUBSCRIPTION_SETTING.brand_name
				  }}</span
				><span v-else>{{ current }} B2B Portal</span></span
			  >
			  <span>
				<locale-component
				  :show-text="true"
				  :height="5"
				  :width="5"
				/>
			  </span>
			</div>
			<!-- Overlay for unpaid subscription -->
			<div v-if="this.isUnpaidSub" class="absolute inset-0 bg-white bg-opacity-80 flex items-center justify-center">
			  <div class="text-center">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 mx-auto text-gray-500 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
				</svg>
				<p class="text-xl font-semibold text-gray-700">Denna webbutik är tillfälligt stängd.</p>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	  <Notification />
	</div>
  </template>

  <script>
  import { mapGetters } from "vuex";
  import {
	  ValidationProvider,
	  ValidationObserver,
	  localeChanged,
  } from "vee-validate";
  import i18n from "@/plugins/i18n";
  import Notification from "./../../customComponent/NotificationAlert";
  import bus from "../../bus";
  import logoImage from "./../../assets/images/logo/logo.png";
  import VLazyImage from "v-lazy-image/v2";
  import {
	  removeLocalCompany,
	  removeLocalBuyer,
	  saveUserId,
  } from "../../services/storage-window";
  import LocaleComponent from "../../components/LocaleComponent";
  
  export default {
	  name: "login",
	  components: {
		  Notification,
		  ValidationProvider,
		  ValidationObserver,
		  LocaleComponent,
		  VLazyImage,
	  },
	  data() {
		  return {
			  current: new Date().getFullYear(),
			  baseURL: window.location.origin,
			  loaded: false,
			  email: "",
			  password: "",
			  processing: false,
			  companyId: "",
			  customerId: "",
			  systemMode: "loading",
			  imageLogo: logoImage,
			  bus,
			  fontFamily: '',
			  isUnpaidSub: process.env.VUE_APP_UNPAID_SUB === 'true',
	
		  };
	  },
	  computed: {
		  ...mapGetters({
			  USER_TOKEN: "auth/USER_TOKEN",
			  GET_LOCALE_LANG: "utility/GET_LOCALE_LANG",
			  GET_COMPANY_SUBSCRIPTION_SETTING:
				  "portal/GET_COMPANY_SUBSCRIPTION_SETTING",
		  }),
		  customCss() {
			  return process.env.VUE_APP_CUSTOM_CSS === 'True';
	  },
	  hasLoginImage() {
		return !!this.GET_COMPANY_SUBSCRIPTION_SETTING.company_login_image;
	  },
  
	  leftColumnStyle() {
		if (this.hasLoginImage) {
		  return {
			background: `url(${this.GET_COMPANY_SUBSCRIPTION_SETTING.company_login_image}) no-repeat center center`,
			backgroundSize: 'cover'
		  };
		} else if (this.GET_COMPANY_SUBSCRIPTION_SETTING.menu_color) {
		  return { background: this.GET_COMPANY_SUBSCRIPTION_SETTING.menu_color };
		} else {
		  return { background: '#181C4C' };
		}
	  }
	  },
	  mounted() {
		
		  const userInfo = this.$route.query;
		  if (userInfo.company_id && userInfo.customer_id) {
			  this.companyId = userInfo.company_id;
			  this.customerId = userInfo.customer_id;
		  }
		  if (this.customCss) {
			  this.fontFamily = getComputedStyle(document.body).fontFamily;
		
		  }
		  removeLocalCompany();
		  removeLocalBuyer();
		  this.setDefaultLanguage();
	  },
	  beforeMount() {
		  if (window.localStorage.hasOwnProperty("__b2b__company__id")) {
			  window.localStorage.removeItem("__b2b__company__id");
		  }
		  this.systemMode = process.env.VUE_APP_SYSTEM_MODE;
	
	  },
	  methods: {
		  logCustomCssApplied() {
			  console.log('Custom CSS is applied');
		  },
		  
		  showNotification(messageKey, type = "success") {
			  // Translate the message and show notification
			  const message = this.$t(messageKey);
			  this.$services.helpers.notification(
				  message,
				  type,
				  this
			  );
			  
			  // Ensure the notification stays for at least 10 seconds
			  setTimeout(() => {
			  }, 10000); // 10 seconds
		  },
		  
		  loginUser() {
			
			if (this.isUnpaidSub) {
				this.showNotification("shopTemporarilyClosed", "error");
				return;
			   }

			  if (this.email === "" || this.password === "") {
				  this.showNotification("fieldRequired", "error");
			  } else {
				  this.processing = true;
				  this.$store
					  .dispatch("auth/loginClient", {
						  email: this.email,
						  pass: this.password,
					  })
					  .then((res) => {
						  this.processing = false;
						  if (this.customerId && this.companyId) {
							  const payloadRequest = {
								  customer_id: this.customerId,
								  company_id: this.companyId,
							  };
							  this.$store.dispatch(
								  "customer/acceptUserRequest",
								  payloadRequest
							  );
						  }
  
						  if (
							  res.data.user_group[0].name === "ShopOwner" ||
							  res.data.user_group[0].name === "ShopOwnerEmployee"
						  ) {
							  if (!res.data.subscription) {
								  this.$router.replace({
									  name: "onboardsubcription",
									  params: { id: res.data.company_id },
								  });
							  } else {
								  this.showNotification("loginSuccess", "success");
								  this.setRandomColor();
  
								  this.$store.commit(
									  "auth/UPDATE_USER_TOKEN",
									  res.data
								  );
								  this.$store.commit(
									  "auth/UPDATE_CUS_TOKEN",
									  res.data
								  );
								  if (window.localStorage.getItem("firstT")) {
									  setTimeout(() => {
										  window.localStorage.removeItem(
											  "firstT"
										  );
										  this.$router.replace({
											  name: "settings",
										  });
									  }, 2000);
								  } else {
									  setTimeout(() => {
										  this.$router.replace({
											  name: "dashboard",
										  });
									  }, 2000);
								  }
							  }
						  } else if (
							  res.data.user_group[0].name === "Administrator"
						  ) {
							  this.$store.commit(
								  "auth/UPDATE_USER_TOKEN",
								  res.data
							  );
							  this.$store.commit(
								  "auth/UPDATE_CUS_TOKEN",
								  res.data
							  );
							  setTimeout(() => {
								  this.$router.replace({
									  name: "admin-dashboard",
								  });
							  }, 2000);
						  } else {
							  this.showNotification("loginSuccess", "success");
							  this.$store.commit(
								  "auth/UPDATE_CUS_TOKEN",
								  res.data
							  );
							  saveUserId(res.data.user_id);
							  this.$store
								  .dispatch("customer/getConnectedShop", {
									  data: "?is_connected=True",
								  })
								  .then((resp) => {
									  if (resp.data.suppliers.length > 0) {
										  this.$store.commit(
											  "customer/UPDATE_CURRENT_COMPANY",
											  { data: resp.data.suppliers[0] }
										  );
									  } else {
										  this.$store.commit(
											  "customer/UPDATE_CURRENT_COMPANY",
											  { data: {} }
										  );
									  }
									  if (this.systemMode === "whitelabel") {
										  let routeName = {
											  name: "bloglist",
											  params: {
												  company_id:
													  process.env
														  .VUE_APP_COMPANY_ID,
											  },
										  };
										  setTimeout(() => {
											  this.$router.replace(routeName);
										  }, 2000);
									  } else {
										  setTimeout(() => {
											  this.$router.replace({
												  name: "customerdash",
											  });
										  }, 2000);
									  }
								  });
						  }
					  })
					  .catch((err) => {
						this.processing = false;
						let errorMessage = "loginError"; // Default error message

						if (err.response && err.response.status === 400 && err.response.data && err.response.data.message) {
							const errorString = err.response.data.message;
							
							if (errorString.includes('No user with this email exists')) {
							errorMessage = "noUserExists";
							} else if (errorString.includes('code=\'authorization\'')) {
							errorMessage = "invalidCredentials";
							}
						}

						this.showNotification(errorMessage, "error");
						});
						 
			  }
		  },
  
		  setDefaultLanguage() {
			  if (this.GET_COMPANY_SUBSCRIPTION_SETTING.english_as_default) {
				  this.changeLocale("en");
			  }
		  },
  
		  changeLocale(locale) {
			  i18n.locale = locale;
			  this.$store.commit("utility/CHANGE_LOCALE", locale);
			  localeChanged();
		  },
		  setRandomColor() {
			  const color = [
				  "#512DA8",
				  "#7E3469",
				  "#EE4D81",
				  "#344258",
				  "#546295",
				  "#1E2C41",
			  ];
			  const choose = color[Math.floor(Math.random() * color.length)];
			  if (!window.localStorage.getItem("randomc")) {
				  window.localStorage.setItem("randomc", choose);
			  }
			  return false;
		  },
	  },
  };
  </script>
  

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

#app {
	overflow-y: hidden !important;
}

.login-head-text {
	font-size: 1.2rem !important;
}

.login-head-text-xl {
	font-size: 1.5rem !important;
}

.override-form-field {
	border: 0;
	border-bottom: 1px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: unset !important;
	border-radius: unset !important;
}

body {
	background: #fff !important;
}

.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.custom-rem {
	padding: 5rem;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.form-control {
	// border: 1px solid $form-border !important;
}

.form-control:focus {
	box-shadow: unset !important;
	// border: 1px solid $theme-primary-color !important;
}

.is--valid {
	margin-top: -30px;
}

.icon-check {
	position: absolute;
	right: 20px;
}

.theme-side {
	background: $production-theme;
}

.login-btn-success {
	background: #e8b105;
}

.logo {
	height: 95px;
}

.login-description {
	position: relative;
	top: 122px;
}

.center-text {
	margin: auto;
	display: table;
}
.logo-large {
    height: 200px !important;
    width: 200px !important;
    object-fit: contain;
}

.logo-right {
    margin-left: 3cm;
}

@media only screen and (max-width: 600px) {
	.logo {
		height: 48px;
	}
	.login-description {
		top: 0px;
	}
}

.col-md-6 {
	transition: background 0.3s ease;
  }


</style>
