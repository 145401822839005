export default {
	GET_AUTH_USER(state) {
		return state.authUser
	},
	GET_COMPANIES(state) {
		return state.companies
	},
	GET_BUYERS(state) {
		return state.buyers
	},
}
